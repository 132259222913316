import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { ScheduleService } from '../schedule.service';
import { SelectorInputOption } from '../shared/selector-input/selector-input.component';

@Component({
  selector: 'app-team-schedule',
  templateUrl: './team-schedule.component.html',
  styleUrls: ['./team-schedule.component.scss']
})
export class TeamScheduleComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() date: string;
  selectedDepartment: string;
  departmentOptions: SelectorInputOption[] = [];
  departments: any[] = [];
  shifts: any[] = [];

  constructor(private scheduleService: ScheduleService) {
    super();
  }

  getData() {
    this.addSubscription(this.scheduleService.getTeamSchedule(this.date).subscribe(departments => {
      this.departments = [];
      this.departmentOptions = [];
      if (departments.length > 0) {
        departments = departments.map(department => {
          const departmentKey = department.name.toLowerCase().replace(/\W+/g, '');
          this.departmentOptions.push({
            label: department.name,
            value: departmentKey
          });
          return {...department, key: departmentKey};
        })
        this.selectedDepartment = departments[0].key;
      }
      this.departments = departments;
    }))
  }

  departmentChanged(value: string) {
    this.selectedDepartment = value;
  }

  ngOnInit(): void {
    console.log('TeamScheduleComponent.ngOnInit()');
    this.getData();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.date && changes.date.previousValue) this.getData();
  }
}
