import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface SwitchInputOption {
  label: string;
  value: any;
}

@Component({
  selector: 'app-switch-input',
  templateUrl: './switch-input.component.html',
  styleUrls: ['./switch-input.component.scss']
})
export class SwitchInputComponent implements OnInit, OnChanges {

  @Input() options: SwitchInputOption[] = [];
  @Input() control: UntypedFormControl;
  @Input() selected: any;

  constructor() { }

  ngOnInit(): void {
    if (this.options.length > 0) this.selected = this.options[0].value;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selected) {
      this.selected = changes.selected.currentValue;
    }
  }

  selectOption(value: any) {
    this.selected = value;
    if (this.control) {
      this.control.setValue(value);
    }
  }

}
