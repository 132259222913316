import { Component, OnInit, Input } from '@angular/core';
import { Shift } from '../../interfaces/shift';
import { UserService } from '../../auth/user.service';
import { Store } from '@ngrx/store';
import { State } from '../../reducers';

@Component({
  selector: 'app-shift-timeline',
  templateUrl: './shift-timeline.component.html',
  styleUrls: ['./shift-timeline.component.scss']
})
export class ShiftTimelineComponent implements OnInit {
  
  @Input() shift: Shift;

  isIOS = false;
  locationLink$ = this.store.select('config', 'schedule', 'locationLink');

  constructor(
    private userService: UserService,
    private store: Store<State>,
  ) { }

  ngOnInit(): void {
    this.isIOS = this.userService.isIOS();
  }

}
