import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatUnicorn'
})
export class FormatUnicornPipe implements PipeTransform {

  transform(value: string, ...args: unknown[]): unknown {
    if (!value) return '';
    return value.formatUnicorn(args);
  }

}
