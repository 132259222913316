import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Day } from './interfaces/day';
import { first, map, switchMap } from 'rxjs/operators';
import { Shift } from './interfaces/shift';
import { ScheduleSettings } from './interfaces/schedule-settings';
import { Schedule } from './interfaces/schedule';
import { Store } from '@ngrx/store';
import { State } from './reducers';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import { ConfigService } from './config.service';
import { iApprovableShift } from '@shared/schedule';

@Injectable({
  providedIn: 'root'
})
export class ScheduleService {

  viewDate$ = this.store.select((state: State) => state.schedule.viewDate);
  today$ = this.store.select((state: State) => state.schedule.today);
  month$ = this.store.select((state: State) => state.schedule.month);
  picking$ = this.store.select((state: State) => state.schedule.picking);
  approvableShifts$ = this.store.select((state: State) => state.schedule.approvable);
  teamScheduleEnabled$ = this.store.select('config', 'teamSchedule', 'enabled');
  confirmShiftsEnabled$ = this.store.select('config', 'confirmShifts', 'enabled');

  constructor(
    private http: HttpClient,
    private store: Store<State>,
    private configService: ConfigService,
  ) { }

  convertToSchedule(startDate: Dayjs, amountOfDays: number, shifts: Shift[]): Schedule {
    const shiftMap = {};
    const extraShiftMap = {};
    const keyFormat = 'YYYYMMDD';
    const days: Day[] = [];
    const shiftsCountMap = {};
    const extraShiftsCountMap = {};
    let extraShiftCount = 0;
    let shiftCount = 0;

    shifts.forEach(shift => {
      const key = dayjs(shift.startTime).format(keyFormat);
      if (shift.pendingDuty) {
        const startTimeChanged = !dayjs(shift.startTime).isSame(dayjs(shift.pendingDuty.startTime), 'minute');
        const endTimeChanged = !dayjs(shift.endTime).isSame(dayjs(shift.pendingDuty.endTime), 'minute');
        shift = {...shift, startTimeChanged, endTimeChanged};
      }
      if (!shiftsCountMap[key]) shiftsCountMap[key] = 0;
      if (!extraShiftsCountMap[key]) extraShiftsCountMap[key] = 0;
      if (shift.resourceId !== 0) {
        shiftsCountMap[key] += 1;
        shiftCount += 1;
        if (!shiftMap[key]) shiftMap[key] = [];
        shiftMap[key].push(shift);
      } else {
        if (!shift.requestExistsForDuty) {
          extraShiftsCountMap[key] += 1;
          extraShiftCount += 1;
        }
        if (!extraShiftMap[key]) extraShiftMap[key] = [];
        extraShiftMap[key].push(shift);
      }
    })

    for(let i = 0; i < amountOfDays; i++) {
      const date = startDate.clone().add(i, 'day').startOf('day');
      const key = date.format(keyFormat);
      const day: Day = {
        date: date.toISOString(),
        shifts: shiftMap[key] || [],
        extraShifts: extraShiftMap[key] || [],
        shiftCount: shiftsCountMap[key] || 0,
        extraShiftCount: extraShiftsCountMap[key] || 0,
      }
      days.push(day);
    }

    return {
      days,
      shiftCount,
      extraShiftCount,
      error: false
    }
  }

  getMonth(date: string): Observable<Schedule> {
    const startDate = dayjs(date).startOf('month').startOf('day');
    const endDate = startDate.clone().endOf('month').endOf('day');
    const fromDate = encodeURIComponent(startDate.toISOString());
    const amountOfDays = endDate.diff(startDate, 'day') + 1; // + 1 to account for the first day of the month
    
    return this.http.get<Shift[]>(`/api/schedules?fromDate=${fromDate}&amountOfDays=${amountOfDays}`).pipe(
      map(shifts => {
        return this.convertToSchedule(startDate, amountOfDays, shifts);
      })
    );
  }
  
  getWeek(date: string): Observable<Schedule> {
    const startDate = dayjs(date).startOf('isoWeek').startOf('day');
    const endDate = startDate.clone().endOf('isoWeek').endOf('day');
    const fromDate = encodeURIComponent(startDate.toISOString());
    const amountOfDays = endDate.diff(startDate, 'day') + 1; // + 1 to account for the first day of the month
    
    return this.http.get<Shift[]>(`/api/schedules?fromDate=${fromDate}&amountOfDays=${amountOfDays}`).pipe(
      map(shifts => {
        return this.convertToSchedule(startDate, amountOfDays, shifts);
      })
    );
  }
  
  getDay(date: string): Observable<Day> {
    const startDate = dayjs(date).startOf('day');
    const fromDate = encodeURIComponent(startDate.toISOString());

    return this.http.get<Shift[]>(`/api/schedules?fromDate=${fromDate}&amountOfDays=1`).pipe(
      map(shifts => {
        return this.convertToSchedule(startDate, 1, shifts).days[0];
      })
    );
  }

  getScheduleSettings(): Observable<ScheduleSettings> {
    return this.http.get<ScheduleSettings>(`/api/schedules/settings`);
  }

  getTeamSchedule(date: string): Observable<any> {
    return this.store.select('config', 'teamSchedule', 'enabled').pipe(
      first(), 
      switchMap((enabled) => {
        return !enabled ? of([]) : this.http.get(`/api/schedules/team?date=${dayjs(date).format('YYYY-MM-DD')}`)
      })
    )
  }

  getSchedule(amountOfDays: number): Observable<Schedule> {
    const startDate = dayjs();
    const fromDate = encodeURIComponent(startDate.toISOString());
    return this.http.get<Shift[]>(`/api/schedules?fromDate=${fromDate}&amountOfDays=${amountOfDays}`).pipe(
      map(shifts => {
        return this.convertToSchedule(startDate, amountOfDays, shifts);
      })
    );
  }

  pickShift(shift: Shift) {
    const dto = {
      departmentId: shift.currentDepartmentId,
      dutyId: shift.dutyId,
      date: dayjs(shift.startTime).format('YYYY-MM-DD')
    };
    return this.http.post(`/api/schedules/pick-shift`, dto);
  }

  createRealization(shiftId: number, startTime: string, endTime: string) {
    return this.http.post(`/api/schedules/realization`, {shiftId, startTime, endTime});
  }

  cancelRealization(shiftId: number, startTime: string, endTime: string) {
    return this.http.post(`/api/schedules/realization/cancel`, {shiftId, startTime, endTime});
  }

  getApprovableShifts(startDate: string): Observable<iApprovableShift[]> {
    const sDate = dayjs(startDate).startOf('month').startOf('day');
    const eDate = sDate.clone().endOf('month').add(1, 'day');
    return this.store.select('config', 'confirmShifts', 'enabled').pipe(
      first(), 
      switchMap((enabled) => {
        return !enabled ? of(<iApprovableShift[]>[]) : this.http.get<iApprovableShift[]>(`/api/schedules/approval?startDate=${sDate.format('YYYY-MM-DD')}&endDate=${eDate.format('YYYY-MM-DD')}`)
      })
    )
  }

  confirmShift(shiftId: number) {
    return this.http.post(`/api/schedules/approval/confirm`, {shiftId});
  }

  declineShift(shiftId: number) {
    return this.http.post(`/api/schedules/approval/decline`, {shiftId});
  }

}
