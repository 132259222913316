import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { Request } from '../interfaces/request';
import { TranslateService } from '@ngx-translate/core';
import { BaseComponent } from '../base/base.component';
import { RequestService } from '../request.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as RequestsActions from '../reducers/requests.actions';
import { SlideUpPanelService } from '../slide-up-panel/slide-up-panel.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-request',
  templateUrl: './request.component.html',
  styleUrls: ['./request.component.scss']
})
export class RequestComponent extends BaseComponent implements OnInit {

  @Input() request: Request;
  typeTranslationString: string;
  typeTextTranslationString: string;
  beginDateString: string;
  endDateString: string;
  slideUpPanelId = '';

  @HostBinding('class') get cssClasses() {
    return `card`;
  }

  constructor(
    private translate: TranslateService,
    private requestService: RequestService,
    private slideUpPanelService: SlideUpPanelService,
    private store: Store<State>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.slideUpPanelId = `requestInfoPanel${this.request.id}`;
    this.translate.get('dateFormatting.day').subscribe(dateFormat => {
      const beginDate = dayjs(this.request.beginTime);
      const endDate = dayjs(this.request.endTime);
      const allDay = this.requestService.isAllDay(beginDate, endDate);
      const sameDay = this.requestService.isSameDay(beginDate, endDate);

      this.beginDateString = `${beginDate.format(dateFormat)}${!allDay ? ` ${beginDate.format('HH:mm')}` : ``}`;
      this.endDateString = `${endDate.format(dateFormat)}${!allDay ? ` ${endDate.format('HH:mm')}` : ``}`;
      this.typeTranslationString = `requests.request.type${this.request.type}`;

      if (this.request.type === 'LeaveWish') {
        this.typeTextTranslationString = `${this.typeTranslationString}Text${allDay && sameDay ? 'AllDay' : ''}`;
      } else if (this.request.type === 'DutyWish') {
        this.typeTextTranslationString = `${this.typeTranslationString}${(this.request.wish ? 'Shift' : 'TimeOff')}`;
      } else if (this.request.type === 'WorkInIntervalWish') {
        this.typeTextTranslationString = `${this.typeTranslationString}${(this.request.wish ? 'Shift' : 'TimeOff')}${allDay && sameDay ? 'AllDay' : ''}`;
      } 
    });
  }

  openPanel(): void {
    this.slideUpPanelService.show(this.slideUpPanelId);
  }

  cancelRequest(): void {
    this.slideUpPanelService.hide(this.slideUpPanelId);
    this.addSubscription(this.requestService.cancel(this.request.id, this.request.type).subscribe(() => {
      this.store.dispatch(RequestsActions.getAll())
    }));
  }

}
