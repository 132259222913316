import { Component, OnInit, Output, EventEmitter, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, ValidatorFn, ValidationErrors } from '@angular/forms';
import { SwitchInputOption } from '../shared/switch-input/switch-input.component';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from '../request.service';
import { BaseComponent } from '../base/base.component';
import { AddRequestInfo } from '../interfaces/add-request-info';
import { SelectorInputOption } from '../shared/selector-input/selector-input.component';
import { PermissionCheckMode, UserService } from '../auth/user.service';
import * as dayjs from 'dayjs';

// export const remarkRequiredValidator: ValidatorFn = (control: FormGroup): ValidationErrors | null => {
//   const requestType = control.get('requestType').value;
//   const remark = control.get('remark').value;

//   return (requestType === 'leave' && remark === '') ? {remarkRequired: true} : null;
// }

export const fromRequiredValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const from = control.get('from').value;
  if (!from) return null;

  return (requestType === 'specific' && from.dateTime === null) ? {fromRequired: true} : null;
}

export const fromLeaveRequiredValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const fromLeave = control.get('fromLeave').value;
  if (!fromLeave) return null;

  return (requestType === 'leave' && fromLeave.dateTime === null) ? {fromRequired: true} : null;
}

export const toRequiredValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const to = control.get('to').value;
  if (!to) return null;

  return (requestType === 'specific' && to.dateTime === null) ? {toRequired: true} : null;
}

export const toLeaveRequiredValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const toLeave = control.get('toLeave').value;
  if (!toLeave) return null;

  return (requestType === 'leave' && toLeave.dateTime === null) ? {toRequired: true} : null;
}

export const toIsBeforeFromValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const from = control.get('from').value;
  const to = control.get('to').value;

  if (!from || !to) return null;

  const isRequiredRequestType = requestType === 'specific';
  const toIsBeforeFrom = dayjs(to.dateTime).isBefore(dayjs(from.dateTime));
  
  return (isRequiredRequestType && toIsBeforeFrom) ? {toIsBeforeFrom: true} : null;
}

export const toLeaveIsBeforeFromLeaveValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const fromLeave = control.get('fromLeave').value;
  const toLeave = control.get('toLeave').value;

  if (!fromLeave || !toLeave) return null;

  const isRequiredRequestType = requestType === 'leave';
  const toIsBeforeFrom = dayjs(toLeave.dateTime).isBefore(dayjs(fromLeave.dateTime));
  
  return (isRequiredRequestType && toIsBeforeFrom) ? {toIsBeforeFrom: true} : null;
}

export const toIsEqualToFromValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const from = control.get('from').value;
  const to = control.get('to').value;

  if (!from || !to) return null;

  const isRequiredRequestType = requestType === 'specific';
  const toIsEqualToFrom = dayjs(to.dateTime).isSame(dayjs(from.dateTime)) && (!from.allDay || !to.allDay);
  
  return (isRequiredRequestType && toIsEqualToFrom) ? {toIsEqualToFrom: true} : null;
}

export const toLeaveIsEqualToFromLeaveValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const fromLeave = control.get('fromLeave').value;
  const toLeave = control.get('toLeave').value;

  if (!fromLeave || !toLeave) return null;

  const isRequiredRequestType = requestType === 'leave';
  const toIsEqualToFrom = dayjs(toLeave.dateTime).isSame(dayjs(fromLeave.dateTime)) && (!fromLeave.allDay || !toLeave.allDay);
  
  return (isRequiredRequestType && toIsEqualToFrom) ? {toIsEqualToFrom: true} : null;
}

export const fromIsBeforeTodayValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const from = control.get('from').value;

  if (!from) return null;

  const isRequiredRequestType = requestType === 'specific';
  const fromIsBeforeToday = dayjs(from.dateTime).isBefore(dayjs(), 'day');
  
  return (isRequiredRequestType && fromIsBeforeToday) ? {fromIsBeforeToday: true} : null;
}

export const fromLeaveIsBeforeTodayValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
  const requestType = control.get('requestType').value;
  const fromLeave = control.get('fromLeave').value;

  if (!fromLeave) return null;

  const isRequiredRequestType = requestType === 'leave';
  const fromIsBeforeToday = dayjs(fromLeave.dateTime).isBefore(dayjs(), 'day');
  
  return (isRequiredRequestType && fromIsBeforeToday) ? {fromIsBeforeToday: true} : null;
}

@Component({
  selector: 'app-request-form',
  templateUrl: './request-form.component.html',
  styleUrls: ['./request-form.component.scss']
})
export class RequestFormComponent extends BaseComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() context: string;
  @Output() cancel: EventEmitter<any> = new EventEmitter();
  @Output() submit: EventEmitter<any> = new EventEmitter();

  fromLeaveIsBeforeMinDateValidator: ValidatorFn = (control: UntypedFormGroup): ValidationErrors | null => {
    const requestType = control.get('requestType').value;
    const fromLeave = control.get('fromLeave').value;

    if (!fromLeave) return null;
    console.log('Checking,', this.minLeaveDate.format());

    const isRequiredRequestType = requestType === 'leave';
    const fromIsBeforeMinDate = dayjs(fromLeave.dateTime).isBefore(this.minLeaveDate, 'day');
    
    return (isRequiredRequestType && fromIsBeforeMinDate) ? {fromIsBeforeMinDate: true} : null;
  }

  today = dayjs().toDate();
  tomorrow = dayjs().add(1, 'day').toDate();
  wishType: string = 'work';
  requestType: string = 'specific';
  wishTypeSwitchOptions: SwitchInputOption[] = [];
  requestForm = new UntypedFormGroup({
    wishType: new UntypedFormControl('off'),
    requestType: new UntypedFormControl('leave'),
    leaveType: new UntypedFormControl(),
    date: new UntypedFormControl(dayjs(this.tomorrow).format('YYYY-MM-DD')),
    shift: new UntypedFormControl(),
    from: new UntypedFormControl(),
    fromLeave: new UntypedFormControl(),
    to: new UntypedFormControl(),
    toLeave: new UntypedFormControl(),
    remark: new UntypedFormControl(),
  }, {
    validators: [
      fromRequiredValidator,
      fromLeaveRequiredValidator,
      toRequiredValidator,
      toLeaveRequiredValidator,
      toIsBeforeFromValidator,
      toLeaveIsBeforeFromLeaveValidator,
      toIsEqualToFromValidator,
      toLeaveIsEqualToFromLeaveValidator,
      fromIsBeforeTodayValidator,
      fromLeaveIsBeforeTodayValidator,
      this.fromLeaveIsBeforeMinDateValidator,
    ]
    /*validators: remarkRequiredValidator*/
  });
  info: AddRequestInfo | any = {};
  shiftOptions: SelectorInputOption[] = [];
  loaded = false;
  minLeaveDate = dayjs(this.tomorrow); 
  minLeaveDays = 0;

  constructor(
    private translateService: TranslateService,
    private requestService: RequestService,
    private userService: UserService,
  ) {
    super();
    this.addSubscription(this.translateService.get([
      'requests.form.switchWantToWork',
      'requests.form.switchWantTimeOff',
    ]).subscribe(translations => {
      this.wishTypeSwitchOptions = [];
      this.userService.isAllowed([
        'CreateWorkNoWorkRequest',
        'CreateDutyRequest'
      ], PermissionCheckMode.OR).then(allowed => {
        if (allowed) {
          this.wishTypeSwitchOptions.push(
            {
              label: translations['requests.form.switchWantToWork'],
              value: 'work',
            }
          )
        }
      })
      this.userService.isAllowed([
        'CreateWorkNoWorkRequest',
        'CreateDutyRequest',
        'CreateLeaveWishRequest'
      ], PermissionCheckMode.OR).then(allowed => {
        if (allowed) {
          this.wishTypeSwitchOptions.push(
            {
              label: translations['requests.form.switchWantTimeOff'],
              value: 'off',
            }
          )
        }
      })
    }))
    this.userService.getSetting('numberOfDaysFromTodayAfterWhichItIsAllowedToEnterLeaveWish').then(numberOfDays => {
      console.log('# OF LEAVE STUFF', numberOfDays)
      this.minLeaveDays = parseInt(numberOfDays);
      this.minLeaveDate = dayjs().add(parseInt(numberOfDays), 'days');
    })
    this.getInfo();
  }

  ngAfterViewInit(): void {
    this.loaded = true;
  }

  getInfo(date: string = dayjs().format()) {
    this.addSubscription(this.requestService.getInfo(date).subscribe(info => {
      const ids = [];
      this.info = {
        ...info,
        activityTypesForLeave: info.activityTypesForLeave.filter((type: any) => {
          const isStartedToday = dayjs(date).isSame(dayjs(type.validFrom), 'day');
          const isAfterStart = dayjs(date).isAfter(dayjs(type.validFrom), 'day');
          const isEndingToday = dayjs(date).isSame(dayjs(type.validUntil), 'day');
          const isBeforeEnd = dayjs(date).isBefore(dayjs(type.validUntil), 'day');
          if ((isStartedToday || isAfterStart) && (isEndingToday || isBeforeEnd)) {
            if (!ids.includes(type.id)) {
              ids.push(type.id);
              return true;
            } else {
              return false;
            }
          }
          // return (isStartedToday || isAfterStart) && (isEndingToday || isBeforeEnd);
        })
      };
      if (info.activityTypesForLeave[0]) this.requestForm.patchValue({leaveType: info.activityTypesForLeave[0].id});
      if (info.allowedDuties) {
        this.requestForm.patchValue({shift: (info.allowedDuties[0] ? info.allowedDuties[0].value : '')});
        this.shiftOptions = info.allowedDuties;
      }
    }))
  }

  applyContext() {
    if (this.context === 'wantWork') {
      this.wishType = 'work';
      this.requestType = 'specific';
    } else if (this.context === 'wantOff') {
      this.wishType = 'off';
      this.requestType = 'specific';
    } else if (this.context === 'requestLeave') {
      this.wishType = 'off';
      this.requestType = 'leave';
    }
    this.requestForm.patchValue({wishType: this.wishType, requestType: this.requestType});
  }

  isFormValid(): boolean {
    return this.loaded ? this.requestForm.valid : true;
  }

  ngOnInit(): void {
    this.applyContext();
    this.requestForm.valueChanges.subscribe(values => console.log('Form values', values))
    this.addSubscription(this.requestForm.controls.wishType.valueChanges.subscribe(value => {
      this.wishType = value;
      this.requestForm.patchValue({requestType: 'specific'});
    }))
    this.addSubscription(this.requestForm.controls.requestType.valueChanges.subscribe(value => {
      this.requestType = value;
    }))
    this.addSubscription(this.requestForm.controls.date.valueChanges.subscribe(value => {
      this.getInfo(dayjs(value).format());
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.context) {
      this.applyContext();
    }
  }

  createWorkRequest() {
    const wishType = this.requestForm.get('wishType').value;
    const from = this.requestForm.get('from').value;
    const to = this.requestForm.get('to').value;
    const beginDate = from.allDay ? dayjs(from.dateTime).startOf('day').format() : dayjs(from.dateTime).format();
    const endDate = to.allDay ? dayjs(to.dateTime).add(1, 'day').startOf('day').format() : dayjs(to.dateTime).format();
    const request = {
      beginDate,
      endDate,
      description: this.requestForm.get('remark').value || ' ',
      wantToWork: wishType === 'work' ? true : false,
    }
    console.log('createWorkRequest()', request);
    this.addSubscription(this.requestService.createWorkRequest(request).subscribe(response => {
      this.submit.emit(request);
    }))
  }

  createDutyRequest() {
    const wishType = this.requestForm.get('wishType').value;
    const shift = this.requestForm.get('shift').value;
    const date = dayjs(`${this.requestForm.get('date').value} 12:00`);
    const request = {
      name: shift,
      date: date.format(),
      wantToWork: wishType === 'work' ? true : false,
      description: this.requestForm.get('remark').value || ' ',
      fromShiftPicking: false
    }
    console.log('createDutyRequest()', request);
    this.addSubscription(this.requestService.createDutyRequest(request).subscribe(response => {
      this.submit.emit(request);
    }))
  }

  createLeaveRequest() {
    const from = this.requestForm.get('fromLeave').value;
    const to = this.requestForm.get('toLeave').value;
    const beginDate = from.allDay ? dayjs(from.dateTime).startOf('day') : dayjs(from.dateTime);
    const endDate = to.allDay ? dayjs(to.dateTime).add(1, 'day').startOf('day') : dayjs(to.dateTime);
    const allDay = this.requestService.isAllDay(beginDate, endDate);
    const sameDay = this.requestService.isSameDay(beginDate, endDate);
    const request = {
      beginDate: beginDate.format(),
      endDate: endDate.format(),
      activityId: parseInt(this.requestForm.get('leaveType').value),
      description: this.requestForm.get('remark').value || ' ',
      applyLeaveForPartialShift: (sameDay && !allDay) || !sameDay,
    }
    console.log('createLeaveRequest()', request);
    this.addSubscription(this.requestService.createLeaveRequest(request).subscribe(response => {
      this.submit.emit(request);
    }))
  }

  pressCancel() {
    this.cancel.emit(true);
  }

  pressSend() {
    console.log('Form', this.requestForm.value);
    const requestType = this.requestForm.value.requestType;
    if (requestType === 'specific') {
      this.createWorkRequest();
    } else if (requestType === 'duty') {
      this.createDutyRequest();
    } else if (requestType === 'leave') {
      this.createLeaveRequest();
    }
  }

}
