import { createReducer, on, createSelector } from '@ngrx/store';
import { getAllSuccess, getAllError, remove, getCandidatesSuccess, setCurrentShift } from './swaps.actions';
import { Swap } from '../interfaces/swap';
import { SwapCandidate } from '../interfaces/swap-candidate';
import { Shift } from '../interfaces/shift';
import { State } from '.';

export interface SwapState {
  items: Swap[];
  candidates: SwapCandidate[];
  currentShift: Shift;
}

export const initialState: SwapState = {
  items: [],
  candidates: [],
  currentShift: undefined,
}

const _swapsReducer = createReducer(initialState,
  on(getAllSuccess, (state: SwapState, {swaps}) => ({
    ...state,
    items: swaps
  })),
  on(getAllError, (state: SwapState) => ({
    ...state,
    items: []
  })),
  on(remove, (state: SwapState, {id}) => ({
    ...state,
    items: state.items.filter(item => item.id !== id)
  })),
  on(getCandidatesSuccess, (state: SwapState, {candidates}) => ({
    ...state,
    candidates
  })),
  on(setCurrentShift, (state: SwapState, {shift}) => ({
    ...state,
    currentShift: shift
  }))
)

export function swapsReducer(state, action) {
  return _swapsReducer(state, action);
}

export const selectSwapsState = (state: State) => state.swaps;

export const selectShiftSwaps = createSelector(
  selectSwapsState,
  (state: SwapState) => [...state.items].filter(item => {
    if (!state.currentShift) return false;
    return item.shiftRequest?.shiftId === state.currentShift?.id || item.shiftWanted?.shiftId === state.currentShift?.id
  })
)