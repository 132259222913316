import { Component, OnInit, Input, OnDestroy, ViewChild, ViewChildren, QueryList } from '@angular/core';
import { Card } from '../interfaces/card';
import { CardsService } from '../cards.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { CardDetailsCategory } from '../interfaces/card-details-category';
import { CardDetails } from '../interfaces/card-details';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-balance-card',
  templateUrl: './balance-card.component.html',
  styleUrls: ['./balance-card.component.scss']
})
export class BalanceCardComponent implements OnInit, OnDestroy {

  @ViewChild('detailsElement') detailsElement: any;
  @ViewChild('detailsContainer') detailsContainer: any;
  @ViewChildren('entryContainer') entryContainers: QueryList<Element>;
  @Input() card: Card;
  detailsSub: Subscription;
  details: any = undefined;
  total = 0;
  opened = false;
  language = 'en';
  sectionStates: {[key:string]: boolean} = {};

  constructor(
    private cardsService: CardsService,
    private cookieService: CookieService
  ) { }

  calculateTotal() {
    let total = 0;
    this.details.categories.map((category: CardDetailsCategory) => total = total + category.amount);
    this.total = parseFloat((parseFloat(`${total}`).toFixed(2)));
  }
  
  open() {
    this.opened = !this.opened;
  }

  getTimeValue(value: number) {
    const parts = `${value}`.split('.');
    let hours = parts[0];
    let minutes = Math.round(60 * parseFloat(`0.${parts[1]}`))

    if (minutes === 60) {
      const hoursNumber = parseInt(hours);
      const correctedHoursNumber = hoursNumber + 1;
      hours = `${(correctedHoursNumber < 10 ? `0${correctedHoursNumber}` : correctedHoursNumber)}`
      minutes = 0;
    }

    return `${hours}:${(minutes < 10 ? `0${minutes}` : minutes)}`;
  }

  openSection(event: any) {
    let id = event.currentTarget.id;
    if (typeof this.sectionStates[id] === undefined) this.sectionStates[id] = false;
    if (!this.sectionStates[id]) {
      this.sectionStates[id] = true;
      event.currentTarget.classList.add('active');
    } else {
      this.sectionStates[id] = false;
      event.currentTarget.classList.remove('active');
    }
  }

  ngOnInit(): void {
    this.language = this.cookieService.get('ess.language');
    this.detailsSub = this.cardsService.getCardDetails(this.card.accountId).subscribe((details: CardDetails) => {
      this.details = details;
      this.calculateTotal();
    }, (error) => {
      console.error('Error retrieving card details for card', this.card, error)
    });
  }

  ngOnDestroy(): void {
    if (this.detailsSub) this.detailsSub.unsubscribe();
  }

}
