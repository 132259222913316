import { Component, OnInit } from '@angular/core';
import { AppService } from '../app.service';
import { TokenService } from '../auth/token.service';
import { UserService } from '../auth/user.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit {

  contracts$ = this.userService.contracts$;
  tabs$ = this.appService.tabs$;

  constructor(
    private userService: UserService,
    private appService: AppService,
    private tokenService: TokenService,
  ) { }

  async ngOnInit() {
    const isAuthenticated = await this.tokenService.isAuthenticated().toPromise();
    if (isAuthenticated) this.appService.updateNavigation();
  }

}
