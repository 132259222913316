import { Component, OnInit, Input } from '@angular/core';
import { Shift } from '../../interfaces/shift';
import { UserService } from '../../auth/user.service';

@Component({
  selector: 'app-shift-header',
  templateUrl: './shift-header.component.html',
  styleUrls: ['./shift-header.component.scss']
})
export class ShiftHeaderComponent implements OnInit {

  @Input() shift: Shift = undefined;
  @Input() extraCssClasses: string;
  @Input() showCalendarLink: boolean = false;
  @Input() showPoints: boolean = false;
  @Input() showActivityType: boolean = false;

  hasDescription = false;
  calendarUrl = '';
  isIOS = false;
  cssClasses = 'shift-header';
  cancelledShiftNames = [
    'GEWEIGERD',
    'DECLINED'
  ]

  constructor(private userService: UserService) { }

  ngOnInit(): void {
    this.isIOS = this.userService.isIOS();

    if (this.shift.pendingDuty) this.cssClasses += ' pending-duty';
    if (this.shift.isRealized) this.cssClasses += ' realized';
    if (this.cancelledShiftNames.includes(this.shift.name)) this.cssClasses += ' declined';
    
    if (this.shift && this.shift.description && this.shift.description !== '') this.hasDescription = true;
    
    this.calendarUrl = `/shift.ical?start=${this.shift.startTime}&end=${this.shift.endTime}&name=${this.shift.name}&description=${this.shift.description}&origin=${window.location.href}`;
  }

}
