<header (click)="openPanel()">
  <h3>{{request.beginTime | djsDateFormat:('dateFormatting.day' | translate)}}</h3>
  <span>
    <span class="status"><span class="indicator {{request.status | lowercase}}"></span>{{'requests.request.status.'+(request.status | lowercase) | translate}}</span>
    <div class="icon arrow" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
  </span>
</header>

<main (click)="openPanel()">
  <p class="type">{{typeTranslationString | translate}}</p>
  <p 
    *ngIf="request.type === 'LeaveWish'"
    [innerHTML]="typeTextTranslationString | translate | formatUnicorn:request.typeName:beginDateString:endDateString">
  </p>
  <p 
    *ngIf="request.type === 'DutyWish'"
    [innerHTML]="typeTextTranslationString | translate | formatUnicorn:request.typeName:beginDateString">
  </p>
  <p 
    *ngIf="request.type === 'WorkInIntervalWish'"
    [innerHTML]="typeTextTranslationString | translate | formatUnicorn:beginDateString:endDateString">
  </p>
  <p *ngIf="request.defailInfo" class="comment">{{request.defailInfo}}</p>
  <p *ngIf="request.descriptionAlt" class="comment"><strong>{{'requests.request.manager' | translate}}:</strong> {{request.descriptionAlt}}</p>
</main>

<app-slide-up-panel 
  [id]="slideUpPanelId"
  [icon]="'/assets/icons/icon-requests.svg'"
  [title]="(typeTranslationString | translate)+': '+(request.beginTime | djsDateFormat:('dateFormatting.day' | translate))"
>
  <div 
    class="text"
    *ngIf="request.type === 'LeaveWish'"
    [innerHTML]="typeTextTranslationString | translate | formatUnicorn:request.typeName:beginDateString:endDateString">
  </div>
  <div 
    class="text"
    *ngIf="request.type === 'DutyWish'"
    [innerHTML]="typeTextTranslationString | translate | formatUnicorn:request.typeName:beginDateString">
  </div>
  <div 
    class="text"
    *ngIf="request.type === 'WorkInIntervalWish'"
    [innerHTML]="typeTextTranslationString | translate | formatUnicorn:beginDateString:endDateString">
  </div>
  <div class="text comment" *ngIf="request.defailInfo">{{request.defailInfo}}</div>
  <div class="text comment" *ngIf="request.descriptionAlt"><strong>{{'requests.request.manager' | translate}}:</strong> {{request.descriptionAlt}}</div>
  <div class="actions" *ngIf="request.status === 'open'">
    <a *ngIf="request.type === 'LeaveWish'" [appCheckPermissions]="['DeleteLeaveWishRequest']" href="javascript:void(0)" class="button primary" (click)="cancelRequest();$event.stopPropagation();">
      {{'buttons.cancelRequest' | translate}}
    </a>
    <a *ngIf="request.type === 'DutyWish'" [appCheckPermissions]="['DeleteDutyRequest']" href="javascript:void(0)" class="button primary" (click)="cancelRequest();$event.stopPropagation();">
      {{'buttons.cancelRequest' | translate}}
    </a>
    <a *ngIf="request.type === 'WorkInIntervalWish'" [appCheckPermissions]="['DeleteWorkNoWorkRequest']" href="javascript:void(0)" class="button primary" (click)="cancelRequest();$event.stopPropagation();">
      {{'buttons.cancelRequest' | translate}}
    </a>
  </div>
</app-slide-up-panel>