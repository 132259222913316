<app-slide-up-panel 
  [id]="slideUpPanelId"
  [icon]="'/assets/icons/icon-alert.svg'"
  [title]="error?.custom ? ('errors.'+error?.code+'.title' | translate) : translations['errors.main.title']"
  (onVisibilityChange)="onSlideUpPanelVisibilityChanged($event)">
  <ng-container *ngIf="error">
    <p *ngIf="!error.custom && error.showText">{{error.text}}</p>
    <p *ngIf="!error.custom && !error.showText">{{translations['errors.main.text']}}</p>
    <p *ngIf="error.custom">{{'errors.'+error.code+'.text' | translate}}</p>
    <div class="actions">
      <button *ngIf="error.type === 'OK'" class="button negative" (click)="dismiss()">{{translations['errors.main.buttons.ok']}}</button>
      <button *ngIf="error.type === 'CTA'" class="button negative" (click)="dismiss()">{{translations['errors.main.buttons.callToAction']}}</button>
    </div>
  </ng-container>
</app-slide-up-panel>
