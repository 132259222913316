import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './slider.component';
import { SlideComponent } from './slide/slide.component';
import { SharedModule } from '../shared/shared.module';

@NgModule({
  declarations: [
    SliderComponent,
    SlideComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
  ],
  exports: [
    SliderComponent,
    SlideComponent,
  ]
})
export class SliderModule { }
