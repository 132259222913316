<div class="overlay" (click)="hide()"></div>
<div class="panel">
  <header *ngIf="type === 'default'" class="{{(titleBorder ? 'has-border' : '')}}">
    <span>
      <div *ngIf="icon" class="icon primary" [inlineSVG]="icon"></div>
      {{title}}
    </span>
    <div (click)="hide()" class="icon main" [inlineSVG]="'/assets/icons/icon-close.svg'">X</div>
  </header>
  <main>
    <ng-content></ng-content>
  </main>
</div>