import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { BaseComponent } from '../base/base.component';
import { Schedule } from '../interfaces/schedule';
import { ScheduleService } from '../schedule.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Day } from '../interfaces/day';
import * as dayjs from 'dayjs';
import { UserService } from '../auth/user.service';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.scss']
})
export class ScheduleComponent extends BaseComponent implements OnInit {

  currentDate = dayjs().startOf('day');
  nextMonth: string;
  month$ = this.scheduleService.month$;
  days$: Observable<Day[]> = this.month$.pipe(map((schedule: Schedule) => schedule ? schedule.days : []));
  extraShiftCount$: Observable<number> = this.month$.pipe(map((schedule: Schedule) => schedule ? schedule.extraShiftCount : 0));
  contracts$ = this.userService.contracts$;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private scheduleService: ScheduleService,
    private userService: UserService,
    @Inject(DOCUMENT) private document: Document,
    @Inject("windowObject") private window: Window,
  ) {
    super();
  }

  scrollToTop() {
    const position = this.document.body.scrollTop || this.document.documentElement.scrollTop;
    if (position) {
      this.window.scrollBy(0, -Math.max(10, Math.floor(position / 10)));
      requestAnimationFrame(this.scrollToTop.bind(this));
    }
  }

  ngOnInit(): void {
    this.currentDate = dayjs().startOf('day');
    this.nextMonth = this.currentDate.clone().add(1, 'month').startOf('month').startOf('day').format();

    this.addSubscription(this.route.params.subscribe(params => {
      this.currentDate = dayjs(params.date);
      this.nextMonth = this.currentDate.clone().add(1, 'month').startOf('month').startOf('day').format();
      
      this.scrollToTop();
    }))
  }

  onDatePressed(date: string) {
    this.router.navigate([`/schedule/${this.currentDate.toISOString()}/day/${date}`])
  }

  onMonthChanged(date: string) {
    this.router.navigate([`/schedule/${date}`])
  }

  shouldShowDate(date: Date): boolean {
    const today = dayjs();
    const checkDate = dayjs(date);
    if (checkDate.isBefore(today, 'day') && checkDate.isSame(today, 'month')) return false;
    return true;
  }
  
}
