<nav class="header">
  <button (click)="backToOverview()">
    <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-calendar.svg'"></div>
  </button>
  <span>{{dateString | djsDateFormat:('dateFormatting.day' | translate)}}</span>
  <ng-container *ngIf="teamScheduleEnabled$ | async as enabled">
    <button *ngIf="enabled === false"></button>
    <button *ngIf="enabled === true" (click)="showTeamSchedulePanel()">
      <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-team.svg'"></div>
    </button>
  </ng-container>
</nav>

<app-calendar 
  [schedule]="days$ | async" 
  [showNavigation]="false"
  [showNavigation]="true"
  [showCalendar]="true"
  [currentDate]="calendarCurrentDate"
  [viewMode]="calendarViewMode"
  (datePressed)="onDatePressed($event)"
></app-calendar>

<div class="requests">
  <app-swap-request 
    *ngFor="let request of swaps$ | async" 
    [request]="request" 
    [showTools]="true"
    [showHeader]="false">
  </app-swap-request>
  <ng-container *ngFor="let shift of extraShifts$ | async">
    <ng-container *ngFor="let request of shift.requests">
      <div class="card" *ngIf="request.type === 'DutyWish'">
        <main>
          <p [innerHTML]="'shiftPicking.request.waitingForConfirmation' | translate | formatUnicorn:request.typeName"></p>
          <a href="javascript:void(0)" 
            [appCheckPermissions]="['DeleteDutyRequest']"
            class="button secondary small"
            (click)="cancelRequest(request.id, request.type);$event.stopPropagation();">
            {{'buttons.cancel' | translate}}
          </a>
        </main>
      </div>
    </ng-container>
  </ng-container>
</div>

<div class="toolbox" *ngIf="(shiftCount$ | async) && ((allowSwap && (swapCandidateCount$ | async)) || allowRealization)">
  <a href="javascript:void(0)" *ngIf="allowSwap && (swapCandidateCount$ | async)" (click)="showSwapPanel()" class="button action">{{'buttons.requestSwap' | translate}}</a>
  <a href="javascript:void(0)" *ngIf="allowRealization" [appCheckPermissions]="['RealizeShiftPublished']" (click)="showRealizationPanel()" class="button action">{{'buttons.updateHours' | translate}}</a>
</div>

<div *ngIf="!(shiftCount$ | async )" class="card no-bg">
  <div class="shift-header">
    <div class="content">
      <div class="no-shift">
        <div class="icon main" [inlineSVG]="'/assets/icons/icon-rest.svg'"></div> {{'schedule.noShift' | translate}}
      </div>
    </div>
  </div>
</div>

<ng-container *ngFor="let shift of shifts$ | async">
  <ng-container *ngIf="shift.resourceId !== 0">
    <div class="card">
      <app-shift-header [shift]="shift" [showCalendarLink]="true"></app-shift-header>
      <a *ngIf="shift.pendingDuty" (click)="openCancelRealization(shift)" href="javascript:void(0)" class="button small secondary">{{'realizations.cancel.button' | translate}}</a>
      <app-shift-timeline [shift]="shift"></app-shift-timeline>
    </div>
  </ng-container>
</ng-container>

<app-shift-picking-widget
  [appCheckPermissions]="['ViewShiftPickingList']"
  *ngIf="(extraShiftCount$ | async)"
  [date]="dateString"
  [count]="(extraShiftCount$ | async)"
  [shifts]="extraShifts$ | async"
></app-shift-picking-widget>

<app-slide-up-panel 
  *ngIf="(shiftCount$ | async)"
  [id]="swapSlideUpPanelId"
  [icon]="'/assets/icons/icon-swap.svg'"
  [title]="translations['swaps.form.title']">
  <app-request-swap-form 
    [date]="dateString"
    [slideUpPanelId]="swapSlideUpPanelId"
    [shifts]="shifts$ | async"
    (onSubmit)="refresh()">
  </app-request-swap-form>
</app-slide-up-panel>

<app-slide-up-panel
  [id]="teamSlideUpPanelId"
  [icon]="'/assets/icons/icon-team.svg'"
  [title]="translations['teamSchedule.title']">
  <app-team-schedule
    [date]="dateString">
  </app-team-schedule>
</app-slide-up-panel>

<app-slide-up-panel
  [id]="realizationSlideUpPanelId"
  [icon]="'/assets/icons/icon-update-hours.svg'"
  [title]="translations['realizations.form.title']">
  <app-realization-form
    [slideUpPanelId]="realizationSlideUpPanelId"
    [shifts]="realizeableShifts$ | async">
  </app-realization-form>
</app-slide-up-panel>

<app-slide-up-panel
  [id]="confirmCancelRealizationSlideUpPanelId"
  [type]="'confirm'"
  [icon]="'/assets/icons/icon-update-hours.svg'"
  [title]="'realizations.cancel.title' | translate">
  <div class="confirm-box">
    <p [innerHTML]="'realizations.cancel.text' | translate"></p>
    <div class="actions">
      <a href="javascript:void(0)" (click)="closeCancelRealization()" class="button secondary">{{'buttons.no' | translate}}</a>
      <a href="javascript:void(0)" (click)="cancelRealization()" class="button primary">{{'buttons.yes' | translate}}</a>
    </div>
  </div>
</app-slide-up-panel>