<nav *ngIf="isMenuChild" class="header">
  <button routerLink="/menu">
    <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-arrow-left.svg'"></div>
  </button>
  <span>{{'navigation.requests' | translate}}</span>
  <button></button>
</nav>

<section *ngIf="(swapCount$ | async) > 0">
  <header>
    <div class="icon primary" [inlineSVG]="'/assets/icons/icon-swap.svg'"></div>
    {{'requests.titleSwaps' | translate}}
  </header>
  <app-swap-request 
    *ngFor="let request of swaps$ | async" 
    [request]="request">
  </app-swap-request>
</section>

<section *ngIf="(requestCount$ | async) > 0">
  <header>
    <div class="icon primary" [inlineSVG]="'/assets/icons/icon-requests.svg'"></div>
    {{'requests.titleRequests' | translate}}
  </header>
  <app-request
    *ngFor="let request of requests$ | async"
    [request]="request">
  </app-request>
</section>

<p *ngIf="(swapCount$ | async) === 0 && (requestCount$ | async) === 0" class="no-data-indicator">{{'noData' | translate}}</p>

<app-context-menu
  [appCheckPermissions]="['CreateWorkNoWorkRequest','CreateDutyRequest','CreateLeaveWishRequest']"
  [permissionCheckMode]="'OR'"
  [title]="contextMenuTitle"
  [items]="contextMenuItems"
  (onItemPressed)="onContextMenuPressed($event)">
</app-context-menu>

<app-slide-up-panel 
  [id]="slideUpPanelId"
  [icon]="'/assets/icons/icon-requests.svg'"
  [title]="translations['requests.form.title']">
  <app-request-form 
    [context]="context" 
    (submit)="onRequestFormSubmit($event)"
    (cancel)="onRequestFormCancel($event)">
  </app-request-form>
</app-slide-up-panel>
