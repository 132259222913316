import { trigger, transition, query, style, group, animate, animateChild } from '@angular/animations';

export function slideTo(direction: string){
  return [
    query(':enter, :leave', [
      style({
        position: 'absolute',
        [direction]: 0,
        width: '100%'
      })
    ], { optional: true }),
    query(':enter', [
      style({ [direction]: '-100%'})
    ], { optional: true }),
    group([
      query(':leave', [
        animate('300ms ease-in-out', style({ [direction]: '100%'}))
      ], { optional: true }),
      query(':enter', [
        animate('300ms ease-in-out', style({ [direction]: '0%'}))
      ], { optional: true })
    ]),
    // Normalize the page style... Might not be necessary
    // Required only if you have child animations on the page
    // query(':leave', animateChild()),
    // query(':enter', animateChild()),
  ];
}

export const slider =
  trigger('routeAnimations', [
    transition('* => isLeft', slideTo('left') ),
    transition('* => isRight', slideTo('right') ),
    transition('isRight => *', slideTo('left') ),
    transition('isLeft => *', slideTo('right') ),
  ]);