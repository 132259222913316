import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { AuthService } from './auth.service';
import { TokenService, TokenSet } from './token.service';
import { catchError, filter, first, map, switchMap, take } from 'rxjs/operators';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {

  isRefreshing: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private injector: Injector,
  ) {}

  get tokenService() {
    return this.injector.get(TokenService);
  }

  get authService() {
    return this.injector.get(AuthService);
  }
  
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    console.log(`[UnauthorizedInterceptor] url`, request.url);
    if (!request.url.match(/^\/api/)) return next.handle(request);
    if (request.url.match(/^\/api\/auth/)) return next.handle(request);

    console.log(`[UnauthorizedInterceptor] isRefreshing`, this.isRefreshing);

    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status !== 401) return throwError(error);
        if (this.isRefreshing) {
          return this.tokenSubject.pipe(
            filter(accessToken => accessToken !== null),
            take(1),
            switchMap((accessToken: string) => {
              return next.handle(request.clone({
                setHeaders: {
                  Authorization: `Bearer ${accessToken}`
                }
              }));
            })
          )
        } else {
          this.isRefreshing = true;
          this.tokenSubject.next(null);
          return this.tokenService.getTokens().pipe(
            // first(),
            switchMap((tokens) => {
              console.log(`[UnauthorizedInterceptor] tokens`, tokens);
              return this.tokenService.refreshToken(tokens);
            }),
            switchMap((newTokens: TokenSet) => {
              console.log(`[UnauthorizedInterceptor] newTokens`, newTokens);
              this.isRefreshing = false;
              this.tokenSubject.next(newTokens.accessToken);
              return next.handle(request.clone({
                setHeaders: {
                  Authorization: `Bearer ${newTokens.accessToken}`
                }
              })).pipe(
                catchError((error: HttpErrorResponse) => {
                  console.log(`[UnauthorizedInterceptor] caught error`, error);
                  this.authService.bailOut();
                  console.log(`[UnauthorizedInterceptor] after bailout`);
                  return throwError(error);
                }
              ))
            })
          )
        }
      })
    )
  }
}
