<a class="trigger" href="javascript:void(0)" (click)="toggle()">
  <div class="icon primary" [inlineSVG]="'/assets/icons/icon-shift-available.svg'"></div>
  <span>
    <h3>{{'shiftPicking.notifier.title' | translate}}</h3>
    <p>{{'shiftPicking.notifier.textDay' | translate | formatUnicorn:count}}</p>
  </span>
  <div class="icon arrow" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
</a>
<div class="content">
  <header>Shifts available on this day</header>
  <div class="shifts">
    <app-shift 
      *ngFor="let shift of shifts"
      [shift]="shift"
      [shiftPicking]="true"
      [card]="false"
      [date]="date">
    </app-shift>
  </div>
</div>
<a href="javascript:void(0)" [routerLink]="'/schedule/picking'" class="button primary tool">{{'shiftPicking.notifier.button' | translate}}</a>