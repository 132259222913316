<app-slide-up-panel
  [id]="slideUpPanelId"
  [type]="'confirm'"
  [icon]="'/assets/icons/icon-shift-available.svg'"
  [title]="'shiftPicking.confirm.title' | translate">
  <div class="confirm-box">
    <p *ngIf="mode === 'pick'"
      [innerHTML]="'shiftPicking.confirm.confirmPick' | translate | formatUnicorn:shift?.name:(shift?.startTime | djsDateFormat:('dateFormatting.day' | translate))"></p>
    <p *ngIf="mode === 'request'"
      [innerHTML]="'shiftPicking.confirm.confirmRequest' | translate | formatUnicorn:shift?.name:(shift?.startTime | djsDateFormat:('dateFormatting.day' | translate))"></p>
    <div class="actions">
      <a href="javascript:void(0)" (click)="close()" class="button secondary">{{'buttons.cancel' | translate}}</a>
      <a *ngIf="mode === 'pick'" href="javascript:void(0)" (click)="pickShift(shift)" class="button primary">{{'buttons.confirm' | translate}}</a>
      <a *ngIf="mode === 'request'" href="javascript:void(0)" (click)="applyForShift(shift)" class="button primary">{{'buttons.confirm' | translate}}</a>
    </div>
  </div>
</app-slide-up-panel>