import { Injectable, Inject } from '@angular/core';
import { AuthService } from './auth.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { from, Observable } from 'rxjs';
import { map, first } from 'rxjs/operators';
import * as Bowser from "bowser";
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { CookieService } from 'ngx-cookie-service';
import { HttpClient } from '@angular/common/http';
import { Contract } from '../interfaces/contract';

export enum PermissionCheckMode {
  AND = 'AND',
  OR = 'OR'
}

@Injectable({
  providedIn: 'root'
})
export class UserService {
  
  browser: Bowser.Parser.Parser;
  jwt: JwtHelperService;

  contracts$ = this.store.select('auth', 'contracts');
  activeContract$ = this.store.select('auth', 'activeContract');

  constructor(
    @Inject("windowObject") private window: Window,
    private authService: AuthService,
    private store: Store<State>,
    private cookieService: CookieService,
    private http: HttpClient,
  ) { 
    this.jwt = new JwtHelperService();
    this.browser = Bowser.getParser(window.navigator.userAgent);
  }

  equalTokenValue(property: string, value: any): Observable<boolean> {
    return from(this.authService.getUser()).pipe(map(user => {
      return user[property] === value;
    }))
  }

  equalContractId(id: number): Observable<boolean> {
    return from(this.authService.getContract()).pipe(map(contract => {
      return contract.id === id;
    }))
  }

  isMine(resourceId: number): Promise<boolean> {
    return this.equalTokenValue('resourceId', resourceId).toPromise();
  }

  isMineByContract(resourceId: number): Promise<boolean> {
    return this.equalContractId(resourceId).toPromise();
  }

  isAndroid(): boolean {
    return this.browser.is('android');
  }

  isIOS(): boolean {
    return this.browser.is('ios');
  }

  getLanguage(): string {
    const defaultLang = 'en';
    const cookieLang = this.cookieService.get('ess.language');
    return cookieLang !== '' ? cookieLang : defaultLang;
  }

  async getSetting(setting: string): Promise<any> {
    const settings = await this.store.select((state: State) => state.auth.settings).pipe(first()).toPromise();
    return settings[setting];
  }

  async isAllowed(permissions: string[], mode: PermissionCheckMode = PermissionCheckMode.AND): Promise<boolean>  {
    const userPermissions = await this.store.select((state: State) => state.auth.permissions).pipe(first()).toPromise();
    if (!userPermissions) return false;
    let grantPermission = mode === PermissionCheckMode.AND ? true : false;

    permissions.map((permission: string) => {
      if (mode === PermissionCheckMode.AND) {
        if (userPermissions[permission] !== true) grantPermission = false;
      } else if (mode === PermissionCheckMode.OR) {
        if (userPermissions[permission] === true) grantPermission = true;
      }
    });

    return grantPermission;
  }

  getContracts(): Observable<Contract[]> {
    return this.http.get<Contract[]>(`/api/users/contracts`);
  }
}
