import { Theme } from '../theme/theme.interface';

export const Magazine324: Theme = {
  name: '324',
  properties: {
    'grey-light': '#F6F6F6',
    'grey': '#E5E5E5',
    'background': '#F6F6F6',
    
    'primary': '#63358B',
    'primary-rgb': '99, 53, 139',
    'primary-d-10': '#592f7d',
    'primary-d-20': '#4f2a6f',
    'primary-d-40': '#3b1f53',

    'accent': '#E40046',
    'accent-rgb': '228, 0, 70',
    'accent-d-10': '#cd003e',
    'accent-d-20': '#b60037',
    'accent-d-40': '#880029',

    'secondary': 'var(--primary)',
    'secondary-d-10': 'var(--primary-d-10)',
    'secondary-d-20': 'var(--primary-d-20)',
    'secondary-d-40': 'var(--primary-d-40)',
    'on-secondary': '#63358B',

    'top-nav': 'var(--primary)',
    'on-top-nav': 'var(--on-primary)',

    'tab-text': 'var(--on-primary)',
    'tab-text-inactive': 'rgba(255, 255, 255, 0.7)',
    'tab-bg-inactive': 'var(--primary-d-20)',
    'tab-border': 'var(--primary-d-20)',

    'button-nav-background': 'var(--primary-d-10)',
    'button-nav-border': 'var(--primary-d-20)',
    'button-nav-background-active': 'var(--on-primary)',
    'button-nav-on-active': 'var(--primary)',
    'button-nav-on-inactive': 'var(--on-primary)',

    'sp-banner-bg': 'var(--accent)',
    'sp-banner-text': 'var(--on-accent)',
    'sp-banner-icon': 'var(--on-accent)',

    'primary-button-shadow': '0 1px 0 0 var(--primary-d-20), 0 2px 0 0 rgba(0, 0, 0, 0.2)',
    'secondary-button-bg': 'var(--grey-light)',
    'secondary-button-text': 'var(--primary)',

    'secondary-button-shadow': '0 1px 0 0 var(--grey), 0 2px 0 0 rgba(0, 0, 0, 0.2)',

    'action-button-bg': 'var(--accent)',
    'action-button-text': 'var(--on-accent)',
    'action-button-border': 'var(--accent)',
    'action-button-shadow': '0 1px 0 0 var(--accent-d-20), 0 2px 0 0 rgba(0, 0, 0, 0.2)',
    'confirmation-button-bg': 'var(--primary)',
    'confirmation-button-text': 'var(--on-primary)',
    'confirmation-button-border': 'var(--primary)',
    'confirmation-button-shadow': '0 1px 0 0 var(--primary-d-20), 0 2px 0 0 rgba(0, 0, 0, 0.2)',

    'icon-main': 'var(--primary)',
    'icon-arrow': 'var(--primary)',

    'sup-border-radius': '4px 4px 0 0',
    'sup-bg': 'var(--background)',

    'input-bg': 'var(--white)',

    'input-icon': 'var(--primary)',
    'switch-bg': 'var(--grey)',

    'switch-active-bg': 'var(--white)',
    'switch-active-text': 'var(--primary)',

    'card-border-radius': '4px',
    'card-shadow': '0px 4px 8px rgba(0, 0, 0, 0.05)',

    'calendar-border': 'var(--grey)',
  }
};