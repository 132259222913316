<ng-container *ngFor="let tab of tabs$ | async">
  <a *ngIf="tab === 'schedule'"         [appCheckPermissions]="['ViewRosterList']" routerLink="/schedule" routerLinkActive="active">{{'navigation.schedule' | translate}}</a>
  <a *ngIf="tab === 'requests'"         [appCheckPermissions]="['ViewRequestsList']" routerLink="/requests" routerLinkActive="active">{{'navigation.requests' | translate}}</a>
  <a *ngIf="tab === 'balance'"          [appCheckPermissions]="['ViewAccountsList']" routerLink="/balance" routerLinkActive="active">{{'navigation.balance' | translate}}</a>
  <a *ngIf="tab === 'availability'"     [appCheckPermissions]="['DeleteWorkNoWorkRequest', 'CreateWorkNoWorkRequest']" routerLink="/availability" routerLinkActive="active">{{'navigation.availability' | translate}}</a>
  <a *ngIf="tab === 'selfScheduling'"   [appCheckPermissions]="['SelfScheduling']" routerLink="/self-scheduling" routerLinkActive="active">{{'navigation.selfScheduling' | translate}}</a>
  <a *ngIf="tab === 'timeRegistration'" [appCheckPermissions]="['ViewRosterList']" routerLink="/time-registration" routerLinkActive="active">{{'navigation.timeRegistration' | translate}}</a>
  <a *ngIf="tab === 'menu'"             routerLink="/menu" routerLinkActive="active" class="small">
    <div class="icon" [inlineSVG]="'/assets/icons/icon-more.svg'"></div>
  </a>
</ng-container>