import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private http: HttpClient) { }

  send(resourceId: number, action: string, args: any[]): void {
    this.http.post(`/api/notifications`, {
      resources: [{resourceId}],
      action,
      args
    }).subscribe(() => {})
  }
  
}
