<ng-container *ngIf="approvableShifts$ | async as shifts">
  <h3>{{'confirmShifts.title' | translate}}</h3>
  <app-slider>
    <app-slider-slide *ngFor="let shift of shifts">
      <div class="card">
        <header><h3>{{shift.startTime | djsDateFormat:('dateFormatting.day' | translate)}}</h3></header>
        <app-shift-header [shift]="shift" [showActivityType]="true"></app-shift-header> 
        <div class="actions">
          <a href="javascript:void(0)" (click)="declineShift(shift.id)" class="button small secondary">{{'buttons.decline' | translate}}</a>
          <a href="javascript:void(0)" (click)="confirmShift(shift.id)" class="button small primary">{{'buttons.accept' | translate}}</a>
        </div>
      </div>
    </app-slider-slide>
  </app-slider>
</ng-container>