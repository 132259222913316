<div class="error">
  <div
    class="icon primary" 
    [inlineSVG]="'/assets/icons/'+(icons[code] ? icons[code] : icons['generic'])">
  </div>
  <h1 [innerHTML]="('errors.'+(supportedErrors[code] ? code : 'generic')+'.text') | translate">
  </h1>
  <a *ngIf="supportedErrors[code] ? supportedErrors[code].button : supportedErrors['generic'].button" 
    href="javascript:void(0)"
    class="button primary"
    (click)="retry()">
    {{('errors.'+(supportedErrors[code] ? code : 'generic')+'.button') | translate}}
  </a>
  <!-- <pre class="code">
    {{'errors.codePrefix' | translate}}{{formattedCode}}
  </pre>  -->
</div>