import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm = new UntypedFormGroup({
    username: new UntypedFormControl(''),
    password: new UntypedFormControl(''),
  })

  constructor(private authService: AuthService,
              private cookieService: CookieService,
              private router: Router) { }

  ngOnInit(): void {
  }

  onSubmit() {
    console.log(this.loginForm.value);
    const language = this.cookieService.get('ess.language');
    const uid = this.cookieService.get('ess.uid');
    const magazineId = this.cookieService.get('ess.magazine');
    this.authService.comtecLogin(
      this.loginForm.controls.username.value,
      this.loginForm.controls.password.value,
      uid,
      parseInt(magazineId),
      language
    ).subscribe((response: any) => {
      this.authService.login(response.accessToken, response.refreshToken);
      this.router.navigate([`/schedule`]);
    }, error => {
      console.error('Error logging in', error);
      this.authService.logout();
    });
  }

}
