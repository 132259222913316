import { Component, OnInit, Inject, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { SnakeOrKebabToCamelCase } from '../utils';

@Component({
  selector: 'app-generic-error',
  templateUrl: './generic-error.component.html',
  styleUrls: ['./generic-error.component.scss']
})
export class GenericErrorComponent implements OnInit {

  type: string;
  code: string;
  formattedCode: string;
  icons = {
    'generic': 'icon-server.svg',
    'noCookies': 'icon-server.svg',
    'loginFailed': 'icon-logout.svg',
  };
  supportedErrors = {
    'generic': {
      button: true,
    },
    'loginFailed': {
      button: false
    },
    'noCookies': {
      button: false
    },
    'coreOutdated': {
      button: false
    }
  }

  constructor(
    @Inject("windowObject") private window: Window,
    private router: Router,
    private route: ActivatedRoute,
    private injector: Injector,
    private cookieService: CookieService,
  ) { }

  retry() {
    const previousUrl = this.route.snapshot.queryParams.previous;
    
    if (previousUrl) {
      this.window.location.href = decodeURI(previousUrl);
    } else {
      this.router.navigate(['/']);
    }
  }

  ngOnInit(): void {
    this.code = SnakeOrKebabToCamelCase(this.route.snapshot.queryParams.code);
    this.formattedCode = `${this.route.snapshot.queryParams.code}`.replace(/-/g, ' ');
  }
}
