import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Store } from '@ngrx/store';
import { EMPTY, Observable, of } from 'rxjs';
import { catchError, first, map, switchMap } from 'rxjs/operators';
import { AppService } from '../app.service';
import { State } from '../reducers';
import { setTokens } from '../reducers/auth.actions';
import { isNetworkConnected } from '../utils';
import { AuthService } from './auth.service';

export interface TokenSet {
  accessToken: string;
  refreshToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class TokenService {

  jwt: JwtHelperService;

  constructor(
    private store: Store<State>,
    private injector: Injector,
    private authService: AuthService,
    private appService: AppService,
  ) { 
    this.jwt = new JwtHelperService();
  }

  get jwtService() {
    return this.injector.get(JwtHelperService);
  }

  get http() {
    return this.injector.get(HttpClient);
  }

  getTokens(): Observable<TokenSet> {
    // console.log('TokenService.getTokens()');
    return this.store.select('auth', 'tokens').pipe(first());
  }

  setTokens(tokenSet: TokenSet): TokenSet {
    // console.log('TokenService.setTokens()');
    this.store.dispatch(setTokens(tokenSet));
    return tokenSet;
  }

  refreshToken(tokenSet: TokenSet): Observable<TokenSet> {
    console.warn('REFRESHING!');
    const {refreshToken} = tokenSet;
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${tokenSet.accessToken}`,
    });

    if (!refreshToken) {
      console.warn('No refresh token, bailing out!')
      this.authService.bailOut();
      return EMPTY;
    }

    return this.http.post<TokenSet>('/api/auth/token', {refreshToken}, {headers})
      .pipe(map((tokenSet: TokenSet) => this.setTokens(tokenSet)))
      .pipe(catchError((error) => {
        console.error('Error while refreshing:', error);
        if (error.status === 0 || !isNetworkConnected()) {
          console.warn('Failed to refresh token because of missing network connection!');
          this.appService.blockAndRetry();
        } else if (error) {
          console.warn('Error refreshing tokens, bailing out!')
          this.authService.bailOut();
        }
        return EMPTY;
      }))
  }

  isAuthenticated(): Observable<boolean> {
    // console.log('TokenService.isAuthenticated()');
    return this.getTokens().pipe(switchMap((tokenSet: TokenSet) => {
      // console.log('TokenService.isAuthenticated() tokenSet', tokenSet);
      if (!tokenSet || !tokenSet.accessToken || !tokenSet.refreshToken) return of(false);
      const valid: boolean = !this.jwt.isTokenExpired(tokenSet.accessToken);
      // console.log('TokenService.isAuthenticated() valid tokenSet', valid);
      return of(valid);
    }))
  }

  isAuthenticatedOrRefresh(): Observable<boolean> {
    // console.log('TokenService.isAuthenticatedOrRefresh()');
    return this.getTokens().pipe(switchMap((tokenSet: TokenSet) => {
      // console.log('TokenService.isAuthenticatedOrRefresh() tokenSet', tokenSet);
      if (!tokenSet) return of(false);
      const valid: boolean = !this.jwt.isTokenExpired(tokenSet.accessToken);
      // console.log('TokenService.isAuthenticatedOrRefresh() valid tokenSet', valid);
      if (valid) return of(valid);
      return this.refreshToken(tokenSet).pipe(switchMap((tokenSet: TokenSet) => {
        // console.log('TokenService.isAuthenticatedOrRefresh() refreshed tokenSet', tokenSet);
        if (!tokenSet) return of(false);
        // console.log('TokenService.isAuthenticatedOrRefresh() valid refreshed tokenSet', !this.jwt.isTokenExpired(tokenSet.accessToken));
        return of(!this.jwt.isTokenExpired(tokenSet.accessToken));
      }))
    }))
  }

}
