import { Component, HostBinding, Input, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { CalendarEvent } from '../calendar.component';

@Component({
  selector: 'app-calendar-event',
  templateUrl: './calendar-event.component.html',
  styleUrls: ['./calendar-event.component.scss']
})
export class CalendarEventComponent implements OnInit {

  @Input() event: CalendarEvent;

  @HostBinding('style.left.%') get leftPercentage() {
    return !this.event.isAllDay ? this.calculateTimePercentage(this.event.startTime) : '';
  }

  @HostBinding('style.right.%') get rightPercentage() {
    return !this.event.isAllDay ? this.calculateTimePercentage(this.event.endTime, this.event.startTime) : '';
  }

  @HostBinding('class') get cssClasses() {
    return `event event-${this.event.type} ${this.event.isAllDay ? 'event-allDay' : ''} ${this.event.cssClasses ? this.event.cssClasses.join(' ') : ''}`;
  }

  constructor() { }

  ngOnInit(): void {
  }

  calculateTimePercentage(date: Date | string, begin?: Date | string) {
    if (typeof date === 'string') date = dayjs(date).toDate();
    if (begin && typeof begin === 'string') begin = dayjs(begin).toDate();

    const startOfDay = new Date(date.getTime());
    startOfDay.setHours(0, 0, 0, 0);

    let percentage = ((date.getTime() - startOfDay.getTime()) / (1000 * 60 * 60 * 24) * 100);

    if (begin) {
      percentage = 100 - percentage;
      if (!dayjs(date).isSame(begin, 'day')) {
        percentage = percentage === 100 ? 0 : -(100 - percentage);
      }
    }

    return percentage;
  }

}
