import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InlineSVGModule } from 'ng-inline-svg';
import { SlideUpPanelComponent } from './slide-up-panel.component';
import { SlideUpPanelService } from './slide-up-panel.service';

@NgModule({
  declarations: [
    SlideUpPanelComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule.forRoot(),
  ],
  providers: [
    SlideUpPanelService
  ],
  exports: [
    SlideUpPanelComponent
  ]
})
export class SlideUpPanelModule { }
