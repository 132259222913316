import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as dayjs from 'dayjs';
import { BaseComponent } from '../../base/base.component';

export interface TimeRangeInputValue {
  start: string;
  end: string;
}

@Component({
  selector: 'app-time-range-input',
  templateUrl: './time-range-input.component.html',
  styleUrls: ['./time-range-input.component.scss']
})
export class TimeRangeInputComponent extends BaseComponent implements OnInit {

  start: string;
  end: string;
  @Input() control: UntypedFormControl;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.control) {
      this.addSubscription(this.control.valueChanges.subscribe(value => {
        this.start = value.start;
        this.end = value.end;
      }))
    }
  }

  startChanged(event: any) {
    this.start = event.target.value;
    this.update();
  }

  endChanged(event: any) {
    this.end = event.target.value;
    this.update();
  }

  update() {
    const startDate = dayjs().set('hour', parseInt(this.start.split(':')[0])).set('minute', parseInt(this.start.split(':')[1]))
    const endDate = dayjs().set('hour', parseInt(this.end.split(':')[0])).set('minute', parseInt(this.end.split(':')[1]))
    const endBeforeStart = endDate.isBefore(startDate);

    if (this.control) {
      this.control.setValue({start: this.start, end: this.end})
      if (endBeforeStart) {
        console.error(`end (${this.end}) is before start (${this.start})!`);
        this.control.setErrors({endBeforeStart: true});
      } else {
        this.control.setErrors(null);
      }
    }
  }

}
