import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { User } from '../interfaces/user';

@Injectable()
export class UserResolver implements Resolve<User> {
  constructor(private authService: AuthService) {}

  resolve(): Observable<User> {
    return this.authService.getUser();
  }
}