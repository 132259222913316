<div class="fields {{(isErroneous ? 'error' : '')}}">
  <div class="date-picker {{dateOnly ? 'no-margin' : ''}}">
    <!-- <div #datePlaceholder (click)="clickDatePlaceholder()" class="placeholder">{{'inputs.date.placeholder' | translate}}</div> -->
    <input #dateField type="date" [value]="value.date" min="{{startDate}}" max="{{maxDate}}" (change)="dateChanged($event)" />
  </div>
  <div class="time-picker" *ngIf="!dateOnly">
    <div [style]="value.allDay ? 'display:block;' : 'display:none;'" (click)="setAllDay(false)" class="all-day">{{'inputs.dateTime.allDay' | translate}}</div>
    <div [style]="!value.allDay ? 'display:flex;' : 'display:none;'" class="time-field">
      <!-- <input #dateField type="date" [value]="startDate" min="{{startDate}}" (change)="dateChanged($event)" /> -->
      <input #timeField type="time" min="{{startTime}}" [value]="value.time" max="{{maxDate}}" min="{{minDate}}" (change)="timeChanged($event)" />
      <span (click)="setAllDay(true)">X</span>
    </div>
  </div>
</div>