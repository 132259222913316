import { Directive, Input, ElementRef, OnInit } from '@angular/core';
import { UserService, PermissionCheckMode } from '../../auth/user.service';

@Directive({
  selector: '[appCheckPermissions]'
})
export class CheckPermissionsDirective implements OnInit {

  @Input('appCheckPermissions') permissions: string[];
  @Input('permissionCheckMode') mode: PermissionCheckMode = PermissionCheckMode.AND;
  
  constructor(
    private element: ElementRef,
    private userService: UserService,
  ) { }

  ngOnInit(): void {
    this.userService.isAllowed(this.permissions, this.mode).then(hasPermission => {
      if (!hasPermission) {
        this.element.nativeElement.parentNode.removeChild(this.element.nativeElement);
      }
    })
  }

}
