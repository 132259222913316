import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewChecked, OnChanges, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseComponent } from '../../base/base.component';
import * as dayjs from 'dayjs';

export interface DateTimeInputValue {
  dateTime: Date;
  date: string;
  time: string;
  allDay: boolean;
}

@Component({
  selector: 'app-date-time-input',
  templateUrl: './date-time-input.component.html',
  styleUrls: ['./date-time-input.component.scss']
})
export class DateTimeInputComponent extends BaseComponent implements OnInit, AfterViewChecked {

  @Input() allDay: boolean = true;
  @Input() startDate: string; // expects YYYY-MM-DD
  @Input() startTime: string = '00:00'; // expects HH:mm
  @Input() control: UntypedFormControl;
  @Input() linkedControl: UntypedFormControl;
  @Input() isErroneous: boolean = false;
  @Input() dateOnly: boolean = false;
  @Input() minDate?: string|null = null; // expects YYYY-MM-DD
  @Input() maxDate?: string|null = null; // expects YYYY-MM-DD

  @Output() onDateChange?: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('timeField') timeFieldElement: ElementRef;
  @ViewChild('dateField') dateFieldElement: ElementRef;

  value: DateTimeInputValue = {
    dateTime: null,
    date: null,
    time: this.startTime,
    allDay: this.allDay
  };

  shouldFocusTimeField = false;
  shouldFocusDateField = false;

  constructor() {
    super();
  }

  ngAfterViewChecked(): void {
    if (this.shouldFocusTimeField) {
      this.shouldFocusTimeField = false;
      setTimeout(() => {
        this.timeFieldElement.nativeElement.click();
        this.timeFieldElement.nativeElement.focus();
      }, 300)
    }
  }

  ngOnInit(): void {
    const defaultDate = this.getFormattedDate(this.control.value?.dateTime || new Date());
    const defaultTime = this.getFormattedTime(this.control.value?.dateTime || new Date());
    const defaultAllDay = this.control.value?.allDay;
    
    const date = this.startDate || defaultDate;
    const time = this.startTime || defaultTime;
    const allDay = this.allDay || defaultAllDay;
    const dateTime = this.getDateTime(date, time);

    this.value = { dateTime, date, time, allDay };
    
    this.updateValue();

    this.addSubscription(this.control.valueChanges.subscribe(changes => {
      this.value = changes;
    }))
  }

  getDateTime(date: string, time: string): Date {
    return new Date(`${date ? date : this.getFormattedDate(new Date())}T${time ? time : '00:00'}`)
  }

  getFormattedTime(input: Date) {
    return `${input.getHours()}:${input.getMinutes()}`;
  }

  getFormattedDate(input: Date) {
    const year = input.getFullYear();
    const rawMonth = '' + (input.getMonth() + 1);
    const rawDate = '' + input.getDate();
    const month = rawMonth.length === 1 ? `0${rawMonth}` : rawMonth;
    const date = rawDate.length === 1 ? `0${rawDate}` : rawDate;

    return `${year}-${month}-${date}`
  }

  getLinkedControlValue(value: DateTimeInputValue): DateTimeInputValue {
    const time = value.time.substr(0, 4) + (parseInt(value.time.substr(4, 1)) + 1);
    const dateTime = this.getDateTime(value.date, time);
    return {
      dateTime: dateTime,
      date: value.date,
      time: value.time.substr(0, 4) + (parseInt(value.time.substr(4, 1)) + 1),
      allDay: value.allDay
    }
  }

  updateValue() {    
    console.log('UPDATING VALUE!?');
    const time = this.value.time;
    const date = this.value.date;
    const dateTime = this.getDateTime(date, time);

    this.value.dateTime = dateTime;
    if (this.value.allDay) {
      this.value.time = '00:00';
    }

    if (!this.value.date) {
      this.value.date = this.getFormattedDate(dateTime);
    }

    if (this.control) {
      this.control.setValue({...this.value});
    }

    if (this.linkedControl) {
      this.linkedControl.setValue(
        this.getLinkedControlValue({...this.value})
      );
    }
  }

  setAllDay(allDay: boolean) {
    this.shouldFocusTimeField = !allDay;
    this.value.allDay = allDay;
    this.updateValue();
  }

  dateChanged(event: any) {
    const minDate = dayjs(this.minDate);
    const newDate = dayjs(event.target.value);

    // if (this.minDate && newDate.isBefore(minDate, 'day')) {
    //   this.dateFieldElement.nativeElement.value = this.value.date;
    //   this.updateValue();
    // } else {
      this.value.date = event.target.value;
      this.updateValue();
      this.onDateChange?.emit({date: event.target.value})
    // }
  }

  timeChanged(event: any) {
    this.value.time = event.target.value;
    this.updateValue();
  }

}
