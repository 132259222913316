import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Statistics from '@o4c/plugin-statistics';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class StatisticsService {
  private statistics: Statistics;
  private magazine;

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {
    this.magazine = this.cookieService.get('ess.magazine');
    this.statistics = new Statistics({
      url: '/api/statistics',
      client: 'ess',
      plugin: 'ess',
      proxy: true
    });
  }

  triggerViewEvent(url: string) {
    if (environment.useStatistics) {
      return this.statistics.triggerPageView(this.magazine, url);
    }
  }
}
