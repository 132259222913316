<nav *ngIf="showNavigation">
  <div *ngIf="showSwitch" class="mode-switch {{!showCalendar ? 'list' : 'calendar'}}">
    <a href="javascript:void(0)" (click)="toggleShowCalendar()">
      <div class="icon {{showCalendar ? 'on-' : ''}}primary" [inlineSVG]="'/assets/icons/icon-list-view.svg'"></div> 
    </a>
    <a href="javascript:void(0)" (click)="toggleShowCalendar()">
      <div class="icon {{!showCalendar ? 'on-' : ''}}primary" [inlineSVG]="'/assets/icons/icon-calendar.svg'"></div> 
    </a>
  </div>
  <div>
    <button (click)="this.navigateCalendar(true)">
      <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-arrow-left.svg'"></div>
    </button>
    <span>{{date | djsDateFormat:'MMMM YYYY'}}</span>
    <button (click)="this.navigateCalendar()">
      <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
    </button>
  </div>
</nav>

<div *ngIf="isSlider" class="calendar {{viewMode}}-view">
  <!-- <div *ngIf="showHeader" class="header">
    <div *ngFor="let day of calendar[0].days">
      {{'calendar.weekDays.'+(day.date | djsDateFormat:'d') | translate}}
    </div>
  </div> -->
  <div class="month">
    <div class="nav" *ngIf="showNavigation" (click)="pressDate(previousMonth)">
      <span>
        <div class="icon primary" [inlineSVG]="'/assets/icons/icon-arrow-left.svg'"></div>
        {{previousMonth | djsDateFormat:'MMMM'}}
      </span>
    </div>
    <ng-container *ngFor="let week of calendar">
      <ng-container *ngFor="let day of week.days">
        <div *ngIf="!day.isOtherMonth" 
          (click)="pressDate(day.date.toISOString())"
          class="{{getDayCssClasses(day)}}">
          <div class="content">
            <div class="day-indicator">{{'calendar.weekDays.'+(day.date | djsDateFormat:'d') | translate}}</div>
            <div class="number">{{day.date | djsDateFormat:'D'}}</div>
          </div>
          <app-calendar-event *ngFor="let event of eventList[day.key]" [event]="event"></app-calendar-event>
          <div *ngFor="let shift of shifts[day.key]"
            class="shift {{(shift.pendingDuty ? 'pending-duty' : '')}} {{(shift.isRealized ? 'realized' : '')}}"
            [style.left.%]="this.calculateTimePercentage(shift.startTime)"
            [style.right.%]="this.calculateTimePercentage(shift.endTime, shift.startTime)">
          </div>
        </div>
      </ng-container>
    </ng-container>
    <div class="nav" *ngIf="showNavigation" (click)="pressDate(nextMonth)">
      <span>
        {{nextMonth | djsDateFormat:'MMMM'}}
        <div class="icon primary" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
      </span>
    </div>
  </div>
</div>

<div *ngIf="!isSlider" class="calendar {{viewMode}}-view {{showCalendar ? 'show' : 'hide'}}" (swipeleft)="this.navigateCalendar()" (swiperight)="this.navigateCalendar(true)">
  <div *ngIf="showHeader && calendar[0]" class="header">
    <div *ngFor="let day of calendar[0].days">
      {{'calendar.weekDays.'+(day.date | djsDateFormat:'d') | translate}}
    </div>
  </div>
  <div class="month">
    <div class="week" *ngFor="let week of calendar">
      <div *ngFor="let day of week.days" 
        (click)="pressDate(day.date.toISOString())"
        class="{{getDayCssClasses(day)}}">
        <div class="content">
          <div class="number">{{day.date | djsDateFormat:'D'}}</div>
        </div>
        <app-calendar-event *ngFor="let event of eventList[day.key]" [event]="event"></app-calendar-event>
        <div *ngFor="let shift of shifts[day.key]"
          class="shift {{(shift.pendingDuty ? 'pending-duty' : '')}} {{(shift.isRealized ? 'realized' : '')}}"
          [style.left.%]="this.calculateTimePercentage(shift.startTime)"
          [style.right.%]="this.calculateTimePercentage(shift.endTime, shift.startTime)">
        </div>
      </div>
    </div>
  </div>
</div>