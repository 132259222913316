import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { MagazineNavigation } from './interfaces/navigation';
import { iMagazineClientConfig } from '@shared/magazine-config';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  configData: any = {};

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) {}

  // async loadConfigData(fileName: string = 'default'): Promise<any> {
  //   console.log(`ConfigService.loadConfigData(${fileName}.json)`);
  //   return this.http.get(`/assets/config/${fileName}.json`).toPromise();
  // }

  // async loadConfig(): Promise<any> {
  //   const magazineId = this.cookieService.get('ess.magazine');
  //   const defaultConfig = await this.loadConfigData();
  //   const magazineConfig = magazineId ? await this.loadConfigData(magazineId).catch(() => {}) : {};
  //   const mergedConfig = {...defaultConfig};

  //   if (magazineConfig) {
  //     for(let key in magazineConfig) {
  //       mergedConfig[key] = magazineConfig[key];
  //     }
  //   }

  //   this.configData = mergedConfig;

  //   console.log(`ConfigService.loadConfig() ->`, this.configData);

  //   return this.configData;
  // }

  // get(key: string): any {
  //   console.log(`ConfigService.get(${key})`);
  //   return this.configData[key];
  // }

  getNavigationConfig(): Observable<MagazineNavigation> {
    return this.http.get<MagazineNavigation>(`/api/magazines/navigation`);
  }

  getLatestConfig(): Observable<iMagazineClientConfig> {
    return this.http.get<iMagazineClientConfig>(`/api/magazines/client`);
  }
}
