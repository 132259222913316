<nav class="header">
  <span>{{'appMenu.title' | translate}}</span>
</nav>

<div class="items">
  <ng-container *ngFor="let item of menu$ | async">
    <a *ngIf="item === 'requests'" [appCheckPermissions]="['ViewRequestsList']" routerLink="/menu/requests" class="item">
      <span class="label">
        <span class="icon primary" [inlineSVG]="'/assets/icons/icon-menu-requests.svg'"></span>
        {{'navigation.requests' | translate}}
      </span>
      <span class="icon main" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></span>
    </a>
    <a *ngIf="item === 'availability'" [appCheckPermissions]="['DeleteWorkNoWorkRequest', 'CreateWorkNoWorkRequest']" routerLink="/menu/availability" class="item">
      <span class="label">
        <span class="icon primary" [inlineSVG]="'/assets/icons/icon-availability.svg'"></span>
        {{'navigation.availability' | translate}}
      </span>
      <span class="icon main" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></span>
    </a>
    <a *ngIf="item === 'selfScheduling'" [appCheckPermissions]="['SelfScheduling']" routerLink="/menu/self-scheduling" class="item">
      <span class="label">
        <span class="icon primary" [inlineSVG]="'/assets/icons/icon-self-scheduling.svg'"></span>
        {{'navigation.selfScheduling' | translate}}
      </span>
      <span class="icon main" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></span>
    </a>
    <a *ngIf="item === 'balance'" [appCheckPermissions]="['ViewAccountsList']" routerLink="/menu/balance" class="item">
      <span class="label">
        <span class="icon primary" [inlineSVG]="'/assets/icons/icon-balance.svg'"></span>
        {{'navigation.balance' | translate}}
      </span>
      <span class="icon main" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></span>
    </a>
    <a *ngIf="item === 'timeRegistration'" [appCheckPermissions]="['ViewRosterList']" routerLink="/menu/time-registration/schedule-pattern" class="item">
      <span class="label">
        <span class="icon primary" [inlineSVG]="'/assets/icons/icon-calendar.svg'"></span>
        {{'navigation.timeRegistration' | translate}}
      </span>
      <span class="icon main" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></span>
    </a>
    <ng-container *ngIf="contracts$ | async as contracts">
      <ng-container *ngIf="item === 'contracts' && contracts.length > 1">
        <a routerLink="/menu/contracts" class="item">
          <span class="label">
            <span class="icon primary" [inlineSVG]="'/assets/icons/icon-contract.svg'"></span>
            {{'navigation.contracts' | translate}}
          </span>
          <span class="icon main" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></span>
        </a>
      </ng-container>
    </ng-container>
  </ng-container>
</div>

<p class="version">ESS Version: {{version}} (production: {{isProduction}})</p>