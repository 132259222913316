export interface iAuth0Config {
  domain: string;
  clientId: string;
  clientSecret: string;
  audience: string;
}

export interface iOwsIntegrationConfig {
  endpoint: string;
  psk: string;
}

export interface iOwsConfig {
  endpoint: string;
  integration: iOwsIntegrationConfig;
}

export enum MagazineNavigationRoute {
  SCHEDULE = 'schedule',
  REQUESTS = 'requests',
  MENU = 'menu',
  AVAILABILITY = 'availability',
  BALANCE = 'balance',
  CONTRACTS = 'contracts',
  SELFSCHEDULING = 'selfScheduling',
  TIMEREGISTRATION = 'timeRegistration',
}

export interface iMagazineNavigation {
  tabs: MagazineNavigationRoute[];
  menu: MagazineNavigationRoute[];
}

export interface iMagazineServerConfig {
  auth0: iAuth0Config;
  ows: iOwsConfig;
}

export interface iTeamScheduleConfig {
  enabled: boolean;
}

export interface iConfirmShiftsConfig {
  enabled: boolean;
}

export interface iAvailabilityConfig {
  default: string;
}

export interface iScheduleConfig {
  locationLink: string;
}

export interface iTimeRegistrationConfig {
  workDayHours: number;
}

export interface iMagazineClientConfig {
  navigation: iMagazineNavigation;
  supportEmail: string;
  teamSchedule: iTeamScheduleConfig;
  confirmShifts: iConfirmShiftsConfig;
  availability: iAvailabilityConfig;
  schedule: iScheduleConfig;
  timeRegistration?: iTimeRegistrationConfig;
}

export interface iMagazineConfig {
  server: iMagazineServerConfig;
  client: iMagazineClientConfig;
}

export const DefaultNavigation: iMagazineNavigation = {
  tabs: [
    MagazineNavigationRoute.SCHEDULE,
    MagazineNavigationRoute.REQUESTS,
    MagazineNavigationRoute.MENU,
  ],
  menu: [
    MagazineNavigationRoute.BALANCE,
    MagazineNavigationRoute.CONTRACTS,
  ],
}

export const DefaultMagazineConfig = {
  server: {
    auth0: {
      domain: '',
      clientId: '',
      clientSecret: '',
      audience: '',
    },
    ows: {
      endpoint: '',
      integration: {
        endpoint: '',
        psk: '',
      }
    },
  },
  client: {
    navigation: {...DefaultNavigation},
    supportEmail: "",
    schedule: {
      locationLink: '',
    },
    teamSchedule: {
      enabled: false,
    },
    confirmShifts: {
      enabled: false,
    },
    availability: {
      default: "available"
    },
  },
};