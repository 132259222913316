import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { CardsService } from '../cards.service';
import { Card } from '../interfaces/card';

@Injectable()
export class CardsResolver implements Resolve<Card[]> {
  constructor(private cardService: CardsService) {}

  resolve(): Observable<Card[]> {
    return this.cardService.getCards();
  }
}