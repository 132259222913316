<div class="overlay {{(open ? 'show' : '')}}" (click)="toggleOpen()"></div>
<a href="javascript:void(0)" class="trigger {{(open ? 'active' : '')}}" (click)="toggleOpen()">
  <div class="icon" [inlineSVG]="'/assets/icons/icon-plus.svg'"></div>
</a>
<nav class="{{(open ? 'show' : '')}}">
  <span class="title">{{title}}</span>
  <a *ngFor="let item of items" 
    href="javascript:void(0)"
    class="button primary"
    (click)="pressItem(item.key)">
    {{item.label}}
  </a>
</nav>