import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../environments/environment';
import { AppService } from '../app.service';
import { UserService } from '../auth/user.service';
import { BaseComponent } from '../base/base.component';

@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss']
})
export class AppMenuComponent extends BaseComponent implements OnInit {

  contracts$ = this.userService.contracts$;
  menu$ = this.appService.menu$;
  isProduction = false;
  version = environment.version;
  
  constructor(
    private userService: UserService,
    private appService: AppService,
    private titleService: Title,
    private translateService: TranslateService
  ) {
    super();
    this.addSubscription(this.translateService.get('navigation.menu').subscribe(translation => {
      this.titleService.setTitle(translation);
    }));
  }

  ngOnInit(): void {
    this.isProduction = environment.production;
  }

}
