import { Component, OnInit, Input, EventEmitter, Output, HostBinding } from '@angular/core';
import { Day } from '../interfaces/day';
import { Router, ActivatedRoute } from '@angular/router';
import { BaseComponent } from '../base/base.component';
import { Shift } from '../interfaces/shift';
import { ShiftPickingService } from '../shift-picking.service';
import * as dayjs from 'dayjs';

@Component({
  selector: 'app-schedule-day',
  templateUrl: './schedule-day.component.html',
  styleUrls: ['./schedule-day.component.scss']
})
export class ScheduleDayComponent extends BaseComponent implements OnInit {

  @Input() day: Day;
  @Input() shiftPicking: boolean = false;

  @HostBinding('class') get cssClass() {
    return `item ${this.isToday ? 'active' : ''} ${this.isWeekend ? 'secondary' : ''}`;
  }
  
  isToday = false;
  isTomorrow = false;
  isWeekend = false;
  isFree = false;
  hasRequestableShift = false;
  cssClasses = '';
  confirmPanelId = 'shiftPickingConfirmationPanel';
  pickingMode = 'pick';
  pickingShift: Shift;
  dateKey = '';

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private shiftPickingService: ShiftPickingService,
  ) {
    super();
  }

  ngOnInit(): void {
    const today = dayjs();
    const tomorrow = dayjs(today).add(1, 'day');
    const day = dayjs(this.day.date);

    this.dateKey = day.format('YYYYMMDD');

    if (day.isSame(today, 'day')) this.isToday = true;
    if (day.isSame(tomorrow, 'day')) this.isTomorrow = true;
    if ([0, 6].includes(day.day())) this.isWeekend = true;
    if (this.day.shiftCount <= 0) this.isFree = true;

    if (this.isToday) this.cssClasses += ' today';
    if (this.isTomorrow) this.cssClasses += ' tomorrow';
    if (this.isWeekend) this.cssClasses += ' weekend';
    if (this.shiftPicking) this.cssClasses += ' shift-picking';
    if (this.day.shiftCount <= 0 && !this.shiftPicking) this.cssClasses += ' no-shift';

    if (this.day.extraShifts) {
      this.day.extraShifts.forEach(shift => {
        if (shift.resourceId === 0 && !shift.requestExistsForDuty) this.hasRequestableShift = true;
      })
    }
  }

  cancelRequest(id: number, type: string) {
    this.addSubscription(this.shiftPickingService.cancelRequest(id, type).subscribe(() => {
      this.shiftPickingService.finishPicking();
    }));
  }

  openDay() {
    let scheduleDate = this.route.snapshot.params.date;
    if (!scheduleDate) scheduleDate = dayjs().startOf('day').toISOString();
    this.router.navigate([`/schedule/${scheduleDate}/day/${this.day.date}`])
  }

}
