<form [formGroup]="loginForm" (ngSubmit)="onSubmit()">
  <label for="username">
    <input 
      type="text" 
      id="username" 
      placeholder="Username"
      formControlName="username" />
  </label>
  <label for="password">
    <input
      type="password" 
      id="password" 
      placeholder="Password"
      formControlName="password" />
  </label>
  <button type="submit">Login</button>
</form>