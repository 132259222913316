import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { BaseComponent } from '../../base/base.component';
import { Shift } from '../../interfaces/shift';

@Component({
  selector: 'app-shift-selector-input',
  templateUrl: './shift-selector-input.component.html',
  styleUrls: ['./shift-selector-input.component.scss']
})
export class ShiftSelectorInputComponent extends BaseComponent implements OnInit {

  @Input() shifts: Shift[] = [];
  @Input() control: UntypedFormControl;
  @Input() selected: Shift;

  constructor() {
    super();
  }

  ngOnInit(): void {
    if (this.shifts.length > 0 && !this.selected) {
      this.select(this.shifts[0]);
    }
    if (this.control) {
      this.addSubscription(this.control.valueChanges.subscribe(value => {
        this.selected = value;
      }))
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shifts) {
      const selectedWithinOptions = changes.shifts.currentValue.filter((shift: Shift) => this.selected && shift.id === this.selected.id);

      if (selectedWithinOptions.length < 1) {
        this.select(changes.shifts.currentValue[0]);
      }
    }
    if (changes.selected) {
      this.selected = changes.selected.currentValue;
    }
  }

  select(shift: Shift) {
    this.selected = shift;
    if (this.control) {
      this.control.setValue(shift);
    }
  }

}
