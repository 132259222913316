import { AfterViewInit, Component, ContentChildren, ElementRef, HostBinding, Input, OnDestroy, QueryList, ViewChild } from '@angular/core';
import KeenSlider, { TOptionsEvents } from 'keen-slider';
import { Subscription } from 'rxjs';
import { SlideComponent } from './slide/slide.component';

@Component({
  selector: 'app-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss']
})
export class SliderComponent implements OnDestroy, AfterViewInit {

  currentSlide: number = 0;
  dotHelper: Array<Number> = [];
  slider: KeenSlider = null;
  isLoading = false;
  $slideListSub: Subscription;

  @Input() loop = true;
  @Input() spacing = 16;

  options: TOptionsEvents = {
    initial: 0,
    loop: this.loop,
    spacing: this.spacing,
    // created: (slider) => {
    //   slider.moveToSlideRelative(0)
    // },
    slideChanged: (slider) => {
      // Set the current slide
      this.currentSlide = slider.details().relativeSlide
    },
  }

  @ViewChild("sliderRef") 
  sliderRef: ElementRef<HTMLElement>

  @ContentChildren(SlideComponent)
  slideList: QueryList<SlideComponent>

  @HostBinding('class') get cssClasses() {
    return this.isLoading ? 'loading' : '';
  }

  constructor() { }

  init(): void {
    setTimeout(() => {
      console.log(`[Slider]: Initializing!`);
      // Set loading state
      this.isLoading = true;
      // If a slider exists, destroy it
      // if (this.slider) this.slider.destroy();
      // Create a new slider
      this.slider = new KeenSlider(this.sliderRef.nativeElement, this.options);
      // Create a helper array to render the navigation dots
      this.dotHelper = [...Array(this.slider.details().size).keys()];
      // Set loading state
      this.isLoading = false;
    })
  }

  update(): void {
    setTimeout(() => {
      console.log(`[Slider]: Updating!`);
      // Resize the slider to fit new slides
      this.slider.resize();
      // Recalculate the dots for navigation
      this.dotHelper = [...Array(this.slider.details().size).keys()];
      // Move to the first slide
      this.moveTo(0);
    }, 500)
  }

  moveTo(index: number) {
    this.slider.moveToSlideRelative(index);
  }

  ngAfterViewInit(): void {
    // Defer and trigger change detection
    setTimeout(() => {
      // Initialize the slider
      this.init();
      // Start listening to changes in the slides
      this.$slideListSub = this.slideList.changes.subscribe((slides) => {
        console.log(`[Slider]: Slides were updated! Now serving ${slides._results.length} slides.`);
        if (slides._results.length > 0) {
          // Set loading state
          this.isLoading = true;
          // Update the slider
          this.update();
        }
      }) 
    }, 500)
  }

  ngOnDestroy(): void {
    if (this.slider) {
      console.log(`[Slider]: Destroying!`);
      this.slider.destroy()
    };
    if (this.$slideListSub) this.$slideListSub.unsubscribe();
  }

}
