import { Component, OnInit } from '@angular/core';
import { Day } from '../interfaces/day';
import { ActivatedRoute, Router } from '@angular/router';
import { CalendarViewMode } from '../shared/calendar/calendar.component';
import { ScheduleService } from '../schedule.service';
import { TranslateService } from '@ngx-translate/core';
import { SlideUpPanelService } from '../slide-up-panel/slide-up-panel.service';
import { Swap } from '../interfaces/swap';
import { Observable } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import * as SwapsActions from '../reducers/swaps.actions';
import * as ScheduleActions from '../reducers/schedule.actions';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { selectShiftSwaps } from '../reducers/swaps.reducer';
import { Shift } from '../interfaces/shift';
import { map } from 'rxjs/operators';
import { Schedule } from '../interfaces/schedule';
import { ShiftPickingService } from '../shift-picking.service';
import { Dayjs } from 'dayjs';
import * as dayjs from 'dayjs';
import { SwapService } from '../swap.service';
import { ConfigService } from '../config.service';
import { UserService } from '../auth/user.service';

@Component({
  selector: 'app-schedule-day-detail',
  templateUrl: './schedule-day-detail.component.html',
  styleUrls: ['./schedule-day-detail.component.scss']
})
export class ScheduleDayDetailComponent extends BaseComponent implements OnInit {

  dateString: string = '';
  calendarCurrentDate: Dayjs;
  calendarViewMode: CalendarViewMode = CalendarViewMode.Slider;
  translations: string[] = [];
  swapSlideUpPanelId = 'requestSwapPanel';
  teamSlideUpPanelId = 'teamSchedulePanel';
  realizationSlideUpPanelId = 'realizationSlideUpPanel';
  confirmCancelRealizationSlideUpPanelId = 'confirmCancelRealizationSlideUpPanel';
  allowSwap = true;
  allowRealization = true;
  allowPublishedRealization = false;
  allowJustifiedRealization = false;
  extraShiftsOpened = false;
  realizationShift: Shift;

  days$: Observable<Day[]> = this.scheduleService.month$.pipe(map((schedule: Schedule) => schedule ? schedule.days : []));
  today$: Observable<Day> = this.scheduleService.today$;
  shifts$: Observable<Shift[]> = this.today$.pipe(map((day: Day) => day ? day.shifts : []));
  shiftCount$: Observable<number> = this.shifts$.pipe(map(shifts => shifts.length));
  extraShifts$: Observable<Shift[]> = this.today$.pipe(map((day: Day) => day ? day.extraShifts : []));
  extraShiftCount$: Observable<number> = this.extraShifts$.pipe(map(shifts => shifts.length));
  swaps$: Observable<Swap[]> = this.store.select(selectShiftSwaps);
  swapCandidateCount$ = this.swapService.swapCandidates$.pipe(map(candidates => candidates.length));
  teamScheduleEnabled$: Observable<boolean> = this.scheduleService.teamScheduleEnabled$;
  realizeableShifts$: Observable<Shift[]> = this.today$
    .pipe(map((day: Day) => day ? day.shifts : []))
    .pipe(map(shifts => shifts.filter(async shift => {
      const isTodayOrBefore = dayjs() >= dayjs(shift.startTime);
      const allowPublishedRealization = await this.userService.isAllowed(['RealizeShiftPublished']);
      const allowJustifiedRealization = await this.userService.isAllowed(['RealizeShiftJustified']);
      return isTodayOrBefore && ((allowPublishedRealization && shift.status === 'PUBLISHED') || (allowJustifiedRealization && shift.status === 'JUSTIFIED'));
    })));


  constructor(
    private store: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private scheduleService: ScheduleService,
    private swapService: SwapService,
    private translateService: TranslateService,
    private slideUpPanelService: SlideUpPanelService,
    private shiftPickingService: ShiftPickingService,
    private configService: ConfigService,
    private userService: UserService,
  ) {
    super();
    this.addSubscription(this.translateService.get([
      'loaders.data',
      'swaps.form.title',
      'teamSchedule.title',
      'realizations.form.title',
    ]).subscribe(translations => {
      this.translations = translations;
    }))
    this.addSubscription(this.route.params.subscribe(params => {
      this.calendarCurrentDate = dayjs(params.date);
      this.dateString = params.date;
      this.getToday();
    }))
  }

  ngOnInit(): void {
    this.store.dispatch(SwapsActions.getAll());
    // this.days = this.route.snapshot.data.schedule.days;
    this.dateString = this.route.snapshot.params.date;
    this.calendarCurrentDate = dayjs(this.dateString);
    this.getToday();
  }

  refresh() {
    this.getToday();
  }

  async getToday() {
    this.store.dispatch(ScheduleActions.getToday({date: this.dateString}));

    this.extraShiftsOpened = false; 
    
    this.allowPublishedRealization = await this.userService.isAllowed(['RealizeShiftPublished']);
    this.allowJustifiedRealization = await this.userService.isAllowed(['RealizeShiftJustified']);

    if (this.calendarCurrentDate.isBefore(dayjs(), 'day') || this.calendarCurrentDate.isSame(dayjs(), 'day')) {
      this.allowSwap = false;
    } else {
      this.allowSwap = true;
    }

    if (!this.allowPublishedRealization && !this.allowJustifiedRealization) {
      this.allowRealization = false;
    }
  }

  cancelRequest(id: number, type: string) {
    this.addSubscription(this.shiftPickingService.cancelRequest(id, type).subscribe(() => {
      this.shiftPickingService.finishPicking();
    }));
  }

  toggleExtraShifts = () => this.extraShiftsOpened = !this.extraShiftsOpened;

  showSwapPanel() {
    this.slideUpPanelService.show(this.swapSlideUpPanelId);
  }

  showTeamSchedulePanel() {
    this.slideUpPanelService.show(this.teamSlideUpPanelId);
  }

  showRealizationPanel() {
    this.slideUpPanelService.show(this.realizationSlideUpPanelId);
  }

  backToOverview() {
    this.router.navigate([`/schedule/${this.route.snapshot.params.overviewDate}`]);
  }

  onDatePressed(date: string) {
    this.router.navigate([`/schedule/${this.route.snapshot.params.overviewDate}/day/${date}`])
  }

  openCancelRealization(shift: Shift) {
    this.realizationShift = shift;
    this.slideUpPanelService.show(this.confirmCancelRealizationSlideUpPanelId);
  }

  closeCancelRealization() {
    this.slideUpPanelService.hide(this.confirmCancelRealizationSlideUpPanelId);
  }

  cancelRealization() {
    this.store.dispatch(ScheduleActions.cancelRealization({
      shiftId: this.realizationShift.id,
      startTime: this.realizationShift.startTime,
      endTime: this.realizationShift.endTime
    }))
    this.closeCancelRealization();
  }

}
