<h3 (click)="openDay()">
  <span>{{day.date | djsDateFormat:('dateFormatting.day' | translate)}}</span>
  <div class="icon arrow" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
</h3>

<!-- <section class="requests" *ngIf="shiftPicking">
  <ng-container *ngFor="let shift of (!shiftPicking ? day.shifts : day.extraShifts)">
    <ng-container *ngFor="let request of shift.requests">
      <div class="card" *ngIf="request.type === 'DutyWish'">
        <main>
          <p [innerHTML]="'shiftPicking.request.waitingForConfirmation' | translate | formatUnicorn:request.typeName"></p>
          <a href="javascript:void(0)" 
            [appCheckPermissions]="['DeleteDutyRequest']"
            class="button secondary small"
            (click)="cancelRequest(request.id, request.type);$event.stopPropagation();">
            {{'buttons.cancel' | translate}}
          </a>
        </main>
      </div>
    </ng-container>
  </ng-container>
</section> -->

<div (click)="openDay()" *ngIf="isFree" class="card no-bg">
  <div class="shift-header">
    <div class="content">
      <div class="no-shift">
        <div class="icon main" [inlineSVG]="'/assets/icons/icon-rest.svg'"></div> {{'schedule.noShift' | translate}}
      </div>
    </div>
  </div>
</div>

<ng-container *ngIf="(shiftPicking && hasRequestableShift) || !shiftPicking">
  <app-shift 
    *ngFor="let shift of (!shiftPicking ? day.shifts : day.extraShifts)"
    [date]="day.date"
    [shiftPicking]="shiftPicking"
    [shift]="shift">
  </app-shift>
</ng-container>