<div class="shift-timeline">
  <div class="entry" *ngFor="let activity of shift.activities; let i = index;">
    <div class="time" *ngIf="i === 0 && shift.pendingDuty && shift.startTimeChanged">
      <span>{{shift.startTime | djsDateFormat:'HH:mm'}}</span>
      {{shift.pendingDuty.startTime | djsDateFormat:'HH:mm'}}
    </div>
    <div class="time" *ngIf="i !== 0 || !shift.pendingDuty || !shift.startTimeChanged">
      {{activity.startTime | djsDateFormat:'HH:mm'}}
    </div>
    <div class="content">
      <h3>{{activity.typeName}}</h3>
      <ng-container *ngIf="activity.workstationName && activity.workstationName !== ''">
        <h4>{{'schedule.workstation' | translate}}</h4>
        <p>{{activity.workstationName}}</p>
      </ng-container>
      <ng-container *ngIf="i === 0">
        <ng-container *ngIf="activity.address && activity.address !== ''">
          <h4>{{'schedule.address' | translate}}</h4>
          <p>
            <a (click)="$event.stopPropagation()" 
              [href]="'https://www.google.com/maps/dir//'+activity.address" 
              [target]="isIOS ? '_self' : '_blank'">
              {{activity.address}}
            </a>
          </p>
        </ng-container>
        <ng-container *ngIf="locationLink$ | async as locationLink">
          <ng-container *ngIf="i === 0">
            <h4>{{'schedule.locationLinkHeader' | translate}}</h4>
            <p>
              <a (click)="$event.stopPropagation()" 
                [href]="locationLink" 
                [target]="isIOS ? '_self' : '_blank'">
                {{'schedule.locationLinkText' | translate}}
              </a>
            </p>
          </ng-container>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div class="entry">
    <div class="time" *ngIf="shift.pendingDuty && shift.endTimeChanged">
      <span>{{shift.endTime | djsDateFormat:'HH:mm'}}</span>
      {{shift.pendingDuty.endTime | djsDateFormat:'HH:mm'}}
    </div>
    <div class="time" *ngIf="!shift.pendingDuty || !shift.endTimeChanged">{{shift.endTime | djsDateFormat:'HH:mm'}}</div>
    <div class="content">
      <h3>{{'schedule.endOfShift' | translate}}</h3>
    </div>
  </div>
</div>
