import { User } from '../interfaces/user';
import { createReducer, on } from '@ngrx/store';
import { setUser, setPermissions, setAuth0, setActiveContract, getContractsSuccess, setTokens, setSettings } from './auth.actions';
import { UserPermissions } from '../interfaces/user-permission';
import { Contract } from '../interfaces/contract';
import { UserSettings } from '../interfaces/user-settings';

export interface TokenState {
  accessToken: string;
  refreshToken: string;
}

export interface AuthState {
  user: User;
  permissions: UserPermissions;
  settings: UserSettings;
  tokens: TokenState;
  contracts: Contract[];
  activeContract: Contract;
}

export const initialState: AuthState = {
  user: undefined,
  permissions: undefined,
  settings: undefined,
  tokens: undefined,
  contracts: [],
  activeContract: undefined,
}

const _authReducer = createReducer(initialState, 
  on(setUser, (state: AuthState, {user}) => ({
    ...state,
    user
  })),
  on(setActiveContract, (state: AuthState, {contract}) => ({
    ...state,
    activeContract: contract
  })),
  on(getContractsSuccess, (state: AuthState, {contracts}) => ({
    ...state,
    contracts
  })),
  on(setPermissions, (state: AuthState, {permissions}) => ({
    ...state,
    permissions
  })),
  on(setSettings, (state: AuthState, {settings}) => ({
    ...state,
    settings
  })),
  on(setAuth0, (state: AuthState, {accessToken, refreshToken}) => ({
    ...state,
    auth0: {
      accessToken,
      refreshToken
    }
  })),
  on(setTokens, (state: AuthState, {accessToken, refreshToken}) => ({
    ...state,
    tokens: {
      accessToken,
      refreshToken
    }
  })),
);

export function authReducer(state, action) {
  return _authReducer(state, action);
}