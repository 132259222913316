import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { ContextMenuItem } from '../context-menu/context-menu.component';
import { TranslateService } from '@ngx-translate/core';
import { RequestService } from '../request.service';
import { SlideUpPanelService } from '../slide-up-panel/slide-up-panel.service';
import { BaseComponent } from '../base/base.component';
import { SwapService } from '../swap.service';
import { Swap } from '../interfaces/swap';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { Request } from '../interfaces/request';
import * as RequestsActions from '../reducers/requests.actions';
import * as SwapsActions from '../reducers/swaps.actions';
import { UserService, PermissionCheckMode } from '../auth/user.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent extends BaseComponent implements OnInit, OnDestroy {

  contextMenuTitle: string = '';
  contextMenuItems: ContextMenuItem[] = [];
  slideUpPanelId = 'newRequestPanel';
  translations: string[] = [];
  context: string = 'wantWork';
  isMenuChild = false;

  requests$: Observable<Request[]> = this.requestService.requests$;
  requestCount$: Observable<number> = this.requestService.requestCount$;
  swaps$: Observable<Swap[]> = this.swapService.swaps$;
  swapCount$: Observable<number> = this.swapService.swapCount$;

  @HostBinding('class') get cssClasses() {
    return `${this.isMenuChild ? 'menu-child' : ''}`;
  }
  
  constructor(
    private translateService: TranslateService,
    private requestService: RequestService,
    private swapService: SwapService,
    private slideUpPanelService: SlideUpPanelService,
    private store: Store<State>,
    private userService: UserService,
    private route: ActivatedRoute,
  ) { 
    super();
    this.addSubscription(this.translateService.get([
      'loaders.requests',
      'requests.form.title',
      'requests.contextMenu.title',
      'requests.contextMenu.wantToWork',
      'requests.contextMenu.wantTimeOff',
      'requests.contextMenu.requestLeave',
    ]).subscribe(translations => {
      this.translations = translations;
      this.contextMenuTitle = translations['requests.contextMenu.title'];
      this.contextMenuItems = [];
      this.userService.isAllowed(['CreateWorkNoWorkRequest', 'CreateDutyRequest'], PermissionCheckMode.OR).then(allowed => {
        if (allowed) {
          this.contextMenuItems.push(
            {
              key: 'wantWork',
              label: translations['requests.contextMenu.wantToWork'],
            }
          )
          this.contextMenuItems.push(
            {
              key: 'wantOff',
              label: translations['requests.contextMenu.wantTimeOff'],
            }
          )
        }
      })
      this.userService.isAllowed(['CreateLeaveWishRequest']).then(allowed => {
        if (allowed) {
          this.contextMenuItems.push(
            {
              key: 'requestLeave',
              label: translations['requests.contextMenu.requestLeave'],
            }
          )
        }
      })
    }))
  }

  ngOnInit(): void {
    this.isMenuChild = this.route.parent.routeConfig.path === 'menu';
    this.getData();
  }

  getData(): void {
    this.store.dispatch(SwapsActions.getAll())
    this.store.dispatch(RequestsActions.getAll())
  }

  onContextMenuPressed(menuItemKey: string) {
    this.context = menuItemKey;
    this.slideUpPanelService.show(this.slideUpPanelId);
  }

  onRequestFormSubmit(data: any) {
    this.slideUpPanelService.hide(this.slideUpPanelId);
    this.getData();
  }

  onRequestFormCancel(event: any) {
    this.slideUpPanelService.hide(this.slideUpPanelId);
  }

}
