export enum MagazineNavigationRoute {
  SCHEDULE = 'schedule',
  REQUESTS = 'requests',
  MENU = 'menu',
  AVAILABILITY = 'availability',
  BALANCE = 'balance',
  CONTRACTS = 'contracts',
}

export interface MagazineNavigation {
  tabs: MagazineNavigationRoute[];
  menu: MagazineNavigationRoute[];
}