<form [formGroup]="requestForm">
  <div class="field">
    <app-switch-input [selected]="wishType" [options]="wishTypeSwitchOptions" [control]="requestForm.controls.wishType"></app-switch-input>
  </div>

  <div class="field radio">
    <span class="label">{{'requests.form.requestTypeLabel' | translate}}</span>
    <label for="specific" [appCheckPermissions]="['CreateWorkNoWorkRequest']">
      <input formControlName="requestType" type="radio" id="specific" value="specific" name="requestType" />
      <span>{{'requests.form.requestTypeSpecific' | translate}}</span>
    </label>
    <label for="duty" [appCheckPermissions]="['CreateDutyRequest']">
      <input formControlName="requestType" type="radio" id="duty" value="duty" name="requestType" />
      <span>{{'requests.form.requestTypeDuty' | translate}}</span>
    </label>
    <label *ngIf="requestForm.controls.wishType.value === 'off'" for="leave" [appCheckPermissions]="['CreateLeaveWishRequest']">
      <input formControlName="requestType" type="radio" id="leave" value="leave" name="requestType" />
      <span>{{'requests.form.requestTypeLeave' | translate}}</span>
    </label>
  </div>

  <div *ngIf="wishType === 'off' && requestType === 'leave' && info.activityTypesForLeave" class="field select">
    <span class="label">{{'requests.form.leaveTypeLabel' | translate}}</span>
    <select formControlName="leaveType">
      <option *ngFor="let type of info.activityTypesForLeave" value="{{type.id}}">{{type.name}}</option>
    </select>
  </div>

  <div class="field date" *ngIf="requestType === 'duty'">
    <span class="label">{{'inputs.date.label' | translate}}</span>
    <input type="date" formControlName="date" id="date" name="date" placeholder="{{'inputs.date.placeholder' | translate}}" />
  </div>

  <div *ngIf="requestType === 'duty' && shiftOptions" class="field selector">
    <span class="label">{{'requests.form.shiftLabel' | translate}}</span>
    <app-selector-input [options]="shiftOptions" [control]="requestForm.controls.shift"></app-selector-input>
  </div>

  <div class="field date-time" *ngIf="requestType === 'specific'">
    <span class="label">{{'requests.form.dateTimeFromLabel' | translate}}</span>
    <app-date-time-input
      [startDate]="today | djsDateFormat:'YYYY-MM-DD'"
      [linkedControl]="requestForm.controls.to"
      [isErroneous]="requestForm.errors && requestForm.errors.fromIsBeforeToday"
      [control]="requestForm.controls.from">
    </app-date-time-input>
  </div>

  <div class="field date-time" *ngIf="requestType === 'specific'">
    <span class="label">{{'requests.form.dateTimeToLabel' | translate}}</span>
    <app-date-time-input 
      [isErroneous]="requestForm.errors && (requestForm.errors.toIsBeforeFrom || requestForm.errors.toIsEqualToFrom)"
      [control]="requestForm.controls.to">
    </app-date-time-input>
  </div>

  <div class="field date-time" *ngIf="requestType === 'leave'">
    <span class="label">{{'requests.form.dateTimeFromLabel' | translate}}</span>
    <app-date-time-input
      [startDate]="minLeaveDate | djsDateFormat:'YYYY-MM-DD'"
      [minDate]="minLeaveDate | djsDateFormat:'YYYY-MM-DD'"
      [linkedControl]="requestForm.controls.toLeave"
      [isErroneous]="requestForm.errors && requestForm.errors.fromIsBeforeToday"
      [control]="requestForm.controls.fromLeave">
    </app-date-time-input>
  </div>

  <div class="field date-time" *ngIf="requestType === 'leave'">
    <span class="label">{{'requests.form.dateTimeToLabel' | translate}}</span>
    <app-date-time-input 
      [isErroneous]="requestForm.errors && (requestForm.errors.toIsBeforeFrom || requestForm.errors.toIsEqualToFrom)"
      [control]="requestForm.controls.toLeave">
    </app-date-time-input>
  </div>

  <div class="field textarea">
    <span class="label">{{'requests.form.remarkLabel' | translate}} ( {{ requestForm.controls.remark.value?.length || 0 }} / 200 )</span>
    <textarea maxlength="200" formControlName="remark" rows="3" placeholder="{{'requests.form.remarkPlaceholder' | translate}}"></textarea>
  </div>

  <!-- <pre>{{requestForm.valid}}</pre> -->
  <!-- <pre>{{requestForm.errors | json}}</pre> -->
  <div class="errors" *ngIf="!isFormValid()">
    <span *ngIf="requestForm.errors?.toIsBeforeFrom">{{'requests.form.errors.toIsBeforeFrom' | translate}}</span>
    <span *ngIf="requestForm.errors?.toIsEqualToFrom">{{'requests.form.errors.toIsEqualToFrom' | translate}}</span>
    <span *ngIf="requestForm.errors?.fromIsBeforeToday">{{'requests.form.errors.fromIsBeforeToday' | translate}}</span>
    <span *ngIf="requestForm.errors?.fromIsBeforeMinDate">{{'requests.form.errors.fromIsBeforeMinDate' | translate | formatUnicorn:minLeaveDays}}</span>
  </div>

  <div class="actions">
    <button class="button secondary" (click)="pressCancel()">{{'buttons.cancel' | translate}}</button>
    <button [disabled]="!isFormValid()" class="button confirmation" (click)="pressSend()">{{'buttons.send' | translate}}</button>
  </div>
</form>