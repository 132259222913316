import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { Loader } from './loader.interface';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  loaderSubject: BehaviorSubject<Loader> = new BehaviorSubject<Loader>({show: false});

  constructor() { }

  show(text: string = '', delay?: number) {
    this.loaderSubject.next({show: true, text, delay});
  }

  showFakeLoader() {
    this.loaderSubject.next({show: true, text: '', delay: 0});
    setTimeout(() => this.loaderSubject.next({show: false}), 2000);
  }

  hide() {
    this.loaderSubject.next({show: false});
  }

  getUpdates(): Observable<Loader> {
    return this.loaderSubject.asObservable();
  }

}
