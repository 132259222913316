import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Theme } from './theme.interface';
import { ThemeDirective } from './theme.directive';
import { ThemeService } from './theme.service';

export interface ThemeOptions {
  themes: Theme[];
  active?: string;
}

@NgModule({
  imports: [CommonModule],
  providers: [ThemeService],
  declarations: [ThemeDirective],
  exports: [ThemeDirective]
})
export class ThemeModule {
  static forRoot(options: ThemeOptions): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        {
          provide: 'THEMES',
          useValue: options.themes
        },
        {
          provide: 'ACTIVE_THEME',
          useValue: options.active
        }
      ]
    };
  }
}