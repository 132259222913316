import {
  ActionReducer,
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
  MetaReducer,
} from '@ngrx/store';
import { Request } from '../interfaces/request';
import { requestsReducer } from './requests.reducer';
import { swapsReducer, SwapState } from './swaps.reducer';
import { ScheduleState, scheduleReducer } from './schedule.reducer';
import { AuthState, authReducer } from './auth.reducer';
import { localStorageSync } from 'ngrx-store-localstorage';
import { availabilityReducer, AvailabilityState } from '../availability/reducers/availability.reducer';
import { MagazineNavigation } from '../interfaces/navigation';
import { navigationReducer } from './navigation.reducer';
import { iMagazineClientConfig } from '@shared/magazine-config';
import { configReducer } from './config.reducer';

export interface State {
  navigation: MagazineNavigation,
  config: iMagazineClientConfig,
  requests: Request[],
  swaps: SwapState,
  schedule: ScheduleState,
  auth: AuthState,
  availability: AvailabilityState,
}

export const reducers: ActionReducerMap<State> = {
  navigation: navigationReducer,
  config: configReducer,
  requests: requestsReducer,
  swaps: swapsReducer,
  schedule: scheduleReducer,
  auth: authReducer,
  availability: availabilityReducer,
};

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth', 'config'],
    rehydrate: true,
    storageKeySerializer: (key: string) => `ess.store.${key}`,
  })(reducer);
}

export const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

export const selectAuth = createFeatureSelector<State, AuthState>('auth');
export const selectAuth0 = createSelector(
  selectAuth,
  (state: AuthState) => state.tokens
)
export const selectAuth0Value = createSelector(
  selectAuth,
  (state: AuthState, props: {key: string}) => state.tokens ? state.tokens[props.key] : undefined
)

export const selectSchedule = createFeatureSelector<State, ScheduleState>('schedule');
export const selectViewDate = createSelector(
  selectSchedule,
  (state: ScheduleState) => state.viewDate
)