import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { getAvailability, getAvailabilityError, getAvailabilitySuccess, setRange } from './availability.actions';
import * as dayjs from 'dayjs';
import { State } from '../../reducers';
import { AvailabilityService } from '../availability.service';

@Injectable()
export class AvailabilityEffects {
  setRange$ = createEffect(() => this.actions$.pipe(
    ofType(setRange),
    map((action) => getAvailability({
      startDate: dayjs(action.startDate),
      endDate: dayjs(action.endDate),
    }))
  ))

  getAvailability$ = createEffect(() => this.actions$.pipe(
    ofType(getAvailability),
    mergeMap((action) => this.availabilityService.getAvailability(action.startDate, action.endDate)
      .pipe(
        map(days => getAvailabilitySuccess({days})),
        catchError(() => of({ type: getAvailabilityError.type }))
      )
    )
  ))

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private availabilityService: AvailabilityService,
  ) {}
}