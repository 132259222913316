import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { State } from '../reducers';
import { confirmShift, declineShift } from '../reducers/schedule.actions';
import { ScheduleService } from '../schedule.service';

@Component({
  selector: 'app-approvable-shifts',
  templateUrl: './approvable-shifts.component.html',
  styleUrls: ['./approvable-shifts.component.scss']
})
export class ApprovableShiftsComponent implements OnInit, OnDestroy {

  @HostBinding('class') get cssClasses() {
    return `card ${!this.isHidden ? 'show' : ''}`;
  }

  @HostBinding('style.display')
  cssDisplay = 'block';

  isHidden = true;
  isEnabled = false;
  approvableShifts$ = this.scheduleService.approvableShifts$;
  enabledSub: Subscription;
  approvableShiftsSub: Subscription;

  constructor(
    private scheduleService: ScheduleService,
    private store: Store<State>,
  ) { }

  ngOnInit(): void {
    this.enabledSub = this.scheduleService.confirmShiftsEnabled$.subscribe(enabled => {
      this.isEnabled = enabled;
      this.cssDisplay = enabled ? 'block' : 'none';
    })
    this.approvableShiftsSub = this.scheduleService.approvableShifts$.subscribe(shifts => {
      this.isHidden = shifts && shifts.length < 1 && this.isEnabled;
    })
  }

  confirmShift(shiftId: number) {
    console.log(`confirmShift(${shiftId})`);
    this.store.dispatch(confirmShift({shiftId}))
  }

  declineShift(shiftId: number) {
    console.log(`declineShift(${shiftId})`);
    this.store.dispatch(declineShift({shiftId}))
  }

  ngOnDestroy(): void {
    if (this.enabledSub) this.enabledSub.unsubscribe();
    if (this.approvableShiftsSub) this.approvableShiftsSub.unsubscribe();
  }

}
