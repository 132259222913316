import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Dayjs } from 'dayjs';
import { AvailabilityInterval } from './interfaces/availability-interval';
import { first, map, switchMap } from 'rxjs/operators';
import * as dayjs from 'dayjs';
import { AvailabilityDay } from './interfaces/availability-day';
import { AvailabilityStatus } from './interfaces/availability-status';
import { AvailabilityTimeslot } from './interfaces/availability-timeslot';
import { AvailabilityWish } from './interfaces/availability-wish';
import { ConfigService } from '../config.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';

@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  days$ = this.store.select((state: State) => state.availability.days);
  calendarWeekendDays = [0, 6];

  defaultStatus: AvailabilityStatus = AvailabilityStatus.AVAILABLE;

  constructor(
    private http: HttpClient,
    private store: Store<State>,
    private configService: ConfigService,
  ) { }

  formatTimeslot(interval: AvailabilityInterval, date: dayjs.Dayjs, overrideStatus?: AvailabilityStatus): AvailabilityTimeslot {
    const wishes = [];
    let status = this.defaultStatus;
    let weight = 0;

    (interval.wishes || []).forEach((wish: AvailabilityWish) => {
      if (dayjs(wish.startDate).isSame(date, 'day')) {
        wishes.push(wish);
        if (wish.weight > weight) {
          weight = wish.weight
        }
      }
    });

    if (weight > 0) {
      status = AvailabilityStatus.TENTATIVE;
    }

    if (weight > 50) {
      status = this.defaultStatus === AvailabilityStatus.AVAILABLE ? AvailabilityStatus.UNAVAILABLE : AvailabilityStatus.AVAILABLE;
    }
    
    return {
      ...interval,
      wishes,
      date,
      allDay: interval.startTime === '00:00' && interval.endTime === '00:00',
      status: overrideStatus ? overrideStatus : status,
    }
  }

  formatDay(date: dayjs.Dayjs, intervals: AvailabilityInterval[], allDayInterval: AvailabilityInterval): AvailabilityDay {
    let globalStatus = this.defaultStatus;
    let overrideStatus: AvailabilityStatus;

    const allDayWish = !allDayInterval ? undefined : allDayInterval.wishes.filter((wish: AvailabilityWish) => dayjs(wish.startDate).isSame(date, 'day'))[0];
    const hasAllDayInterval = allDayInterval ? true : false;
    const hasAllDayWish = allDayWish ? true : false;
    const isWeekend = this.calendarWeekendDays.includes(date.day());
    const isPast = date.isBefore(dayjs());

    if (hasAllDayWish && !allDayWish.wantToWork && this.defaultStatus === AvailabilityStatus.AVAILABLE) {
      overrideStatus = allDayWish.weight > 50 ? AvailabilityStatus.UNAVAILABLE : AvailabilityStatus.TENTATIVE;
    }
    if (hasAllDayWish && allDayWish.wantToWork && this.defaultStatus === AvailabilityStatus.UNAVAILABLE) {
      overrideStatus = AvailabilityStatus.AVAILABLE;
    }

    const timeslots = intervals
      .filter((interval) => interval.templates.length > 0)
      .map((interval) => {
        return this.formatTimeslot(interval, date, overrideStatus)
      });

    const day: AvailabilityDay = {
      timeslots,
      isWeekend,
      isPast,
      status: globalStatus,
      defaultStatus: this.defaultStatus,
      date: date.toDate(),
    }

    if (hasAllDayWish) {
      day.allDayWish = allDayWish;
    }

    if (hasAllDayInterval) {
      day.allDayTimeslot = allDayInterval.templates.length > 0 ? this.formatTimeslot(allDayInterval, date) : undefined;
    }

    return day;
  }

  processAvailabilityData(intervals: AvailabilityInterval[], startDate: Dayjs, endDate: Dayjs): AvailabilityDay[] {
    const amountOfDays = endDate.diff(startDate, 'day');
    const days = []; 
    const allDayInterval: AvailabilityInterval = intervals.filter(interval => interval.startTime === '00:00' && interval.endTime === '00:00')[0];

    let currentDate = startDate.clone();
    
    for(let i = 0; i < amountOfDays; i++) {
      days.push(this.formatDay(
        currentDate,
        intervals,
        allDayInterval,
      ));
      currentDate = currentDate.clone().add(1, 'day');
    }

    return days;
  }

  getAvailability(startDate: Dayjs, endDate: Dayjs) {
    const startDateString = startDate.format('YYYY-MM-DD');
    const endDateString = endDate.format('YYYY-MM-DD');
    return this.store.select('config', 'availability', 'default').pipe(
      first(),
      switchMap((defaultAvailability) => {
        return this.http.get<AvailabilityInterval[]>(`/api/availabilities?startDate=${startDateString}&endDate=${endDateString}`)
        .pipe(map((intervals: AvailabilityInterval[]) => {
          // const configDefaultStatus = this.configService.get('defaultAvailability');
          this.defaultStatus = defaultAvailability === 'available' ? 
            AvailabilityStatus.AVAILABLE : 
            defaultAvailability === 'unavailable' ? 
              AvailabilityStatus.UNAVAILABLE : 
              AvailabilityStatus.TENTATIVE;
          
          return this.processAvailabilityData(
            intervals,
            startDate,
            endDate
          );
        }));
      })
    )
    
  }
}
