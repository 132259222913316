import { Component, Input, HostBinding } from '@angular/core';
import { Shift } from '../interfaces/shift';
import { ShiftPickingMode, ShiftPickingService } from '../shift-picking.service';

@Component({
  selector: 'app-shift-picking-widget',
  templateUrl: './shift-picking-widget.component.html',
  styleUrls: ['./shift-picking-widget.component.scss']
})
export class ShiftPickingWidgetComponent {

  @Input() count: number = 0;
  @Input() shifts: Shift[] = [];
  @Input() date: string;

  isOpened = false;

  @HostBinding('class') get cssClasses() {
    return `${(this.isOpened ? 'opened' : 'closed')}`;
  }

  constructor(
    private shiftPickingService: ShiftPickingService,
  ) { }

  toggle = () => this.isOpened = !this.isOpened;
  
  startShiftPicking(mode: ShiftPickingMode, shift: Shift) {
    this.shiftPickingService.startPicking(shift, mode);
  }

}
