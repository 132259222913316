import { Injectable, Injector } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject } from 'rxjs';
import { environment } from '../../environments/environment';

export enum CustomErrorDismissType {
  OK = 'OK',
  CTA = 'CTA'
}

export interface CustomError {
  code: string;
  text?: string;
  type: CustomErrorDismissType;
  custom?: boolean;
  showText?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ErrorService {

  errors: CustomError[] = [];
  currentError$: BehaviorSubject<CustomError> = new BehaviorSubject<CustomError>(undefined);
  showingError = false;

  constructor(
    private injector: Injector,
    private cookieService: CookieService,
  ) { }

  trigger(error: CustomError): void {
    const sameErrors = this.errors.filter(e => e.code === error.code);
    
    if (sameErrors.length === 0) {
      this.errors.push(error);
      
      if (!this.showingError) {
        this.showNextError();
      }
    }
  }

  dismiss(): void {
    this.errors.shift();
    this.showNextError();
  }

  showNextError(): void {
    const nextError = this.errors[0];
    if (nextError) {
      this.currentError$.next(nextError);
      this.showingError = true;
    } else {
      this.showingError = false;
    }
  }

}
