import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/auth.service';
import { UserService } from './auth/user.service';
import { ConfigService } from './config.service';
import { State } from './reducers';
import { getContractsSuccess } from './reducers/auth.actions';
import { setConfig } from './reducers/config.actions';
import { setNavigation } from './reducers/navigation.actions';
import { CamelToKebabCase, getQueryStringParameter } from './utils';
import { environment } from '../environments/environment';
import { iMagazineClientConfig } from '@shared/magazine-config';

@Injectable({
  providedIn: 'root'
})
export class AppService {

  menu$ = this.store.select('config', 'navigation', 'menu');
  tabs$ = this.store.select('config', 'navigation', 'tabs');

  constructor(
    private configService: ConfigService,
    private userService: UserService,
    private store: Store<State>,
    private router: Router,
    private authService: AuthService,
    private translateService: TranslateService,
  ) { }

  async blockAndRetry(): Promise<void> {
    return new Promise((resolve) => {
      setTimeout(() => {
        window.location.reload();
        return resolve();
      }, 2000);
    })
  }

  async init(): Promise<void> {
    console.warn('Initializing App...');

    const language = this.userService.getLanguage();
    const token = getQueryStringParameter('token');
    const magazineId = getQueryStringParameter('magazine');
    const osName = getQueryStringParameter('os_name');
    const coreVersion = getQueryStringParameter('cv');

    const isNative = [
      'iphone',
      'ipad',
      'android-phone',
      'android-tablet',
      'android',
      'simulator',
    ].includes(`${osName}`);

    const isIos = [
      'iphone',
      'ipad',
      'simulator',
    ].includes(`${osName}`);

    const isAndroid = [
      'android-phone',
      'android-tablet',
      'android',
    ].includes(`${osName}`);

    // if (coreVersion && osName && isNative) {
    //   console.warn('Core version:', coreVersion);
    //   console.warn('OS name:', osName);
    //   console.warn('Is native:', isNative);
    //   if (isIos && semver.lt(coreVersion, environment.minimumCoreVersions.ios)) {
    //     console.error(`Core version is lower than minimum iOS version: ${coreVersion} < ${environment.minimumCoreVersions.ios}`)
    //     this.router.navigate([`error`], {queryParams: {code: 'coreOutdated'}})
    //     return Promise.resolve();
    //   } else if (isAndroid && semver.lt(coreVersion, environment.minimumCoreVersions.android)) {
    //     console.error(`Core version is lower than minimum Android version: ${coreVersion} < ${environment.minimumCoreVersions.android}`)
    //     this.router.navigate([`error`], {queryParams: {code: 'coreOutdated'}})
    //     return Promise.resolve();
    //   }
    // }

    if (!token || !magazineId) {
      if (this.authService.isLoggedIn()) {
        const config = await this.getLatestConfig();
        if (config) {
          // this.navigateToDefaultTabRoute(config);
        }
      } else {
        console.warn('User not logged');
      }
      console.warn('App init without token and/or magazineId', {token, magazineId});
      return Promise.resolve();
    }

    this.authService.setCookie('ess.magazine', magazineId);

    if (language !== 'en') {
      this.translateService.use(language);
    }

    console.warn('Sending auth request...');
    const authResponse: any = await this.authService.platformLogin(
      parseInt(magazineId),
      token,
      language
    ).catch(error => {
      console.error('Error logging in', error)
      if (error.status === 0) {
        return this.blockAndRetry();
      } else if (error.status === 403) {
        console.warn('Error logging in, bailing out!')
        this.authService.bailOut();
        return Promise.resolve();
      } else {
        this.router.navigate([`error`], {queryParams: {code: 'login-failed'}})
        return Promise.resolve();
      }
    })
    console.warn('Received auth response...', authResponse);

    console.warn('Checking auth response...');
    if (!authResponse || (!authResponse.accessToken || !authResponse.refreshToken)) {
      console.error('Login failed!');
      this.authService.bailOut();
      return Promise.resolve();
    }

    console.warn('Logging in...');
    this.authService.login(authResponse.accessToken, authResponse.refreshToken);

    const {config, contracts} = authResponse;

    if (!config || !contracts) {
      console.warn('No contracts or config, bailing out!')
      this.authService.bailOut();
      return Promise.resolve();
    }

    console.warn('Updating state...');
    this.store.dispatch(getContractsSuccess({contracts}));
    this.store.dispatch(setConfig({config}));

    setTimeout(() => {
      console.warn('App init finished, redirecting to schedule...');
      this.navigateToDefaultTabRoute(config);
      return Promise.resolve();
    }, 0);
  }

  navigateToDefaultTabRoute(config: iMagazineClientConfig) {
    const scheduleRoute = 'schedule';
    const { tabs } = config?.navigation;
    const firstTab = tabs && tabs[0] ? CamelToKebabCase(tabs[0]) : null;
    const defaultRoute = firstTab || scheduleRoute;

    this.router.navigate([`/${defaultRoute}`])
      .catch(() => {
        // case kebabed case tab name route does not exist, it will default to schedule
        this.router.navigate([`/${scheduleRoute}`]);
      })
  }

  async getLatestConfig() {
    const config = await this.configService.getLatestConfig().toPromise();
    if (!config) return false;
    this.store.dispatch(setConfig({config}));
    return config;
  }

  async updateNavigation() {
    const navigation = await this.configService.getNavigationConfig().toPromise();
    if (!navigation) return false;
    this.store.dispatch(setNavigation({navigation}));
  }

}
