import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';
import { switchMap } from 'rxjs/operators';

@Injectable()
export class ContractInterceptor implements HttpInterceptor {

  constructor(
    private injector: Injector,
  ) {}

  get authService() {
    return this.injector.get(AuthService);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    if (!request.url.match(/^\/api/)) return next.handle(request);
    if (request.url.match(/^\/api\/auth/)) return next.handle(request);
    
    return this.authService.getContract().pipe(switchMap((contract) => {
      request = request.clone({
        params: request.params.set('resourceId', `${contract ? contract.id : ''}`)
      });
      return next.handle(request);
    }))
  }
}
