import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { Schedule } from '../interfaces/schedule';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { getContracts } from '../reducers/auth.actions';

@Injectable()
export class ContractResolver implements Resolve<Schedule> {

  constructor(private store: Store<State>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Schedule> {
    this.store.dispatch(getContracts());
    return of(undefined);
  }

}