import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { RequestService } from '../request.service';
import { mergeMap, catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import * as RequestActions from './requests.actions'

@Injectable()
export class RequestsEffects {
  getAllRequests$ = createEffect(() => this.actions$.pipe(
    ofType(RequestActions.getAll),
    mergeMap(() => this.requestService.getAll()
      .pipe(
        map(requests => ({type: RequestActions.getAllSuccess.type, requests})),
        catchError(() => of({ type: RequestActions.getAllError.type }))
      )
    )
  ))

  constructor(
    private actions$: Actions,
    private requestService: RequestService,
  ) {}
}