import { Injectable, Inject, EventEmitter } from '@angular/core';
import { Theme, ThemeProperties } from './theme.interface';

@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  // Emit events when theme changes
  themeChange = new EventEmitter<Theme>();

  constructor(
    @Inject('THEMES') public themes: Theme[],
    @Inject('ACTIVE_THEME') public theme: string
  ) {}

  /**
   * Returns a theme based on its name if it was provided to the module
   * @param name 
   */
  getTheme(name: string) {
    const theme = this.themes.find(t => t.name === name);
    if (!theme) {
      console.log(`Theme not found: '${name}'`);
    }
    return theme;
  }

  /**
   * Returns the name if the active theme if there is an active one
   */
  getActiveTheme() {
    if (this.theme) {
      return this.getTheme(this.theme);
    }
  }

  /**
   * Returns a specific theme property based on its name
   * @param propName 
   */
  getProperty(name: string) {
    return this.getActiveTheme().properties[name];
  }

  /**
   * Sets a new theme to be the active theme
   * @param name 
   */
  setTheme(name: string) {
    this.theme = name;
    this.themeChange.emit(this.getActiveTheme());
  }

  /**
   * Registers a new theme to our list of themes
   * @param theme 
   */
  registerTheme(theme: Theme) {
    this.themes.push(theme);
  }

  /**
   * Update the theme
   * @param name 
   * @param properties 
   */
  updateTheme(name: string, properties: ThemeProperties) {
    const theme = this.getTheme(name);
    theme.properties = {
      ...theme.properties,
      ...properties
    };

    if (name === this.theme) {
      this.themeChange.emit(theme);
    }
  }

}