import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';
import { Shift } from './interfaces/shift';
import { selectViewDate, State } from './reducers';
import { RequestService } from './request.service';
import { SlideUpPanelService } from './slide-up-panel/slide-up-panel.service';
import { getPickingSchedule } from './reducers/schedule.actions';
import * as RequestsActions from './reducers/requests.actions';
import * as ScheduleActions from './reducers/schedule.actions';

export enum ShiftPickingMode {
  PICK = 'pick',
  REQUEST = 'request'
}

export interface ShiftPickingSet {
  shift: Shift;
  mode: ShiftPickingMode;
}

@Injectable({
  providedIn: 'root'
})
export class ShiftPickingService {

  dataSet$: BehaviorSubject<ShiftPickingSet> = new BehaviorSubject<ShiftPickingSet>(undefined);
  slideUpPanelId = 'ShiftPickingDialog';

  constructor(
    private slideUpPanelService: SlideUpPanelService,
    private requestService: RequestService,
    private store: Store<State>,
  ) { }

  startPicking(shift: Shift, mode: ShiftPickingMode) {
    this.dataSet$.next({shift, mode});
    this.slideUpPanelService.show(this.slideUpPanelId);
  }

  finishPicking() {
    this.store.select(selectViewDate).subscribe(viewDate => {
      this.store.dispatch(getPickingSchedule());
      this.store.dispatch(RequestsActions.getAll());
      this.store.dispatch(ScheduleActions.getToday({date: viewDate}));
      this.slideUpPanelService.hide(this.slideUpPanelId);
    });
    selectViewDate.release();
  }

  cancelRequest(id: number, type: string) {
    return this.requestService.cancel(id, type);
  }

}
