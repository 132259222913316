import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { BaseComponent } from '../base/base.component';
import { Shift } from '../interfaces/shift';
import { State } from '../reducers';
import { createRealization, updateRealization } from '../reducers/schedule.actions';
import { SlideUpPanelService } from '../slide-up-panel/slide-up-panel.service';

@Component({
  selector: 'app-realization-form',
  templateUrl: './realization-form.component.html',
  styleUrls: ['./realization-form.component.scss']
})
export class RealizationFormComponent extends BaseComponent implements OnInit, OnChanges {

  @Input() slideUpPanelId: string;
  @Input() shifts: Shift[] = [];

  form = new UntypedFormGroup({
    shift: new UntypedFormControl(),
    range: new UntypedFormControl({start: '00:00', end: '23:59'}),
  });

  constructor(
    private slideUpPanelService: SlideUpPanelService,
    private store: Store<State>,
  ) {
    super();
  }

  getShiftTimeRange(shift: Shift) {
    return {
      start: dayjs(shift.pendingDuty ? shift.pendingDuty.startTime : shift.startTime).format('HH:mm'),
      end: dayjs(shift.pendingDuty ? shift.pendingDuty.endTime : shift.endTime).format('HH:mm'),
    }
  }

  ngOnInit(): void {
    this.addSubscription(this.form.get('shift').valueChanges.subscribe(shift => {
      if (shift) this.form.get('range').setValue(this.getShiftTimeRange(shift));
    }));
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.shifts) this.reset();
  }

  save(): void {
    const shift = this.form.get('shift').value;
    const newStartTime = this.form.get('range').value.start.split(':');
    const newEndTime = this.form.get('range').value.end.split(':');
    const shiftId = shift.id;
    const startTime = dayjs(shift.startTime).hour(newStartTime[0]).minute(newStartTime[1]).format();
    const endTime = dayjs(shift.endTime).hour(newEndTime[0]).minute(newEndTime[1]).format();
    
    if (shift.pendingDuty) {
      this.store.dispatch(updateRealization({
        original: {
          shiftId: shift.id,
          startTime: shift.startTime,
          endTime: shift.endTime
        },
        realization: {shiftId, startTime, endTime}
      }))
    } else {
      this.store.dispatch(createRealization({shiftId, startTime, endTime}));
    }

    this.slideUpPanelService.hide(this.slideUpPanelId);
  }

  cancel(): void {
    this.reset();
    this.slideUpPanelService.hide(this.slideUpPanelId);
  }

  reset(): void {
    const shift = this.shifts[0];
    if (shift) {
      this.form.get('shift').setValue(shift);
      this.form.get('range').setValue(this.getShiftTimeRange(shift));
    }
  }

}
