import { Component, OnInit, Input, Output, EventEmitter, HostBinding, OnDestroy } from '@angular/core';
import { SlideUpPanelService } from './slide-up-panel.service';
import { Subscription } from 'rxjs';

export enum SlideUpPanelType {
  Default = 'default',
  Confirm = 'confirm',
}

@Component({
  selector: 'app-slide-up-panel',
  templateUrl: './slide-up-panel.component.html',
  styleUrls: ['./slide-up-panel.component.scss']
})
export class SlideUpPanelComponent implements OnInit, OnDestroy {

  @Input() type: SlideUpPanelType = SlideUpPanelType.Default;
  @Input() id: string;
  @Input() icon: string;
  @Input() title: string;
  @Input() titleBorder: boolean = true;
  @Input() visible: boolean = false;

  @Output() onVisibilityChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class') get cssClasses() {
    return `${this.type} ${(this.visible ? 'visible' : 'invisible')}`;
  }

  events: EventEmitter<string> = new EventEmitter<string>();
  eventsSub: Subscription;

  constructor(private service: SlideUpPanelService) { }

  onEvent(event: string) {
    if (event === 'show') {
      this.show();
    } else if (event === 'hide') {
      this.hide();
    }
  }

  ngOnInit(): void {
    if (!this.id) alert('Slide up panel is missing `id` input');
    this.service.add({id: this.id, events: this.events});
    this.eventsSub = this.events.subscribe(this.onEvent.bind(this));
  }

  show() {
    if (!this.visible) {
      this.visible = true;
      this.onVisibilityChange.emit(true);
    }
  }

  hide() {
    if (this.visible) {
      this.visible = false;
      this.onVisibilityChange.emit(false);
    }
  }

  ngOnDestroy(): void {
    this.service.delete(this.id);
    if (this.eventsSub) this.eventsSub.unsubscribe();
  }

}
