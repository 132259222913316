import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AddRequestInfo } from './interfaces/add-request-info';
import { Request } from './interfaces/request';
import { Store } from '@ngrx/store';
import { State } from './reducers';
import { map } from 'rxjs/operators';
import { Dayjs } from 'dayjs';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  requests$ = this.store.select((state: State) => state.requests);
  requestCount$ = this.requests$.pipe(map((b) => b.length));
 
  constructor(private http: HttpClient, private store: Store<State>) { }

  getInfo(date?: string): Observable<AddRequestInfo> {
    return this.http.get<AddRequestInfo>(`/api/requests/info${date ? `?date=${encodeURIComponent(date)}` : ``}`);
  }

  getAll(): Observable<Request[]> {
    return this.http.get<Request[]>(`/api/requests`);
  }

  createWorkRequest(request: any): Observable<any> {
    return this.http.post(`/api/requests/work`, request);
  }

  createDutyRequest(request: any): Observable<any> {
    return this.http.post(`/api/requests/duty`, request);
  }

  createLeaveRequest(request: any): Observable<any> {
    return this.http.post(`/api/requests/leave`, request);
  }

  createTemplateRequest(request: any): Observable<any> {
    return this.http.post(`/api/requests/template`, request);
  }

  cancel(id: number, type: string): Observable<any> {
    const dtoType = (type === 'DutyWish' ? 'duty' : (type === 'LeaveWish' ? 'leave' : 'worknowork'))
    return this.http.post(`/api/requests/cancel`, {id, type: dtoType});
  }

  isSameDay(beginDate: Dayjs, endDate: Dayjs): boolean { 
    return beginDate.isSame(endDate, 'day') || (beginDate.isSame(endDate.clone().add(1, 'day'), 'day') && beginDate.isSame(endDate, 'hour'));
  }

  isAllDay(beginDate: Dayjs, endDate: Dayjs): boolean {
    const beginningOfDay = beginDate.isSame(beginDate.clone().startOf('day'), 'hour');
    const endOfDay = endDate.isSame(endDate.clone().startOf('day'), 'hour');
    return beginningOfDay && endOfDay;
  }

}
