import { createAction, props } from '@ngrx/store';
import { Swap } from '../interfaces/swap';
import { SwapCandidate } from '../interfaces/swap-candidate';
import { Shift } from '../interfaces/shift';

// Generic
export const setCurrentShift = createAction('[Swaps] Set Current Shift', props<{shift: Shift}>());

// Swaps
export const getAll = createAction('[Swaps] Get All');
export const getAllSuccess = createAction('[Swaps] Get All Success', props<{swaps: Swap[]}>());
export const getAllError = createAction('[Swaps] Get All Error');

// Swap Requests
export const create = createAction('[SwapRequest] Create', props<{
  dto: {
    shiftId: number,
    wantedResourceId: number,
    wantedShiftId?: number,
  },
  meta: {
    shiftWanted: boolean,
    sender: string,
    requestShiftName: string,
    wantedShiftName: string,
    wantedResourceId: number,
  }
}>());
export const createSuccess = createAction('[SwapRequest] Create Success', props<{
  meta: {
    shiftWanted: boolean,
    sender: string,
    requestShiftName: string,
    wantedShiftName: string,
    wantedResourceId: number,
  }
}>());
export const createError = createAction('[SwapRequest] Create Error');

export const cancel = createAction('[SwapRequest] Cancel', props<{request: Swap}>());
export const cancelSuccess = createAction('[SwapRequest] Cancel Success', props<{request: Swap}>());
export const cancelError = createAction('[SwapRequest] Cancel Error', props<{request: Swap}>());

export const decline = createAction('[SwapRequest] Decline', props<{request: Swap}>());
export const declineSuccess = createAction('[SwapRequest] Decline Success', props<{request: Swap}>());
export const declineError = createAction('[SwapRequest] Decline Error', props<{request: Swap}>());

export const accept = createAction('[SwapRequest] Accept', props<{request: Swap}>());
export const acceptSuccess = createAction('[SwapRequest] Accept Success', props<{request: Swap}>());
export const acceptError = createAction('[SwapRequest] Accept Error', props<{request: Swap}>());

export const remove = createAction('[SwapRequest] Remove', props<{id: number}>());

// Swap Candidates
export const getCandidates = createAction('[SwapsCandidates] Get All');
export const getCandidatesSuccess = createAction('[SwapsCandidates] Get All Success', props<{candidates: SwapCandidate[]}>());
export const getCandidatesError = createAction('[SwapsCandidates] Get All Error');