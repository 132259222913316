import { createAction, props } from '@ngrx/store';
import { Contract } from '../interfaces/contract';
import { User } from '../interfaces/user';
import { UserPermissions } from '../interfaces/user-permission';
import { UserSettings } from '../interfaces/user-settings';

export const setUser = createAction('[Auth] Set User', props<{user: User}>());

export const setPermissions = createAction('[Auth] Set Permissions', props<{permissions: UserPermissions}>());

export const setSettings = createAction('[Auth] Set Settings', props<{settings: UserSettings}>());

export const setAuth0 = createAction('[Auth] Set Auth0', props<{accessToken: string, refreshToken: string}>());
export const setTokens = createAction('[Auth] Set Tokens', props<{accessToken: string, refreshToken: string}>());

export const getContracts = createAction('[Auth] Get Contracts');
export const getContractsSuccess = createAction('[Auth] Get Contracts Success', props<{ contracts: Contract[] }>());
export const getContractsError = createAction('[Auth] Get Contracts Error');

export const setActiveContract = createAction('[Auth] Set Active Contract', props<{contract: Contract}>());