<ng-container *ngIf="request.shiftRequest">
  <header *ngIf="showHeader" (click)="open()">
    <h3>{{request.shiftRequest.startTime | djsDateFormat:('dateFormatting.day' | translate)}}</h3>
    <span>
      <span class="status"><span class="indicator {{request.status | lowercase}}"></span>{{'swaps.request.status.'+(request.status | lowercase) | translate}}</span>
      <div class="icon arrow" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
    </span>
  </header>

  <main (click)="open()">
    <div class="request-type-indicator" *ngIf="!showHeader">
      <div class="icon" [inlineSVG]="'/assets/icons/icon-swap.svg'"></div>
    </div>
    <div class="text" [innerHTML]="text"></div>
    <div class="tools" *ngIf="!loading && showTools">
      <a *ngIf="isOwner" href="javascript:void(0)" class="button primary" (click)="cancelRequest();$event.stopPropagation();">
        {{'buttons.cancelRequest' | translate}}
      </a>
      <a *ngIf="!isOwner && request.status === 'OPEN'" href="javascript:void(0)" class="button secondary" (click)="declineRequest();$event.stopPropagation();">
        {{'buttons.decline' | translate}}
      </a>
      <a *ngIf="!isOwner && request.status === 'OPEN'" href="javascript:void(0)" class="button primary" (click)="acceptRequest();$event.stopPropagation();">
        {{'buttons.accept' | translate}}
      </a>
    </div>
  </main>

  <app-slide-up-panel 
    [id]="slideUpPanelId"
    [icon]="'/assets/icons/icon-swap.svg'"
    [title]="request.shiftRequest.startTime | djsDateFormat:('dateFormatting.day' | translate)"
  >
    <div class="text" [innerHTML]="text"></div>
    <div class="shifts" *ngIf="isOwner && request.shiftRequest && request.shiftWanted">
      <app-shift-header [shift]="request.shiftRequest"></app-shift-header>
      <div class="icon primary" [inlineSVG]="'/assets/icons/icon-swap.svg'"></div>
      <app-shift-header [shift]="request.shiftWanted"></app-shift-header>
    </div>
    <div class="shifts" *ngIf="isOwner && !request.shiftRequest && request.shiftWanted">
      <app-shift-header [shift]="request.shiftWanted"></app-shift-header>
    </div>
    <div class="shifts" *ngIf="isOwner && request.shiftRequest && !request.shiftWanted">
      <app-shift-header [shift]="request.shiftRequest"></app-shift-header>
    </div>
    <div class="shifts" *ngIf="!isOwner && request.shiftRequest && request.shiftWanted">
      <app-shift-header [shift]="request.shiftRequest"></app-shift-header>
      <div class="icon primary" [inlineSVG]="'/assets/icons/icon-swap.svg'"></div>
      <app-shift-header [shift]="request.shiftWanted"></app-shift-header>
    </div>
    <div class="shifts" *ngIf="!isOwner && !request.shiftRequest && request.shiftWanted">
      <app-shift-header [shift]="request.shiftWanted"></app-shift-header>
    </div>
    <div class="shifts" *ngIf="!isOwner && request.shiftRequest && !request.shiftWanted">
      <app-shift-header [shift]="request.shiftRequest"></app-shift-header>
    </div>
    <div class="actions" *ngIf="isOwner || request.status === 'OPEN'">
      <a *ngIf="isOwner" href="javascript:void(0)" class="button primary" (click)="cancelRequest();$event.stopPropagation();">
        {{'buttons.cancelRequest' | translate}}
      </a>
      <a *ngIf="!isOwner && request.status === 'OPEN'" href="javascript:void(0)" class="button secondary" (click)="declineRequest();$event.stopPropagation();">
        {{'buttons.decline' | translate}}
      </a>
      <a *ngIf="!isOwner && request.status === 'OPEN'" href="javascript:void(0)" class="button primary" (click)="acceptRequest();$event.stopPropagation();">
        {{'buttons.accept' | translate}}
      </a>
    </div>
  </app-slide-up-panel>
</ng-container>