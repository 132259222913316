<app-calendar
  [schedule]="days$ | async"
  [currentDate]="currentDate.format()"
  (datePressed)="onDatePressed($event)"
  (monthChanged)="onMonthChanged($event)"
></app-calendar>

<app-approvable-shifts></app-approvable-shifts>

<a *ngIf="(extraShiftCount$ | async) > 0"
  class="shifts-available"
  href="javascript:void(0)"
  [routerLink]="'/schedule/picking'"
  [appCheckPermissions]="['ViewShiftPickingList']">
  <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-shift-available.svg'"></div>
  <span>
    <h3>{{'shiftPicking.notifier.title' | translate}}</h3>
    <p>{{'shiftPicking.notifier.text' | translate | formatUnicorn:(extraShiftCount$ | async)}}</p>
  </span>
  <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
</a>

<div class="timeline">
  <ng-template #loading>
    <div class="item">
      <div class="card">
        <main><app-loading></app-loading></main>
      </div>
    </div>
  </ng-template>
  <ng-container *ngIf="month$ | async as month; else loading">
    <ng-container *ngIf="month.loading" [ngTemplateOutlet]="loading"></ng-container>
    <ng-container *ngIf="!month.loading">
      <ng-container *ngFor="let day of days$ | async">
        <app-schedule-day *ngIf="shouldShowDate(day.date)" [day]="day"></app-schedule-day>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="month.error">
      <div class="item">
        <h3>{{'schedule.noData.title' | translate}}</h3>
        <div class="card">
          <main>{{'schedule.noData.text' | translate}}</main>
        </div>
        <ng-container *ngIf="contracts$ | async as contracts">
          <a *ngIf="contracts.length > 1" href="javascript:void(0)" [routerLink]="'/menu/contracts'" class="button primary">
            {{'schedule.noData.button' | translate}}
          </a>
        </ng-container>
      </div>
    </ng-container>
  </ng-container>
</div>

<ng-container *ngIf="month$ | async as month">
  <ng-container *ngIf="!month.error">
    <a href="javascript:void(0)" (click)="onMonthChanged(nextMonth)" class="next-month button primary">
      {{'buttons.show' | translate}} {{nextMonth | djsDateFormat:'MMMM YYYY'}}
    </a>
  </ng-container>
</ng-container>

<!-- <div class="day-list">
  <ng-container *ngFor="let day of days$ | async">
    <app-schedule-day *ngIf="shouldShowDate(day.date)" [day]="day"></app-schedule-day>
  </ng-container>
  <a href="javascript:void(0)" (click)="onMonthChanged(nextMonth.toDate())" class="next-month button primary">
    {{'buttons.show' | translate}} {{nextMonth | djsDateFormat:'MMMM YYYY'}}
  </a>
</div> -->
