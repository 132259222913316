import { Component, OnDestroy } from '@angular/core';
import { LoaderService } from './loader.service';
import { Subscription } from 'rxjs';
import { Loader } from './loader.interface';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnDestroy {

  delay: number = 1000;
  subscription: Subscription;
  show = false;
  text = '';
  timeout = null;

  constructor(private loaderService: LoaderService) {
    this.subscription = this.loaderService.getUpdates().subscribe((loader: Loader) => {
      console.log('LOADER ->', loader);
      if (loader.show) {
        const delay = loader.delay >= 0 ? loader.delay : this.delay;
        console.log(`LOADER -> Showing in ${delay}ms`);
        this.timeout = setTimeout(() => {
          this.show = loader.show;
          this.text = loader.text;
        }, delay);
      } else {
        console.log('LOADER -> Clearing timeout');
        clearTimeout(this.timeout);
        this.show = false;
      }
    })
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

}
