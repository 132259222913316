import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ScheduleService } from '../schedule.service';
import { Observable, of } from 'rxjs';
import { Schedule } from '../interfaces/schedule';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { getPickingSchedule } from '../reducers/schedule.actions';

@Injectable()
export class PickingResolver implements Resolve<Schedule> {

  constructor(private store: Store<State>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Schedule> {
    this.store.dispatch(getPickingSchedule());
    return of(undefined);
  }

}