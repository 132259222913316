import { Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { environment } from '../../environments/environment';
import { Card } from '../interfaces/card';

@Component({
  selector: 'app-balance',
  templateUrl: './balance.component.html',
  styleUrls: ['./balance.component.scss']
})
export class BalanceComponent implements OnInit {
  
  isMenuChild = false;
  cards: Card[] = [];
  show498Button = false;

  @HostBinding('class') get cssClasses() {
    return `${this.isMenuChild ? 'menu-child' : ''}`;
  }

  constructor(
    private route: ActivatedRoute,
    private cookieService: CookieService,
  ) { }

  ngOnInit(): void { 
    console.log('this.route', this.route);
    this.isMenuChild = this.route.parent.routeConfig.path === 'menu';
    this.cards = this.route.snapshot.data.cards;
    const magazineId = this.cookieService.get('ess.magazine');
    if (magazineId === '335' && !environment.production) {
      this.show498Button = true;
    }
  }

  trigger498(): void {
    window.location.href = '/s/logout';
  }

}
