import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SwapCandidate } from './interfaces/swap-candidate';
import { Observable } from 'rxjs';
import { Swap } from './interfaces/swap';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';
import { State } from './reducers';

@Injectable({
  providedIn: 'root'
})
export class SwapService {

  swaps$ = this.store.select((state: State) => state.swaps.items);
  swapCandidates$ = this.store.select((state: State) => state.swaps.candidates);
  swapCount$ = this.swaps$.pipe(map((b) => b.length));

  constructor(private http: HttpClient, private store: Store<State>) { }

  getAll(): Observable<Swap[]> {
    return this.http.get<Swap[]>(`/api/swaps`);
  }

  getForShift(shiftId: number): Observable<Swap[]> {
    return this.http.get<Swap[]>(`/api/swaps?shiftId=${shiftId}`);
  }

  getCandidates(shiftId: number, date: string): Observable<SwapCandidate[]> {
    return this.http.get<SwapCandidate[]>(`/api/swaps/candidates?shiftId=${shiftId}&date=${date}`);
  }

  create(shiftId: number, wantedResourceId: number, wantedShiftId?: number) {
    const body: any = {shiftId, wantedResourceId};
    if (wantedShiftId) {
      body.wantedShiftId = wantedShiftId
    }
    return this.http.post(`/api/swaps`, body);
  }

  cancel(id: number) {
    return this.http.post(`/api/swaps/cancel`, {id});
  }

  accept(id: number) {
    return this.http.post(`/api/swaps/accept`, {id});
  }

  decline(id: number) {
    return this.http.post(`/api/swaps/decline`, {id});
  }
  
}
