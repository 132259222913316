<div (click)="openDay()">
  <div 
    *ngIf="shiftPicking && shift.requestExistsForDuty && shift.requests[0]" 
    [innerHTML]="'shiftPicking.request.waitingForConfirmation' | translate | formatUnicorn:shift.requests[0]?.typeName"
    class="request">
  </div>
  <div class="shift">
    <app-shift-header *ngIf="shiftPicking || shift.resourceId !== 0"
      [shift]="shift"
      [showCalendarLink]="!shiftPicking">
    </app-shift-header>
    <div class="actions" *ngIf="shiftPicking">
      <a (click)="startShiftPicking(shift, 'request');$event.stopPropagation()"
        [appCheckPermissions]="['AddRequestFromShiftPicking']"
        *ngIf="!shift.requestExistsForDuty"
        href="javascript:void(0)" 
        class="button primary medium">
        {{'buttons.apply' | translate}}
      </a>
      <a (click)="cancelRequest(shift.requests[0].id, shift.requests[0].type);$event.stopPropagation();"
        [appCheckPermissions]="['AddRequestFromShiftPicking']"
        *ngIf="shift.requestExistsForDuty"
        href="javascript:void(0)" 
        class="button secondary medium">
        {{'buttons.cancel' | translate}}
      </a>
      <a (click)="startShiftPicking(shift, 'pick');$event.stopPropagation()"
        [appCheckPermissions]="['PickShift']"
        *ngIf="!shift.requestExistsForDuty"
        href="javascript:void(0)" 
        class="button primary medium">
        {{'buttons.pick' | translate}}
      </a>
    </div>
  </div>
</div>
