<form [formGroup]="form">
  <ng-container *ngIf="shifts.length > 0; else noShifts">
    <app-shift-selector-input [shifts]="shifts" [control]="form.controls.shift">
    </app-shift-selector-input>
    <app-time-range-input [control]="form.controls.range">
    </app-time-range-input>
    <div class="actions">
      <button class="button secondary" (click)="cancel()">{{'buttons.cancel' | translate}}</button>
      <button [disabled]="form.controls.range.errors !== null" class="button confirmation" (click)="save()">{{'buttons.save' | translate}}</button>
    </div>
  </ng-container>
  <ng-template #noShifts>
      <div class="no-data">
        <span>
          <div class="icon main" [inlineSVG]="'/assets/icons/icon-no-roster.svg'"></div>
          {{'schedule.noData.noRealizableShifts' | translate}}
        </span>
      </div>
  </ng-template>
</form>