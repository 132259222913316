import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateChild, Router } from '@angular/router';
import { LoaderService } from '../../loader/loader.service';
import { AuthService } from '../auth.service';
import { TokenService } from '../token.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate, CanActivateChild {

  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private loaderService: LoaderService,
    private router: Router,
  ) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.loaderService.show('', 0);
    const isAuthenticated = await this.tokenService.isAuthenticatedOrRefresh().toPromise();
    // const isAuthenticated = this.authService.isLoggedIn();

    console.log('[AuthGuard]: isAuthenticated', isAuthenticated);
    
    this.loaderService.hide();
    if (isAuthenticated) {
      return true;
    } else {
      this.router.navigate([`error`], {queryParams: {code: 'login-failed'}})
    }

    return false;
  }

  async canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    return this.canActivate(route, state);
  }
  
}
