import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map, concatMap, withLatestFrom, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as AuthActions from './auth.actions'
import { UserService } from '../auth/user.service';
import { Store } from '@ngrx/store';
import { State } from '.';

@Injectable()
export class AuthEffects {

  getContracts$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.getContracts),
    mergeMap(() => this.userService.getContracts()
      .pipe(
        map(contracts => ({type: AuthActions.getContractsSuccess.type, contracts})),
        catchError(() => of({ type: AuthActions.getContractsError.type }))
      )
    )
  ))

  setFirstActiveContract$ = createEffect(() => this.actions$.pipe(
    ofType(AuthActions.getContractsSuccess),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.select('auth', 'activeContract'))
    )),
    tap(([action, activeContract]) => {
      if (!activeContract && action.contracts) {
        this.store.dispatch(AuthActions.setActiveContract({contract: action.contracts[0]}))
      }
    })
  ), {dispatch: false})

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private userService: UserService,
  ) {}
}