import { createReducer, on } from '@ngrx/store';
import { MagazineNavigation, MagazineNavigationRoute } from '../interfaces/navigation';
import { setNavigation } from './navigation.actions';

export const initialState: MagazineNavigation = {
  tabs: [
    MagazineNavigationRoute.SCHEDULE,
    MagazineNavigationRoute.REQUESTS,
    MagazineNavigationRoute.MENU,
  ],
  menu: [
    MagazineNavigationRoute.BALANCE,
    MagazineNavigationRoute.CONTRACTS,
  ],
};

const _navigationReducer = createReducer(initialState,
  on(setNavigation, (state: MagazineNavigation, {navigation}) => navigation),
)

export function navigationReducer(state, action) {
  return _navigationReducer(state, action);
}