<form [formGroup]="form">
  <label for="accessToken">
    Access Token
    <textarea formControlName="accessToken" id="accessToken"></textarea>
    <pre>{{accessToken}}</pre>
  </label>
  <label for="refreshToken">
    Refresh Token
    <textarea formControlName="refreshToken" id="refreshToken"></textarea>
    <pre>{{refreshToken}}</pre>
  </label>
  <label for="user">
    User
    <pre>{{user | json}}</pre>
  </label>
  <a href="javascript:void(0)" class="button primary" (click)="submit()">SUBMIT</a>
</form>