/**
 * Converts provided string from camelCase to snake_case
 * @param value 
 */
export const CamelToSnakeCase = (value: string) => value.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1_$2').toLowerCase();

/**
 * Converts provided string from camelCase to kebab-case
 * @param value 
 */
export const CamelToKebabCase = (value: string) => value.replace(/([a-z0-9]|(?=[A-Z]))([A-Z])/g, '$1-$2').toLowerCase();

/**
 * Converts provided string from snake_case or kebab-case to camelCase
 * @param value 
 */
export const SnakeOrKebabToCamelCase = (value: string) => value.replace(/([-_]\w)/g, g => g[1].toUpperCase());

/**
 * Returns the value of a query string parameter if it exists or false if it doesn't
 * @param name 
 */
export const getQueryStringParameter = (name: string) => {
  const query = window.location.search.substring(1);
  const vars = query.split('&');
  
	for (var i = 0; i < vars.length; i++) {
		const pair = vars[i].split('=');
		if (pair[0] == name) {
      return pair[1];
    }
  }
  
	return false;
}

/**
 * Checks the window object to see if an internet connection is active
 * @returns boolean
 */
export const isNetworkConnected = (): boolean => {
  return window.navigator.onLine;
}