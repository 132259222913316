import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { mergeMap, catchError, map, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import * as ScheduleActions from './schedule.actions'
import { ScheduleService } from '../schedule.service';
import { Store } from '@ngrx/store';
import { State } from '.';
import { setCurrentShift } from './swaps.actions';
import { ScheduleSettings } from '../interfaces/schedule-settings';
import * as dayjs from "dayjs";
import { emptySchedule } from "./schedule.reducer";

@Injectable()
export class ScheduleEffects {
  getMonth$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.getMonth),
    mergeMap((action) => this.scheduleService.getMonth(action.date)
      .pipe(
        map(schedule => ScheduleActions.getMonthSuccess({schedule: {...schedule, error: false, loading: false}})),
        catchError(() => of({ type: ScheduleActions.getMonthError.type }))
      )
    )
  ))

  getPickingSchedule$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.getPickingSchedule),
    mergeMap((action) => this.scheduleService.getScheduleSettings()
      .pipe(
        switchMap((settings: ScheduleSettings) => {
          return this.scheduleService.getSchedule(settings.maxFutureDays);
        })
      )
      .pipe(
        map(schedule => ScheduleActions.getPickingScheduleSuccess({schedule: {...schedule, error: false}})),
        catchError(() => of({ type: ScheduleActions.getPickingScheduleError.type }))
      )
    )
  ))

  getToday$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.getToday),
    mergeMap((action) => this.scheduleService.getDay(action.date)
      .pipe(
        map(day => {
          this.store.dispatch(setCurrentShift({shift: day.shifts ? day.shifts[0] : undefined}));
          return ScheduleActions.getTodaySuccess({day})
        }),
        catchError(() => of({ type: ScheduleActions.getTodayError.type }))
      )
    )
  ))

  clearMonth$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.getMonthError),
    tap(() => {
      this.store.dispatch(ScheduleActions.getMonthSuccess({schedule: {...emptySchedule, error: true}}));
      this.store.dispatch(ScheduleActions.getPickingScheduleSuccess({schedule: {...emptySchedule, error: true}}));
    })
  ), {dispatch: false})

  clearMonthForLoading$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.getMonth),
    tap(() => {
      this.store.dispatch(ScheduleActions.setLoading({loading: true}));
    })
  ), {dispatch: false})

  createRealization$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.createRealization),
    mergeMap(action => this.scheduleService.createRealization(action.shiftId, action.startTime, action.endTime)
      .pipe(
        map(() => ({type: ScheduleActions.realizationSuccess.type})),
        catchError(() => of({ type: ScheduleActions.createRealizationError.type }))
      )
    )
  ))

  cancelRealization$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.cancelRealization),
    mergeMap(action => this.scheduleService.cancelRealization(action.shiftId, action.startTime, action.endTime)
      .pipe(
        map(() => ({type: ScheduleActions.realizationSuccess.type})),
        catchError(() => of({ type: ScheduleActions.cancelRealizationError.type }))
      )
    )
  ))

  updateRealization$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.updateRealization),
    mergeMap(action => this.scheduleService.cancelRealization(action.original.shiftId, action.original.startTime, action.original.endTime)
      .pipe(
        switchMap(() => this.scheduleService.createRealization(action.realization.shiftId, action.realization.startTime, action.realization.endTime)
          .pipe(
            map(() => ({type: ScheduleActions.realizationSuccess.type})),
            catchError(() => of({ type: ScheduleActions.updateRealizationError.type }))
          ))
      ))
  ))

  afterCreateOrCancelRealization$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.realizationSuccess),
    tap(() => {
      this.store.select('schedule', 'viewDate').subscribe(viewDate => {
        this.store.dispatch(ScheduleActions.getToday({date: viewDate}));
        this.store.dispatch(ScheduleActions.getMonth({date: viewDate}));
      })
    })
  ), {dispatch: false})

  getApprovableShifts$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.getApprovableShifts),
    mergeMap((action) => this.scheduleService.getApprovableShifts(action.date)
      .pipe(
        map(shifts => ScheduleActions.getApprovableShiftsSuccess({shifts})),
        catchError(error => of(ScheduleActions.getApprovableShiftsError({error})))
      )
    )
  ))

  confirmShift$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.confirmShift),
    mergeMap((action) => this.scheduleService.confirmShift(action.shiftId)
      .pipe(
        map(() => ScheduleActions.confirmShiftSuccess()),
        catchError(error => of(ScheduleActions.confirmShiftError({error})))
      )
    )
  ))

  declineShift$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.declineShift),
    mergeMap((action) => this.scheduleService.declineShift(action.shiftId)
      .pipe(
        map(() => ScheduleActions.declineShiftSuccess()),
        catchError(error => of(ScheduleActions.declineShiftError({error})))
      )
    )
  ))

  afterConfirmOrDeclineShift$ = createEffect(() => this.actions$.pipe(
    ofType(ScheduleActions.confirmShiftSuccess, ScheduleActions.declineShiftSuccess),
    tap(() => {
      this.store.select('schedule', 'viewDate').subscribe(viewDate => {
        this.store.dispatch(ScheduleActions.getApprovableShifts({date: viewDate}));
        this.store.dispatch(ScheduleActions.getMonth({date: viewDate}));
      })
    })
  ), {dispatch: false})

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private scheduleService: ScheduleService,
  ) {}
}