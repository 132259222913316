import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler, Provider } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { AuthModule } from './auth/auth.module';
import { ConfigService } from './config.service';
import { TranslateModule, TranslateLoader, TranslateService } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NavigationComponent } from './navigation/navigation.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { RequestsComponent } from './requests/requests.component';
import { BalanceComponent } from './balance/balance.component';
import { ScheduleService } from './schedule.service';
import { LoaderModule } from './loader/loader.module';
import { ScheduleDayComponent } from './schedule-day/schedule-day.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ScheduleResolver } from './resolvers/schedule.resolver';
import { ScheduleDayDetailComponent } from './schedule-day-detail/schedule-day-detail.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { CardsService } from './cards.service';
import { CardsResolver } from './resolvers/cards.resolver';
import { BalanceCardComponent } from './balance-card/balance-card.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { registerLocaleData } from '@angular/common';
import { ContextMenuComponent } from './context-menu/context-menu.component';
import { RequestFormComponent } from './request-form/request-form.component';
import { SharedModule } from './shared/shared.module';
import { RequestService } from './request.service';
import { RequestSwapFormComponent } from './request-swap-form/request-swap-form.component';
import { SwapService } from './swap.service';
import { environment } from '../environments/environment';
import { SwapRequestComponent } from './swap-request/swap-request.component';
import { BaseComponent } from './base/base.component';
import { DebugComponent } from './debug/debug.component';
import { RequestComponent } from './request/request.component';
import { UserResolver } from './resolvers/user.resolver';
import { TeamScheduleComponent } from './team-schedule/team-schedule.component';
import { PickingResolver } from './resolvers/picking.resolver';
import { ShiftPickingComponent } from './shift-picking/shift-picking.component';
import { ShiftPickingWidgetComponent } from './shift-picking-widget/shift-picking-widget.component';
import { StoreModule } from '@ngrx/store';
import { reducers, metaReducers } from './reducers';
import { EffectsModule } from '@ngrx/effects';
import { RequestsEffects } from './reducers/requests.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { SwapsEffects } from './reducers/swaps.effects';
import { ScheduleEffects } from './reducers/schedule.effects';
import { HttpErrorInterceptor } from './http-error.interceptor';
import { ErrorComponent } from './error/error.component';
import { ErrorService } from './error/error.service';
import { ShiftPickingDialogComponent } from './shift-picking-dialog/shift-picking-dialog.component';
import { ShiftPickingService } from './shift-picking.service';
import { AuthEffects } from './reducers/auth.effects';
import { ContractResolver } from './resolvers/contract.resolver';
import { ContractComponent } from './contract/contract.component';

import { ThemeModule } from './theme/theme.module';
import { DefaultTheme } from './themes/default.theme';
import { Magazine324 } from './themes/324.theme';
import { Magazine335 } from './themes/335.theme';

import * as dayjs from 'dayjs';
import * as isoWeek from 'dayjs/plugin/isoWeek';
import * as advancedFormat from 'dayjs/plugin/advancedFormat';
import * as duration from 'dayjs/plugin/duration';

import 'dayjs/locale/en';
import 'dayjs/locale/nl';
import 'dayjs/locale/fi';
import 'dayjs/locale/sv';
import 'dayjs/locale/da';

dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(duration);

import localeEn from '@angular/common/locales/en';
import localeNl from '@angular/common/locales/nl';
import localeFi from '@angular/common/locales/fi';
import localeSv from '@angular/common/locales/sv';
import localeDa from '@angular/common/locales/da';

import { RealizationFormComponent } from './realization-form/realization-form.component';
import { SlideUpPanelModule } from './slide-up-panel/slide-up-panel.module';
import { AvailabilityEffects } from './availability/reducers/availability.effects';
import { AppMenuComponent } from './app-menu/app-menu.component';
import { AppService } from './app.service';
import { Magazine342 } from './themes/342.theme';
import { ApprovableShiftsComponent } from './approvable-shifts/approvable-shifts.component';
import { SliderModule } from './slider/slider.module';
import { Magazine349 } from './themes/349.theme';

registerLocaleData(localeEn);
registerLocaleData(localeNl);
registerLocaleData(localeFi);
registerLocaleData(localeSv);
registerLocaleData(localeDa);

export function initializeApp(appService: AppService) {
  return (): Promise<any> => {
    return appService.init();
  }
}

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, '/assets/i18n/', '.json');
}

/**
 * Prepare the providers array so we can conditionally add
 * providers to it
 */
let providers: Provider[] = [];

/**
 * Add the providers we always want to use
 */
providers = providers.concat([
  { provide: "windowObject", useValue: window },
  {
    provide: APP_INITIALIZER,
    useFactory: initializeApp,
    multi: true,
    deps: [AppService]
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },
  UserResolver,
  ErrorService,
  ScheduleResolver,
  PickingResolver,
  CardsResolver,
  ContractResolver,
  CookieService,
  ConfigService,
  ScheduleService,
  RequestService,
  CardsService,
  SwapService,
  ShiftPickingService,
  AppService,
]);

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    ScheduleComponent,
    RequestsComponent,
    BalanceComponent,
    ScheduleDayComponent,
    ScheduleDayDetailComponent,
    ErrorComponent,
    BalanceCardComponent,
    ContextMenuComponent,
    RequestFormComponent,
    RequestSwapFormComponent,
    SwapRequestComponent,
    BaseComponent,
    DebugComponent,
    RequestComponent,
    TeamScheduleComponent,
    ShiftPickingComponent,
    ShiftPickingWidgetComponent,
    ErrorComponent,
    GenericErrorComponent,
    ShiftPickingDialogComponent,
    ContractComponent,
    RealizationFormComponent,
    AppMenuComponent,
    ApprovableShiftsComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      },
      defaultLanguage: 'en',
      useDefaultLang: true,
    }),
    ThemeModule.forRoot({
      themes: [
        DefaultTheme,
        Magazine324,
      ],
      active: 'default'
    }),
    InlineSVGModule.forRoot(),
    StoreModule.forRoot(reducers, {metaReducers}),
    EffectsModule.forRoot([
      RequestsEffects,
      SwapsEffects,
      ScheduleEffects,
      AuthEffects,
      AvailabilityEffects,
    ]),
    environment.useStoreDevtools ? StoreDevtoolsModule.instrument({maxAge: 25, logOnly: environment.production}) : [],
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    LoaderModule,
    AuthModule,
    SlideUpPanelModule,
    RouterModule,
    SliderModule,
  ],
  providers: providers,
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor() {}
}
