import { createAction, props } from '@ngrx/store';
import { Dayjs } from 'dayjs';
import { TemplateRequest } from '../../interfaces/template-request';
import { AvailabilityDay } from '../interfaces/availability-day';

export const setRange = createAction('[Availability] Set Range', props<{startDate: string, endDate: string}>());

export const getAvailability = createAction('[Availability] Get Availability', props<{startDate: Dayjs, endDate: Dayjs}>());
export const getAvailabilitySuccess = createAction('[Availability] Get Availability Success', props<{ days: AvailabilityDay[] }>());
export const getAvailabilityError = createAction('[Availability] Get Availability Error');

export const createTemplateRequest = createAction('[Availability] Create Template Request', props<{request: TemplateRequest}>());
export const createTemplateRequestSuccess = createAction('[Availability] Create Template Success');
export const createTemplateRequestError = createAction('[Availability] Create Template Error');