import { createReducer, on } from '@ngrx/store';
import { getAllSuccess, getAllError } from './requests.actions';
import { Request } from '../interfaces/request';

export const initialState: Request[] = [];

const _requestsReducer = createReducer(initialState,
  on(getAllSuccess, (state: Request[], {requests}) => requests),
  on(getAllError, (state: Request[]) => [])
)

export function requestsReducer(state, action) {
  return _requestsReducer(state, action);
}