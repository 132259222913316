import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { environment } from '../../environments/environment';
import { Store } from '@ngrx/store';
import { selectAuth0Value, State } from '../reducers';
import * as AuthActions from '../reducers/auth.actions';
import { first } from 'rxjs/operators';
import { Contract } from '../interfaces/contract';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  jwt: JwtHelperService;

  sessionCookie       = 'ess.session';
  auth0AtCookie       = 'ess.auth0.accessToken';
  auth0RtCookie       = 'ess.auth0.refreshToken';
  bailOutCookie       = 'ess.bailOut';

  blockAccess$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private injector: Injector,
    private store: Store<State>,
    private http: HttpClient,
    private cookieService: CookieService,
  ) { 
    this.jwt = new JwtHelperService();
  }

  getAccessToken(): Observable<string> {
    const result = this.store.select(selectAuth0Value, {key: 'accessToken'}).pipe(first());
    selectAuth0Value.release();
    return result;
  }

  getRefreshToken(): Observable<string> {
    const result = this.store.select(selectAuth0Value, {key: 'refreshToken'}).pipe(first());
    selectAuth0Value.release();
    return result;
  }

  getContract(): Observable<Contract> {
    return this.store.select('auth', 'activeContract').pipe(first());
  }

  getUser(): Observable<any> {
    return this.store.select((state: State) => (state.auth ? state.auth.user : undefined)).pipe(first())
  }

  setCookie(name: string, value: string): void {
    const sameSite: any = environment.cookies.sameSite;
    if (environment.cookies.secure) {
      this.cookieService.set(name, value, 0, '/', environment.cookies.domain, environment.cookies.secure, sameSite)
    } else {
      this.cookieService.set(name, value, 0, '/')
    }
  }

  deleteCookie(name: string): void {
    const sameSite: any = environment.cookies.sameSite;
    if (environment.cookies.secure) {
      this.cookieService.delete(name, '/', environment.cookies.domain, environment.cookies.secure, sameSite)
    } else {
      this.cookieService.delete(name, '/')
    }
  }

  isLoggedIn(): boolean {
    return this.cookieService.check(this.sessionCookie);
  }

  login(accessToken: string, refreshToken: string): void {
    const decodedToken = this.jwt.decodeToken(accessToken);
    console.warn('LOGGING IN!', decodedToken);
    // Log the user platform
    this.logUserPlatform();
    
    let user = {...decodedToken};
    const permissions = user.permissions;
    const settings = user.settings;
    delete user.iat;
    delete user.exp;
    delete user.permissions;
    
    this.store.dispatch(AuthActions.setUser({user}));
    this.store.dispatch(AuthActions.setPermissions({permissions}));
    this.store.dispatch(AuthActions.setSettings({settings}));
    this.store.dispatch(AuthActions.setTokens({accessToken, refreshToken}));

    this.setCookie(this.sessionCookie, new Date().toISOString());
    this.deleteCookie(this.bailOutCookie);

    this.grantAccess();
  }

  logout(): void {
    console.warn('LOGGING OUT!');
    this.getUser().subscribe(user => {
      console.warn('LOGGING OUT USER:', user)
      // Clear auth state
      this.store.dispatch(AuthActions.setUser(undefined));
      this.store.dispatch(AuthActions.setPermissions(undefined));
      this.store.dispatch(AuthActions.setTokens(undefined));
      this.store.dispatch(AuthActions.getContractsSuccess(undefined));
      this.store.dispatch(AuthActions.setActiveContract(undefined));
      // Delete the session cookie
      this.cookieService.delete(this.sessionCookie);
    })
  }

  bailOut(): boolean {
    const cookieExists = this.cookieService.check(this.bailOutCookie)
    if (cookieExists) return false;

    console.warn('BAILING OUT!')

    this.setCookie(this.bailOutCookie, new Date().toISOString());
    // Log the user platform
    this.logUserPlatform();
    // Completely block access to the user preventing any clicks/navigations
    this.blockAccess();
    // Log out making sure we can restart with a clean session
    this.logout();
    // Delete Auth0 AccessToken and RefreshToken cookies to make
    // that if they're set once again they'll be new
    this.cookieService.delete(this.auth0AtCookie);
    this.cookieService.delete(this.auth0RtCookie);
    // Redirect to the endpoint that will throw a 498 error
    window.open(`${window.location.origin}/s/logout`, '_self');
    return true;
  }

  blockAccess(): void {
    this.blockAccess$.next(true);
  }

  grantAccess(): void {
    this.blockAccess$.next(false);
  }

  logUserPlatform(): void {
    console.group('User Platform');
    console.log('appVersion:', window.navigator.appVersion);
    console.log('appCodeName:', window.navigator.appCodeName);
    console.log('appName:', window.navigator.appName);
    console.log('userAgent:', window.navigator.userAgent);
    console.log('platform:', window.navigator.platform);
    console.log('vendor:', window.navigator.vendor);
    console.log('cookieEnabled:', window.navigator.cookieEnabled);
    console.groupEnd();
  }

  refreshToken(accessToken: string, refreshToken: string) {
    if (!accessToken || !refreshToken) {
      this.bailOut();
      return of(false);
    }
    const headers = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${accessToken}`,
    });
    return this.http.post('/api/auth/token', {
      refreshToken,
    }, {headers});
  }

  platformLogin(magazineId: number, token: string, language: string): Promise<any> {
    return this.http.post(`/api/auth/login`, {magazineId, token, language}).toPromise();
  }

  auth0Login(auth0AccessToken: string, auth0RefreshToken: string, uid: string, magazineId: number, language: string): Promise<any> {
    return this.http.post(`/api/auth/auth0-login`, {auth0AccessToken, auth0RefreshToken, uid, magazineId, language}).toPromise();
  }

  comtecLogin(username: string, password: string, uid: string, magazineId: number, language: string) {
    return this.http.post(`/api/auth/comtec-login`, {username, password, uid, magazineId, language}); 
  }

}
