import { createAction, props } from '@ngrx/store';
import { Day } from '../interfaces/day';
import { Schedule } from '../interfaces/schedule';
import { iApprovableShift } from '@shared/schedule';

export const setViewDate = createAction('[Schedule] Set View Date', props<{date: string}>());
export const setLoading = createAction('[Schedule] Set Loading', props<{loading: boolean}>());

export const getMonth = createAction('[Schedule] Get Month', props<{date: string}>());
export const getMonthSuccess = createAction('[Schedule] Get Month Success', props<{ schedule: Schedule }>());
export const getMonthError = createAction('[Schedule] Get Month Error');

export const getToday = createAction('[Schedule] Get Today', props<{date: string}>());
export const getTodaySuccess = createAction('[Schedule] Get Today Success', props<{ day: Day }>());
export const getTodayError = createAction('[Schedule] Get Today Error');

export const getPickingSchedule = createAction('[Schedule] Get Picking Schedule');
export const getPickingScheduleSuccess = createAction('[Schedule] Get Picking Schedule Success', props<{ schedule: Schedule }>());
export const getPickingScheduleError = createAction('[Schedule] Get Picking Schedule Error');

export const createRealization = createAction('[Schedule] Create Realization', props<{shiftId: number, startTime: string, endTime: string}>()); 
// export const createRealizationSuccess = createAction('[Schedule] Create Realization Success', props<{result: any}>()); 
export const createRealizationError = createAction('[Schedule] Create Realization Error'); 

export const cancelRealization = createAction('[Schedule] Cancel Realization', props<{shiftId: number, startTime: string, endTime: string}>()); 
// export const cancelRealizationSuccess = createAction('[Schedule] Cancel Realization Success', props<{result: any}>()); 
export const cancelRealizationError = createAction('[Schedule] Cancel Realization Error'); 

export const updateRealization = createAction('[Schedule] Update Realization', props<{
  original: {shiftId: number, startTime: string, endTime: string}, 
  realization: {shiftId: number, startTime: string, endTime: string}
}>()); 
// export const updateRealizationSuccess = createAction('[Schedule] Update Realization Success', props<{result: any}>()); 
export const updateRealizationError = createAction('[Schedule] Update Realization Error'); 

export const realizationSuccess = createAction('[Schedule] Realization Success'); 


export const getApprovableShifts = createAction('[Schedule] Get Approvable Shifts', props<{date: string}>());
export const getApprovableShiftsSuccess = createAction('[Schedule] Get Approvable Shifts Success', props<{ shifts: iApprovableShift[] }>());
export const getApprovableShiftsError = createAction('[Schedule] Get Approvable Shifts Error', props<{ error: any }>());

export const confirmShift = createAction('[Schedule] Confirm Shift', props<{shiftId: number}>());
export const confirmShiftSuccess = createAction('[Schedule] Confirm Shift Success');
export const confirmShiftError = createAction('[Schedule] Confirm Shift Error', props<{ error: any }>());

export const declineShift = createAction('[Schedule] Decline Shift', props<{shiftId: number}>());
export const declineShiftSuccess = createAction('[Schedule] Decline Shift Success');
export const declineShiftError = createAction('[Schedule] Decline Shift Error', props<{ error: any }>());