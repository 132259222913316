import { createReducer, on } from '@ngrx/store';
import { Day } from '../interfaces/day';
import { getTodaySuccess, getMonthSuccess, setViewDate, getPickingScheduleSuccess, getApprovableShiftsSuccess, setLoading } from './schedule.actions';
import { Schedule } from '../interfaces/schedule';
import { iApprovableShift } from '@shared/schedule';

export interface ScheduleState {
  viewDate: string;
  today: Day;
  month: Schedule;
  picking: Schedule;
  approvable: iApprovableShift[];
}

export const emptySchedule: Schedule = {
  shiftCount: 0,
  extraShiftCount: 0,
  days: [],
  error: false,
  loading: true
}

export const initialState: ScheduleState = {
  viewDate: new Date().toISOString(),
  today: undefined,
  month: emptySchedule,
  picking: emptySchedule,
  approvable: undefined,
};

const _scheduleReducer = createReducer(initialState,
  on(setViewDate, (state: ScheduleState, {date}) => ({
    ...state,
    viewDate: date
  })),
  on(setLoading, (state: ScheduleState, {loading}) => {
    let month = {...state.month};
    if (month.days.length > 0) {
      month.loading = true;
    } else {
      month = {...emptySchedule, loading};
    }
    return {
      ...state,
      month
    }
  }),
  on(getMonthSuccess, (state: ScheduleState, {schedule}) => ({
    ...state,
    month: schedule
  })),
  on(getPickingScheduleSuccess, (state: ScheduleState, {schedule}) => ({
    ...state,
    picking: schedule
  })),
  on(getTodaySuccess, (state: ScheduleState, {day}) => ({
    ...state,
    today: day
  })),
  on(getApprovableShiftsSuccess, (state: ScheduleState, {shifts}) => ({
    ...state,
    approvable: shifts
  })),
)

export function scheduleReducer(state, action) {
  return _scheduleReducer(state, action);
}