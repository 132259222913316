import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as dayjs from 'dayjs';
import { UserService } from '../auth/user.service';
import { BaseComponent } from '../base/base.component';
import { Contract } from '../interfaces/contract';
import { State } from '../reducers';
import { setActiveContract } from '../reducers/auth.actions';
import { SelectorInputOption, SelectorInputStyle } from '../shared/selector-input/selector-input.component';

@Component({
  selector: 'app-user',
  templateUrl: './contract.component.html',
  styleUrls: ['./contract.component.scss']
})
export class ContractComponent extends BaseComponent implements OnInit {

  selectorInputStyle: SelectorInputStyle = SelectorInputStyle.BLOCK;
  contractOptions: SelectorInputOption[] = [];
  contracts: Contract[] = [];
  activeContract: Contract;
  translations: string[] = [];

  constructor(
    private userService: UserService,
    private translateService: TranslateService,
    private store: Store<State>
  ) {
    super();
  }

  selectContract(id: number) {
    this.store.dispatch(setActiveContract({
      contract: this.contracts.filter((contract) => contract.id === id)[0]
    }));
  }

  ngOnInit(): void {
    this.addSubscription(this.translateService.get([
      'dateFormatting.contractEndDate',
      'contracts.expiresOn',
      'contracts.expired',
    ]).subscribe(translations => {
      this.translations = translations;
      this.getData();
    }))
  }

  getData() {
    this.addSubscription(this.userService.activeContract$.subscribe(contract => {
      this.activeContract = contract;
    }))
    this.addSubscription(this.userService.contracts$.subscribe(contracts => {
      this.contracts = contracts;
      this.contractOptions = contracts.map(contract => {
        let label = `${contract.employeeNumber}`;

        if (contract.expiredAt !== null) {
          if (dayjs().isBefore(dayjs(contract.expiredAt), 'day')) {
            label += `<br /><span>${this.translations['contracts.expiresOn']}: ${dayjs(contract.expiredAt).format(this.translations['dateFormatting.contractEndDate'])}</span>`
          } else {
            label += `<br /><span>${this.translations['contracts.expired']}: ${dayjs(contract.expiredAt).format(this.translations['dateFormatting.contractEndDate'])}</span>`
          }
        }
        
        return {
          label,
          value: contract.id
        }
      })
    }))
  }

}
