import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { SwapService } from '../swap.service';
import { mergeMap, catchError, map, concatMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import * as SwapsActions from './swaps.actions'
import { NotificationService } from '../notification.service';
import { State } from '.';
import { Store } from '@ngrx/store';
import * as dayjs from 'dayjs';

@Injectable()
export class SwapsEffects {
  getAllSwaps$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.getAll),
    mergeMap(() => this.swapService.getAll()
      .pipe(
        map(swaps => ({type: SwapsActions.getAllSuccess.type, swaps})),
        catchError(() => of({ type: SwapsActions.getAllError.type }))
      )
    )
  ))

  create$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.create),
    mergeMap(action => this.swapService.create(action.dto.shiftId, action.dto.wantedResourceId, action.dto.wantedShiftId)
      .pipe(
        map(() => ({type: SwapsActions.createSuccess.type, meta: action.meta})),
        catchError(() => of({ type: SwapsActions.createError.type }))
      )
    )
  ))

  createSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.createSuccess),
    mergeMap(action => {
      this.notificationService.send(
        action.meta.wantedResourceId,
        `swaps.notifications.${(action.meta.shiftWanted ? 'shiftForShift' : 'shiftForTimeOff')}.created`,
        [
          {value: action.meta.sender},
          {value: action.meta.requestShiftName},
          {value: action.meta.wantedShiftName}
        ]
      );
      return of({type: SwapsActions.getAll.type});
    })
  ))

  cancel$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.cancel),
    mergeMap(action => this.swapService.cancel(action.request.id).pipe(
      map(() => ({type: SwapsActions.cancelSuccess.type, request: action.request})),
      catchError(() => of({ type: SwapsActions.cancelError.type }))
    ))
  ))

  cancelSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.cancelSuccess),
    mergeMap(action => {
      this.notificationService.send(
        action.request.resourceIdWanted,
        `swaps.notifications.${(action.request.shiftWanted ? 'shiftForShift' : 'shiftForTimeOff')}.cancelled`,
        [
          {value: action.request.requestUser},
          {value: action.request.shiftRequest?.name},
          {value: action.request.shiftWanted?.name}
        ]
      );
      return of({type: SwapsActions.remove.type, id: action.request.id});
    })
  ))

  accept$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.accept),
    mergeMap(action => this.swapService.accept(action.request.id).pipe(
      map(() => ({type: SwapsActions.acceptSuccess.type, request: action.request})),
      catchError(() => of({ type: SwapsActions.acceptError.type }))
    ))
  ))

  acceptSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.acceptSuccess),
    mergeMap(action => {
      this.notificationService.send(
        action.request.resourceIdRequest,
        `swaps.notifications.${(action.request.shiftWanted ? 'shiftForShift' : 'shiftForTimeOff')}.accepted`,
        [
          {value: action.request.wantedUser},
          {value: action.request.shiftWanted?.name},
          {value: action.request.shiftRequest?.name}
        ]
      );
      return of({type: SwapsActions.getAll.type});
    })
  ))

  decline$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.decline),
    mergeMap(action => this.swapService.decline(action.request.id).pipe(
      map(() => ({type: SwapsActions.declineSuccess.type, request: action.request})),
      catchError(() => of({ type: SwapsActions.declineError.type }))
    ))
  ))

  declineSuccess$ = createEffect(() => this.actions$.pipe(
    ofType(SwapsActions.declineSuccess),
    mergeMap(action => {
      this.notificationService.send(
        action.request.resourceIdRequest,
        `swaps.notifications.${(action.request.shiftWanted ? 'shiftForShift' : 'shiftForTimeOff')}.declined`,
        [
          {value: action.request.wantedUser},
          {value: action.request.shiftWanted?.name},
          {value: action.request.shiftRequest?.name}
        ]
      );
      return of({type: SwapsActions.getAll.type});
    })
  ))

  getCandidates$ = createEffect(() => this.actions$.pipe(
    ofType(
      SwapsActions.getCandidates,
      SwapsActions.setCurrentShift,
      SwapsActions.createSuccess,
      SwapsActions.declineSuccess,
      SwapsActions.cancelSuccess,
      SwapsActions.acceptSuccess
    ),
    concatMap(action => of(action).pipe(
      withLatestFrom(this.store.select((state: State) => state.swaps.currentShift))
    )),
    mergeMap(([action, currentShift]) => (
      currentShift && !currentShift.pendingDuty ?
      this.swapService.getCandidates(currentShift.id, dayjs(currentShift.startTime).format('YYYY-MM-DD')) :
      of([])
    ).pipe(
      map(candidates => ({type: SwapsActions.getCandidatesSuccess.type, candidates})),
      catchError(() => of({ type: SwapsActions.getCandidatesError.type }))
    ))
  ))

  constructor(
    private store: Store<State>,
    private actions$: Actions,
    private swapService: SwapService,
    private notificationService: NotificationService,
  ) {}
}