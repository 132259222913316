import { Pipe, PipeTransform } from '@angular/core';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

@Pipe({
  name: 'djsDateFormat'
})
export class DjsDateFormatPipe implements PipeTransform {

  transform(value: string|Dayjs|Date, ...args: string[]): string {
    if (!value) return '';
    return dayjs(value).format(args[0]);
  }

}
