import { Resolve, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { ScheduleService } from '../schedule.service';
import { Observable, EMPTY, of } from 'rxjs';
import { Schedule } from '../interfaces/schedule';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { getApprovableShifts, getMonth, setViewDate } from '../reducers/schedule.actions';

@Injectable()
export class ScheduleResolver implements Resolve<Schedule> {
  constructor(private store: Store<State>) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Schedule> {
    let date = route.params.date;
    if (!date) date = new Date().toISOString();
    this.store.dispatch(setViewDate({date}));
    this.store.dispatch(getMonth({date}));
    this.store.dispatch(getApprovableShifts({date}));
    return of(undefined);
  }
}