import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

export interface ContextMenuItem {
  key: string;
  label: string;
}

@Component({
  selector: 'app-context-menu',
  templateUrl: './context-menu.component.html',
  styleUrls: ['./context-menu.component.scss']
})
export class ContextMenuComponent implements OnInit {

  @Input() title: string = '';
  @Input() items: ContextMenuItem[] = [];

  @Output() onItemPressed: EventEmitter<string> = new EventEmitter<string>();

  open: boolean = false;

  constructor() { }

  ngOnInit(): void {
  }

  pressItem(key: string) {
    this.onItemPressed.emit(key);
    this.toggleOpen();
  }

  toggleOpen() {
    this.open = !this.open;
  }

}
