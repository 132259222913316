import { Component, OnInit, Input, SimpleChanges, OnChanges, EventEmitter, Output, HostBinding } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';

export interface SelectorInputOption {
  label: string;
  value: any;
}

export enum SelectorInputStyle {
  INLINE = 'inline',
  BLOCK = 'block',
}

@Component({
  selector: 'app-selector-input',
  templateUrl: './selector-input.component.html',
  styleUrls: ['./selector-input.component.scss']
})
export class SelectorInputComponent implements OnInit, OnChanges {

  @Input() options: SelectorInputOption[] = [];
  @Input() control: UntypedFormControl;
  @Input() selected: any;
  @Input() style: SelectorInputStyle = SelectorInputStyle.INLINE;
  @Input() multiple: boolean = false;

  @Output() selectionChanged: EventEmitter<any> = new EventEmitter<any>();

  @HostBinding('class') get cssClasses() {
    return `${this.style}`;
  }

  constructor() { }

  ngOnInit(): void {
    if (this.options.length > 0 && !this.selected) {
      if (this.multiple) {
        this.selected = [this.options[0].value];
      } else {
        this.selected = this.options[0].value;
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.options) {
      const selectedWithinOptions = changes.options.currentValue.filter((option: SelectorInputOption) => option.value === this.selected);

      if (selectedWithinOptions.length < 1 && changes.options.currentValue.length >= 1) {
        if (this.multiple) {
          this.selected = [changes.options.currentValue[0].value];
        } else {
          this.selected = changes.options.currentValue[0].value;
        }
      }
    }
    if (changes.selected) {
      this.selected = changes.selected.currentValue;
    }
  }

  selectOption(value: any) {
    if (this.multiple) {
      if (this.selected.indexOf(value) === -1) {
        this.selected = [...this.selected, value];
      } else {
        this.selected = this.selected.filter(e => (e !== value));
      }
    } else {
      this.selected = value;
    }

    this.selectionChanged.emit(this.selected);
    if (this.control) {
      this.control.setValue(this.selected);
    }
  }

  getOptionCssClass(optionValue) {
    if (this.multiple) {
      return this.selected.indexOf(optionValue) !== -1 ? 'selected' : '';
    } else {
      return this.selected === optionValue ? 'selected' : ''
    }
  }

}
