<!-- <app-loading *ngIf="isLoading"></app-loading> -->
<div class="keen-slider" #sliderRef>
  <ng-content></ng-content>
  <!-- This dummy slide is here to make sure the slider always initializes with 1 slide even if none are provided -->
  <!-- to prevent init bugs like sliding and navigational dots not being initialized-->
  <app-slider-slide *ngIf="slideList.length === 0">{{slideList.length}}</app-slider-slide>
  <!-- End dummy slide -->
</div>
<div class="dots" *ngIf="dotHelper.length > 1">
  <button *ngFor="let slide of dotHelper; let i = index" (click)="moveTo(i)" [class]="'dot ' + (i === currentSlide ? 'active' : '')"></button>
</div>