import * as buildInfo from '../../build-info';

export const environment = {
  version: buildInfo.version,
  production: true,
  useStatistics: false,
  useStoreDevtools: false,
  cookies: {
    domain: 'ess.ortec-relevance.com',
    secure: true,
    sameSite: 'Lax'
  },
  minimumCoreVersions: {
    ios: '3.6.1',
    android: '3.1.0'
  }
};
