import { Theme } from '../theme/theme.interface';

export const DefaultTheme: Theme = {
  name: 'default',
  properties: {
    // Simple colors
    'dark': '#222',
    'light': '#FFF',
    'white': '#FFF',
    'grey-light': '#EFEFEF',
    'grey': '#D6D6D6',
    'grey-dark': '#6B6B6B',
    'background': '#EFEFEF',
    'muted': '#CCC',
    // Theme colors
    'primary': '#0071B3',
    'primary-rgb': '0, 113, 179',
    'primary-d-10': '#0065a1',
    'primary-d-20': '#005a8f',
    'primary-d-40': '#00436b',
    'on-primary': 'var(--white)',
    'secondary': '#017D34',
    'secondary-d-10': '#00702e',
    'secondary-d-20': '#006429',
    'secondary-d-40': '#004b1f',
    'on-secondary': 'var(--white)',
    'accent': '#A90073',
    'accent-rgb': '169, 0, 115',
    'accent-d-10': '#980067',
    'accent-d-20': '#87005b',
    'accent-d-40': '#650044',
    'on-accent': 'var(--white)',
    'negative': '#DB0000',
    'negative-d-10': '#c50000',
    'negative-d-20': '#af0000',
    'negative-d-40': '#830000',
    'on-negative': 'var(--white)',
    'positive': '#03AC00',
    'positive-d-10': '#029a00',
    'positive-d-20': '#028900',
    'positive-d-40': '#028900',
    'on-positive': 'var(--white)',
    'warning': '#EF9000',
    'warning-d-10': '#d78100',
    'warning-d-20': '#bf7300',
    'warning-d-40': '#8f5600',
    'on-warning': 'var(--white)',
    // Top Navigation
    'top-nav': 'var(--white)',
    'on-top-nav': 'var(--dark)',
    'top-nav-shadow': '0 2px 4px 0 rgba(0, 0, 0, 0.1)',
    'tab-text': 'var(--accent)',
    'tab-text-inactive': 'var(--grey-dark)',
    'tab-bg-inactive': 'var(--grey-light)',
    'tab-border': 'var(--grey)',
    'tab-active-detail': 'var(--accent)',
    'button-nav-background': 'var(--white)',
    'button-nav-border': 'var(--grey)',
    'button-nav-background-active': 'var(--primary)',
    'button-nav-on-active': 'var(--on-primary)',
    'button-nav-background-inactive': 'var(--primary)',
    'button-nav-on-inactive': 'var(--grey-dark)',
    // Shift picking banner
    'sp-banner-bg': 'var(--primary)',
    'sp-banner-text': 'var(--on-primary)',
    'sp-banner-icon': 'var(--on-primary)',
    // Buttons
    'button-border-radius': '4px',
    'primary-button-bg': 'var(--primary)',
    'primary-button-text': 'var(--on-primary)',
    'primary-button-border': 'var(--primary)',
    'primary-button-shadow': 'none',
    'secondary-button-bg': 'var(--white)',
    'secondary-button-text': 'var(--grey-dark)',
    'secondary-button-border': 'var(--grey)',
    'secondary-button-shadow': 'none',
    'accent-button-bg': 'var(--accent)',
    'accent-button-text': 'var(--on-accent)',
    'accent-button-border': 'var(--accent)',
    'accent-button-shadow': '0 1px 0 0 var(--accent-d-20), 0 2px 0 0 rgba(0, 0, 0, 0.2)',
    'action-button-bg': 'var(--primary)',
    'action-button-text': 'var(--on-primary)',
    'action-button-border': 'var(--primary)',
    'action-button-shadow': 'none',
    'confirmation-button-bg': 'var(--secondary)',
    'confirmation-button-text': 'var(--on-secondary)',
    'confirmation-button-border': 'var(--secondary)',
    'confirmation-button-shadow': 'none',
    'negative-button-bg': 'var(--negative)',
    'negative-button-text': 'var(--on-negative)',
    'negative-button-border': 'var(--negative)',
    'negative-button-shadow': '0 1px 0 0 var(--negative-d-20), 0 2px 0 0 rgba(0, 0, 0, 0.2)',
    'positive-button-bg': 'var(--positive)',
    'positive-button-text': 'var(--on-positive)',
    'positive-button-border': 'var(--positive)',
    'positive-button-shadow': '0 1px 0 0 var(--positive-d-20), 0 2px 0 0 rgba(0, 0, 0, 0.2)',
    'warning-button-bg': 'var(--warning)',
    'warning-button-text': 'var(--on-warning)',
    'warning-button-border': 'var(--warning)',
    'warning-button-shadow': '0 1px 0 0 var(--warning-d-20), 0 2px 0 0 rgba(0, 0, 0, 0.2)',
    // Icons
    'icon-main': 'var(--grey-dark)',
    'icon-arrow': 'var(--grey-dark)',
    // Slide up panel
    'sup-border-radius': '0',
    'sup-bg': 'var(--white)',
    'sup-header': 'var(--white)',
    'sup-on-header': 'var(--dark)',
    'sup-footer': 'var(--white)',
    // Forms & Inputs
    'input-bg': 'var(--grey-light)',
    'input-border': 'var(--grey)',
    'input-icon': 'var(--grey-dark)',
    'switch-bg': 'var(--grey-light)',
    'switch-border': 'var(--white)',
    'switch-text': 'var(--grey-dark)',
    'switch-active-bg': 'var(--primary)',
    'switch-active-text': 'var(--on-primary)',
    'selector-bg': 'var(--white)',
    'selector-text': 'var(--dark)',
    'selector-border': 'var(--grey)',
    'selector-active-bg': 'var(--primary)',
    'selector-active-text': 'var(--on-primary)',
    'selector-active-border': 'transparent',
    // Cards
    'card-bg': 'var(--white)',
    'card-border-radius': '0',
    'card-shadow': 'none', 
    // Calendar
    'calendar-shadow': '0 2px 4px 0 rgba(0, 0, 0, 0.2)',
    'calendar-weekend': 'rgba(239, 239, 239, 0.75)',
    'calendar-border': 'rgba(0, 0, 0, 0.1)',
    // Context menu
    'cm-shadow': '0px 8px 8px rgba(var(--accent-rgb), 0.2), inset 0px -1px 0px rgba(0, 0, 0, 0.2)',
    'cm-active-shadow': 'inset 0px -1px 0px rgba(0, 0, 0, 0.2)',
  }
};

// export const DefaultTheme: Theme = {
//   name: 'default',
//   properties: {
//     'background': '#F6F6F6',
//     'on-background': '#000',
//     'primary': '#002E67',
//     'primary-light-20': '#0052b8',
//     'primary-light-40': '#0a77ff',
//     'primary-dark-20': '#002452',
//     'primary-dark-40': '#001b3d',
//     'on-primary': '#fff',
//     'secondary': '#f0f0f0',
//     'on-secondary': '#002E67',
//     'accent': '#EE7D11',
//     'accent-light-20': '#f19640',
//     'accent-light-40': '#f4b170',
//     'accent-dark-20': '#be640d',
//     'accent-dark-40': '#8e4a0a',
//     'on-accent': '#fff',
//     'error': '#EB5945',
//     'on-error': '#fff',
//     'warning': '#F6BB42',
//     'on-warning': '#fff',
//     'border': '#e5e5e5',
//     'border-dark-20': '#b7b7b7',
//     'title': '#000',
//   }
// };