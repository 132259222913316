<header class="main">{{date | djsDateFormat:('dateFormatting.day' | translate)}}</header>

<form [formGroup]="swapForm">
  <label *ngFor="let shift of shifts; let i = index;" for="shift-{{i}}" class="shift-radio">
    <input *ngIf="shifts.length > 1" type="radio" id="shift-{{i}}" formControlName="shiftIndex" value="{{i}}" name="shiftIndex" />
    <app-shift-header [shift]="shift"></app-shift-header>
  </label>

  <div class="warning" *ngIf="!hasCandidateShifts && !hasCandidatesOff">
    {{'swaps.form.noCandidatesForShift' | translate}}
  </div>

  <app-switch-input
    *ngIf="hasCandidateShifts || hasCandidatesOff"
    [selected]="wishTypeSwitchOptions[0].value"
    [options]="wishTypeSwitchOptions"
    [control]="swapForm.controls.type">
  </app-switch-input>

  <div class="candidates" *ngIf="hasCandidateShifts || hasCandidatesOff">
    <div *ngIf="type === 'work'">
      <section *ngFor="let shift of candidateShifts | keyvalue">
        <header>{{shift.key}}</header>
        <div *ngFor="let candidate of shift.value">
          <span>{{candidate.name}}</span>
          <span>
            <span>{{candidate.shift.startTime | djsDateFormat:'ddd HH:mm'}} - {{candidate.shift.endTime | djsDateFormat:'HH:mm'}}</span>
            <a href="javascript:void(0)" 
              class="button primary"
              (click)="createSwap(candidate.resourceId, candidate.shift.id, candidate.shift.name)">
              {{'buttons.swap' | translate}}
            </a>
          </span>
        </div>
      </section>
      <div *ngIf="!hasCandidateShifts" class="warning">{{'swaps.form.noCandidatesForDuty' | translate}}</div>
    </div>
    <div *ngIf="type === 'off'">
      <section *ngIf="hasCandidatesOff">
        <div *ngFor="let candidate of candidatesOff">
          <span>{{candidate.name}}</span>
          <a href="javascript:void(0)"
            class="button primary"
            (click)="createSwap(candidate.resourceId)">
            {{'buttons.swap' | translate}}
          </a>
        </div>
      </section>
      <div *ngIf="!hasCandidatesOff" class="warning">{{'swaps.form.noCandidatesForTimeOff' | translate}}</div>
    </div>
  </div>
</form>