<nav class="header">
  <button routerLink="/menu">
    <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-arrow-left.svg'"></div>
  </button>
  <span>{{'contracts.title' | translate}}</span>
  <button></button>
</nav>

<main>
  <!-- <h3>{{'contracts.title' | translate}}</h3> -->
  <app-selector-input
    *ngIf="contractOptions.length > 1"
    [options]="contractOptions"
    [selected]="activeContract.id"
    [style]="selectorInputStyle"
    (selectionChanged)="selectContract($event)"
  ></app-selector-input>
</main>

<!-- <div class="actions">
  <button class="button secondary">{{'buttons.cancel' | translate}}</button>
  <button class="button confirmation">{{'buttons.switch' | translate}}</button>
</div> -->