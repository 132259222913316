import { Component, OnInit } from '@angular/core';
import * as dayjs from 'dayjs';
import { BaseComponent } from '../base/base.component';
import { Shift } from '../interfaces/shift';
import { RequestService } from '../request.service';
import { ScheduleService } from '../schedule.service';
import { ShiftPickingMode, ShiftPickingService } from '../shift-picking.service';

@Component({
  selector: 'app-shift-picking-dialog',
  templateUrl: './shift-picking-dialog.component.html',
  styleUrls: ['./shift-picking-dialog.component.scss']
})
export class ShiftPickingDialogComponent extends BaseComponent implements OnInit {

  shift: Shift;
  mode: ShiftPickingMode;
  slideUpPanelId: string;

  constructor(
    private shiftPickingService: ShiftPickingService,
    private scheduleService: ScheduleService,
    private requestService: RequestService,
  ) { 
    super();
    this.slideUpPanelId = this.shiftPickingService.slideUpPanelId;
  }

  ngOnInit(): void {
    this.addSubscription(this.shiftPickingService.dataSet$.subscribe(dataSet => {
      if (dataSet) {
        this.shift = dataSet.shift;
        this.mode = dataSet.mode;
      }
    }));
  }

  pickShift(shift: Shift) {
    this.addSubscription(this.scheduleService.pickShift(shift).subscribe(() => {
      this.close();
    }))
  }

  applyForShift(shift: Shift) {
    const request = {
      name: shift.name,
      id: shift.id,
      date: dayjs(shift.startTime).format(),
      wantToWork: true,
      fromShiftPicking: true,
      description: ' ',
      departmentId: shift.currentDepartmentId,
    }
    this.addSubscription(this.requestService.createDutyRequest(request).subscribe(() => {
      this.close();
    }))
  }

  close() {
    this.shiftPickingService.finishPicking();
  }

}
