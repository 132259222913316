<article (click)="open();$event.stopPropagation();" class="{{(opened ? 'open' : '')}}">
  <header>
    <div>
      <div class="title">{{card.name}}</div>
      <div *ngIf="card.showTotal" class="total">
        <ng-container *ngIf="card.type !== 'TIME'">{{total | number:'1.0-2':language}}</ng-container>
        <ng-container *ngIf="card.type === 'TIME'">TEST: {{getTimeValue(total)}}</ng-container>
      </div>
    </div>
    <div class="icon arrow" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
  </header>
  <div *ngIf="details && details.categories" class="details" #detailsElement>
    <div #detailsContainer>
      <section class="category" *ngFor="let category of details.categories; let i = index;" id="{{card.accountId}}-section-{{i}}" (click)="openSection($event);$event.stopPropagation();">
        <header>
          <div>
            <div class="title">{{category.name}}</div> 
            <div class="total">
              <ng-container *ngIf="card.type !== 'TIME'">{{category.amount | number:'1.0-2':language}}</ng-container>
              <ng-container *ngIf="card.type === 'TIME'">{{getTimeValue(category.amount)}}</ng-container>
            </div>
          </div>
          <div class="icon arrow" [inlineSVG]="'/assets/icons/icon-arrow-right.svg'"></div>
        </header>
        <div class="entries" [attr.data-max-height]="(category.entries.length * 40) + 40" style="--entries-max-height:{{(category.entries.length * 40) + 40}}px;">
          <div #entryContainer> 
            <section *ngFor="let entry of category.entries">
              <div class="title">
                {{entry.date | djsDateFormat:('dateFormatting.cardDetails' | translate)}}
                <span *ngIf="entry.description !== ''"> - {{entry.description}}</span>
              </div>
              <div class="total">
                <ng-container *ngIf="card.type !== 'TIME'">{{entry.amount | number:'1.0-2':language}}</ng-container>
                <ng-container *ngIf="card.type === 'TIME'">{{getTimeValue(entry.amount)}}</ng-container>
              </div>
            </section>
          </div>
        </div>
      </section>
      <section *ngIf="details.categories && details.categories.length === 0">
        <div class="title no-registrations">{{'cards.noRegistrations' | translate}}</div>
      </section>
      <section *ngIf="details.showTotal" class="totals">
        <div class="title">{{'cards.total' | translate}}</div>
        <div class="total">
          <ng-container *ngIf="card.type !== 'TIME'">{{total | number:'1.0-2':language}}</ng-container>
          <ng-container *ngIf="card.type === 'TIME'">{{getTimeValue(total)}}</ng-container>
        </div>
      </section>
    </div>
  </div>
</article>