import { Component, Inject } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, RouterOutlet } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { slider } from './animations';
import { CookieService } from 'ngx-cookie-service';
import { Theme } from './theme/theme.interface';
import { ThemeService } from './theme/theme.service';
import { BaseComponent } from './base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import * as dayjs from 'dayjs'
import { UserService } from './auth/user.service';
import { AuthService } from './auth/auth.service';
import { StatisticsService } from './statistics.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    slider,
  ]
})
export class AppComponent extends BaseComponent {
  title = 'ess-app';
  previousBodyClass: string;
  blockAccess = false;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cookieService: CookieService,
    private themeService: ThemeService,
    private router: Router,
    private translate: TranslateService,
    private titleService: Title,
    private userService: UserService,
    private authService: AuthService,
    private statisticsService: StatisticsService,
  ) {
    super();

    const defaultLanguage = 'en';
    const userLanguage = this.userService.getLanguage();
    
    // Set default language, will be used as fallback if translation isn't found in current language
    translate.setDefaultLang(defaultLanguage);
    // Set the language we currently want to use
    translate.use(userLanguage);
    // Set moment locale
    dayjs.locale(userLanguage);

    /**
     * Listen to router events and change body class when navigating.
     * This body class can be used to add styling which only applies
     * to specific routes within the app.
     */
    this.addSubscription(this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.document.body.classList.add('loading');
        if (this.previousBodyClass) this.document.body.classList.remove(this.previousBodyClass);
        const className = `${event.url.split('?')[0].substr(1)}`.replace(/\//g, '-');  
        if (className) {
          this.document.body.classList.add(className);
          this.previousBodyClass = className;
        } else {
          this.document.body.classList.add('login');
          this.previousBodyClass = 'login';
        }
      }
      if(event instanceof NavigationEnd) {
        this.document.body.classList.remove('loading');
        this.statisticsService.triggerViewEvent(event.url);
      }
    }))
    this.addSubscription(this.translate.get('pageTitle').subscribe(translation => this.titleService.setTitle(translation)))
    this.addSubscription(this.authService.blockAccess$.subscribe(blockAccess => this.blockAccess = blockAccess))
    /**
     * Set the theme for this magazine
     */
    this.setTheme();
  }

  setTheme() {
    const theme: Theme = this.themeService.getTheme(this.cookieService.get('ess.magazine'));
    if (theme) {
      console.log('Setting theme for magazine', theme);
      this.themeService.setTheme(theme.name);
    }
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet/* && outlet.activatedRouteData && outlet.activatedRouteData['animation']*/;
  }
}
