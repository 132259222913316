<div class="shift-header {{(shift.pendingDuty ? 'pending-duty' : '')}} {{(shift.isRealized ? 'realized' : '')}} {{extraCssClasses}}">
  <div class="content">
    <div class="date">
      <span class="{{shift.startTimeChanged ? 'changed' : ''}}">{{shift.startTime | djsDateFormat:'HH:mm'}}</span>
      <span class="{{shift.endTimeChanged ? 'changed' : ''}}">{{shift.endTime | djsDateFormat:'HH:mm'}}</span>
    </div>
    <div class="shift">
      <h3>{{shift.name}}<span *ngIf="showActivityType"> ({{shift.activityType}})</span></h3>
      <p>{{shift.departmentName}}<span *ngIf="showPoints"><span *ngIf="shift.departmentName"> / </span>{{shift.points}} {{'selfScheduling.points' | translate}}</span></p>
    </div>
  </div>
  <div class="tools" *ngIf="showCalendarLink">
    <a (click)="$event.stopPropagation()" [href]="calendarUrl" [target]="isIOS ? '_self' : '_blank'" class="button secondary medium">
      <div class="icon main" [inlineSVG]="'/assets/icons/icon-add-to-calendar.svg'"></div>
    </a>
  </div>
</div>
<p *ngIf="hasDescription" class="description" [innerHTML]="shift.description"></p>