import { createReducer, on } from '@ngrx/store';
import * as dayjs from 'dayjs';
import { Dayjs } from 'dayjs';
import { AvailabilityDay } from '../interfaces/availability-day';
import { getAvailabilitySuccess, setRange } from './availability.actions';

export interface AvailabilityRange {
  startDate: string,
  endDate: string,
}

export interface AvailabilityState {
  range: AvailabilityRange;
  days: AvailabilityDay[];
}

export const initialState: AvailabilityState = {
  range: {
    startDate: dayjs().startOf('month').format(),
    endDate: dayjs().endOf('month').format(),
  },
  days: []
};

const _availabilityReducer = createReducer(initialState,
  on(setRange, (state: AvailabilityState, {startDate, endDate}) => ({
    ...state,
    range: {
      startDate,
      endDate
    }
  })),
  on(getAvailabilitySuccess, (state: AvailabilityState, {days}) => ({
    ...state,
    days
  })),
)

export function availabilityReducer(state, action) {
  return _availabilityReducer(state, action);
}