import { createReducer, on } from '@ngrx/store';
import { iMagazineClientConfig, DefaultMagazineConfig } from '@shared/magazine-config';
import { setConfig, setNavigation } from './config.actions';

export const initialState: iMagazineClientConfig = {
  ...DefaultMagazineConfig.client
};

const _configReducer = createReducer(initialState,
  on(setConfig, (state: iMagazineClientConfig, {config}) => config),
  on(setNavigation, (state: iMagazineClientConfig, {navigation}) => ({
    ...state,
    navigation,
  })),
)

export function configReducer(state, action) {
  return _configReducer(state, action);
}