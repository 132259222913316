import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { CustomErrorDismissType, ErrorService } from './error/error.service';
import { isNetworkConnected } from './utils';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

  specificErrors = {
  }
  translatedErrors = {
    'f8a051e9e7ef02e820490fb01f3b6fa7': 'noValidContracts',
    'ac790155f4645c879897b4423464f5a4': 'notAllowedToRetrieveRoster',
    '08de2fdaabcd120b8ca1388d21179921': 'accessDenied',
    'feca875afbb65fa38ffdc4e3b62a9d6d': 'swapCannotBeAppliedCauseViolation',
    '56f08d2434c6172e79e70f2065a44ccb': 'noCancelApprovedExchaneRequest',
    'da38ef5f37459e595558c14d658bacb6': 'noViewUnpublishedSchedules',
    '78a14a53bb6c254d3e77b9b3c11c0e56': 'operationFailedTryAgain',
    '9f312a8ee3a294e9750049149af87de0': 'noWildcardOnPlannedDay',
    'fcfc32b933febfc6c9bedf155e0c8545': 'noShiftLongerThan24',
  }
  ignoreCalls = [
    '/api/swaps/candidates',
    '/assets/config',
  ]

  constructor(
    private errorService: ErrorService,
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
        catchError((error: HttpErrorResponse) => {
          let ignoredEndpoint = false;
          this.ignoreCalls.forEach((call: string) => {
            if(error.url.indexOf(call) !== -1) ignoredEndpoint = true;
          })
          
          const isConnected = isNetworkConnected();
          const message = (error ? (error.error ? `${error.error.message}` : `${error.message}`).replace(/:[a-zA-Z]{2}:/g, '') : '');
          const messageKey = `${message}`.replace(/ /g, '-').toLowerCase();

          const messageId = error ? (error.error ? error.error.messageId : undefined) : undefined;
          const isTranslatedError = messageId && this.translatedErrors[messageId];

          if (!ignoredEndpoint && isConnected) {
            console.error('Caught error', error);
            if (error.error instanceof ErrorEvent) {
              // client-side error
              console.error(`Client-side Error: ${error.error.message}`);
            } else {
              // server-side error
              console.error(`Server-side Error Code: ${error.status}\nMessage: ${error.message}`);
            }
          }

          if (this.specificErrors[messageId] !== false && !ignoredEndpoint && isConnected && !isTranslatedError) {
            if (error.status === 400) {
              this.errorService.trigger({
                code: 'BadRequestError',
                text: message,
                showText: this.specificErrors[messageId] ? false : true,
                type: this.specificErrors[messageId] ? this.specificErrors[messageId] : CustomErrorDismissType.CTA,
              })
            } else if(error.status !== 401 && error.status !== 403) {
              this.errorService.trigger({
                code: 'UnknownRequestError',
                text: message,
                type: CustomErrorDismissType.CTA,
              })
            }
          }

          if (isTranslatedError) {
            this.errorService.trigger({
              code: this.translatedErrors[messageId],
              type: CustomErrorDismissType.OK,
              custom: true
            })
          }

          if (!isConnected && !isTranslatedError) {
            console.error('NO NETWORK CONNECTION!');
            this.errorService.trigger({
              code: 'noNetworkConnection',
              type: CustomErrorDismissType.OK,
              custom: true
            })
          }

          return throwError(error);
        })
      )
  }
}