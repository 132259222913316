import { Component, OnInit, Input, ElementRef, HostBinding } from '@angular/core';
import { Swap } from '../interfaces/swap';
import { UserService } from '../auth/user.service';
import { Subject } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { TranslateService } from '@ngx-translate/core';
import { SlideUpPanelService } from '../slide-up-panel/slide-up-panel.service';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as Actions from '../reducers/swaps.actions';

@Component({
  selector: 'app-swap-request',
  templateUrl: './swap-request.component.html',
  styleUrls: ['./swap-request.component.scss']
})
export class SwapRequestComponent extends BaseComponent implements OnInit {

  @Input() request: Swap;
  @Input() showHeader: boolean = true;
  @Input() showTools: boolean = false;

  isOwner: boolean;

  texts: {[key: string]: {[key: string]: string}} = {};
  textKey: string;

  text = '';

  slideUpPanelId = '';
  loading = true;

  @HostBinding('class') get cssClasses() {
    return `card ${this.showHeader ? '' : 'no-header'}`;
  }

  @HostBinding('style.display') get cssDisplay() {
    return this.request.shiftRequest ? 'block' : 'none';
  }

  constructor(
    private userService: UserService,
    private elementReference: ElementRef,
    private translateService: TranslateService,
    private slideUpPanelService: SlideUpPanelService,
    private store: Store<State>
  ) { 
    super();
  }

  async init() {
    this.addSubscription(this.translateService.get([
      'swaps.request'
    ]).subscribe(async translations => {
      this.texts = translations['swaps.request'];
      this.slideUpPanelId = `swapRequestDetails${this.request.id}`;
      this.isOwner = await this.userService.isMineByContract(this.request.resourceIdRequest);
      this.textKey = (this.request.shiftWanted ? 'shiftForShift' : 'shiftForTimeOff');

      let tempText = this.texts[(this.isOwner ? 'sender' : 'receiver')][this.textKey];
      let args = [];

      if (this.isOwner) {
        args = [this.request.shiftRequest?.name, this.request.wantedUser, this.request.shiftWanted?.name];
      } else {
        if (this.textKey === 'shiftForShift') {
          args = [this.request.requestUser, this.request.shiftRequest?.name, this.request.shiftWanted?.name];
        } else if (this.textKey === 'shiftForTimeOff') {
          args = [this.request.requestUser, this.request.shiftRequest?.name]
        }
      }

      this.text = tempText.formatUnicorn(args);
      this.loading = false;
    }))
    
  }

  cancelRequest() {
    this.store.dispatch(Actions.cancel({request: this.request}));
  }

  acceptRequest() {
    this.store.dispatch(Actions.accept({request: this.request}));
  }

  declineRequest() {
    this.store.dispatch(Actions.decline({request: this.request}));
  }

  open() {
    this.slideUpPanelService.show(this.slideUpPanelId);
  }
  
  close() {
    this.slideUpPanelService.hide(this.slideUpPanelId);

    const element = this.elementReference.nativeElement;
    const height = element.scrollHeight;
    
    element.style.maxHeight = `${height}px`;
    element.classList.add('canceled');
  }

  ngOnInit(): void {
    console.log('Showing swap request:', this.request);
    if (this.request) this.init();
  }

}
