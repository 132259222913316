import { Component, OnInit } from '@angular/core';
import { Day } from '../interfaces/day';
import { BaseComponent } from '../base/base.component';
import { Observable } from 'rxjs';
import { ScheduleService } from '../schedule.service';
import { map } from 'rxjs/operators';
import { Schedule } from '../interfaces/schedule';

export interface PickingMonth {
  date: string;
  days: Day[];
}

@Component({
  selector: 'app-shift-picking',
  templateUrl: './shift-picking.component.html',
  styleUrls: ['./shift-picking.component.scss']
})
export class ShiftPickingComponent extends BaseComponent implements OnInit {
  
  confirmPanelId = 'shiftPickingConfirmationPanel';
  currentMonth: number;
  months$: Observable<PickingMonth[]> = this.scheduleService.picking$.pipe(map((schedule: Schedule) => this.formatData(schedule)));

  constructor(
    private scheduleService: ScheduleService,
  ) { 
    super();
  }

  ngOnInit(): void {
  }

  formatData(schedule: Schedule): PickingMonth[] {
    if (!schedule || !schedule.days) return [];
    let months = [];
    let currentMonth = -1;
    let tempMonth: PickingMonth = {
      date: '',
      days: []
    };

    schedule.days.filter(day => day.extraShiftCount > 0).map(day => {      
      const m = new Date(day.date).getMonth();
      if (m !== currentMonth) {
        if (currentMonth > -1) months.push(tempMonth);
        currentMonth = m;
        tempMonth = {
          date: day.date,
          days: []
        };
      }
      tempMonth.days.push(day);
    })
    
    return tempMonth ? months.concat(tempMonth) : months;
  }

}
