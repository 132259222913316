<nav *ngIf="isMenuChild" class="header">
  <button routerLink="/menu">
    <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-arrow-left.svg'"></div>
  </button>
  <span>{{'navigation.balance' | translate}}</span>
  <button></button>
</nav>

<main *ngIf="cards.length > 0">
  <app-balance-card *ngFor="let card of cards" [card]="card"></app-balance-card>
</main>

<p *ngIf="cards.length === 0" class="no-data-indicator">{{'noData' | translate}}</p>
<!-- <a style="margin: 0 8px 15px;" *ngIf="show498Button" href="javascript:void(0)" class="button primary" (click)="trigger498()">Gertjan! Throw 498 💣💥</a> -->