import { Injectable, EventEmitter } from '@angular/core';

interface Panel {
  id: string;
  events: EventEmitter<string>;
}

interface Panels {
  [key: string]: Panel;
}

@Injectable({
  providedIn: 'root'
})
export class SlideUpPanelService {

  panels: Panels = {};

  constructor() { }

  add(panel: Panel) {
    if (!this.panels[panel.id]) {
      this.panels[panel.id] = panel;
    }
  }

  get(id: string): Panel|undefined {
    return this.panels[id];
  }

  delete(id: string) {
    delete this.panels[id];
  }

  show(id: string) {
    if (this.panels[id]) {
      this.panels[id].events.emit('show');
    } else {
      console.error(`SlideUpPanel '${id}' not defined!`)
    }
  }

  hide(id: string) {
    if (this.panels[id]) {
      this.panels[id].events.emit('hide');
    } else {
      console.error(`SlideUpPanel '${id}' not defined!`)
    }
  }

}
