import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor
} from '@angular/common/http';
import { BehaviorSubject, EMPTY, Observable } from 'rxjs';
import { TokenService, TokenSet } from './token.service';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AuthService } from './auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  isRefreshing: boolean = false;
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private injector: Injector,
  ) {}

  get tokenService() {
    return this.injector.get(TokenService);
  }

  get authService() {
    return this.injector.get(AuthService);
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    console.log(`[TokenInterceptor] url`, request.url);
    if (!request.url.match(/^\/api/)) return next.handle(request);
    if (request.url.match(/^\/api\/auth/)) return next.handle(request);

    console.log(`[TokenInterceptor] isRefreshing`, this.isRefreshing);

    if (this.isRefreshing) {
      return this.tokenSubject
        .pipe(filter(token => token !== null))
        .pipe(take(1))
        .pipe(switchMap(() => {
          return this.tokenService.getTokens().pipe(switchMap((tokenSet: TokenSet) => {
            const jwtHeader = `Bearer ${tokenSet.accessToken}`;
            request = request.clone({
              setHeaders: {
                Authorization: jwtHeader
              }
            })
            return next.handle(request);
          }))
        }))
    } else {
      this.isRefreshing = true;
      this.tokenSubject.next(null);
      
      return this.tokenService.isAuthenticatedOrRefresh()
        .pipe(switchMap(isAuthenticated => {
          if (isAuthenticated) {
            console.log(`[TokenInterceptor] isAuthenticated`, true);
            return this.tokenService.getTokens().pipe(switchMap((tokenSet: TokenSet) => {
              const jwtHeader = `Bearer ${tokenSet.accessToken}`;
              request = request.clone({
                setHeaders: {
                  Authorization: jwtHeader
                }
              })
              this.isRefreshing = false;
              this.tokenSubject.next(tokenSet.accessToken);
              return next.handle(request);
            }))
          } else {
            console.warn('Error after refreshing token from Interceptor, bailing out!')
            this.isRefreshing = false;
            this.authService.bailOut();
            return EMPTY;
          }
        }), catchError((error) => {
          if (request.url.match(/^\/api\/auth/)) {
            console.warn('Error refreshing token from Interceptor, bailing out!', error)
            this.isRefreshing = false;
            this.authService.bailOut();
            return EMPTY;
          } else {
            return next.handle(request);
          }
        }));
    }
    
    
  }
  
}
