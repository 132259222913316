import { Component, HostBinding, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as dayjs from 'dayjs';
import { BaseComponent } from '../../base/base.component';
import { Shift } from '../../interfaces/shift';
import { ShiftPickingService } from '../../shift-picking.service';

@Component({
  selector: 'app-shift',
  templateUrl: './shift.component.html',
  styleUrls: ['./shift.component.scss']
})
export class ShiftComponent extends BaseComponent {

  @Input() shift: Shift;;
  @Input() date: string;
  @Input() shiftPicking: boolean = false;
  @Input() card: boolean = true;

  @HostBinding('class') get cssClass() {
    return `${this.card ? 'card' : ''}`;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private shiftPickingService: ShiftPickingService,
  ) {
    super();
  }

  openDay() {
    let scheduleDate = this.route.snapshot.params.date;
    if (!scheduleDate) scheduleDate = dayjs().startOf('day').toISOString();
    this.router.navigate([`/schedule/${scheduleDate}/day/${this.date}`])
  }

  cancelRequest(id: number, type: string) {
    this.addSubscription(this.shiftPickingService.cancelRequest(id, type).subscribe(() => {
      this.shiftPickingService.finishPicking();
    }));
  }

  startShiftPicking(shift: Shift, mode: any) {
    this.shiftPickingService.startPicking(shift, mode);
  }

}
