<nav>
  <button [routerLink]="'..'">
    <div class="icon on-primary" [inlineSVG]="'/assets/icons/icon-calendar.svg'"></div>
  </button>
  <span>{{'shiftPicking.title' | translate}}</span>
  <button></button>
</nav>

<div class="timeline">
  <ng-container *ngFor="let month of months$ | async">
    <ng-container *ngFor="let day of month.days">
      <app-schedule-day *ngIf="day.extraShiftCount > 0" [shiftPicking]="true" [day]="day"></app-schedule-day>
    </ng-container>
  </ng-container>
</div>