import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { DateTimeInputComponent } from './date-time-input/date-time-input.component';
import { SwitchInputComponent } from './switch-input/switch-input.component';
import { FormatUnicornPipe } from './format-unicorn.pipe';
import { SelectorInputComponent } from './selector-input/selector-input.component';
import { DjsDateFormatPipe } from './dayjs/djs-date-format.pipe';
import { ShiftSelectorInputComponent } from './shift-selector-input/shift-selector-input.component';
import { TimeRangeInputComponent } from './time-range-input/time-range-input.component';
import { ShiftComponent } from './shift/shift.component';
import { ShiftHeaderComponent } from './shift-header/shift-header.component';
import { ShiftTimelineComponent } from './shift-timeline/shift-timeline.component';
import { InlineSVGModule } from 'ng-inline-svg';
import { CheckPermissionsDirective } from './directives/check-permissions.directive';
import { LoadingComponent } from './loading/loading.component';
import { CalendarComponent } from './calendar/calendar.component';
import { CamelCasePipe } from './pipes/camel-case.pipe';
import { KebabCasePipe } from './pipes/kebab-case.pipe';
import { CalendarEventComponent } from './calendar/calendar-event/calendar-event.component';

@NgModule({
  declarations: [
    DateTimeInputComponent,
    SwitchInputComponent,
    FormatUnicornPipe,
    SelectorInputComponent,
    DjsDateFormatPipe,
    ShiftSelectorInputComponent,
    TimeRangeInputComponent,
    ShiftComponent,
    ShiftHeaderComponent,
    ShiftTimelineComponent,
    CheckPermissionsDirective,
    LoadingComponent,
    CalendarComponent,
    CamelCasePipe,
    KebabCasePipe,
    CalendarEventComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    InlineSVGModule.forRoot(),
  ],
  exports: [
    TranslateModule,
    DateTimeInputComponent,
    SwitchInputComponent,
    FormatUnicornPipe,
    SelectorInputComponent,
    DjsDateFormatPipe,
    ShiftSelectorInputComponent,
    TimeRangeInputComponent,
    ShiftComponent,
    ShiftHeaderComponent,
    ShiftTimelineComponent,
    CheckPermissionsDirective,
    LoadingComponent,
    CalendarComponent,
    CamelCasePipe,
    KebabCasePipe,
  ]
})
export class SharedModule { }
