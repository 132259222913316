import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Card } from './interfaces/card';
import { Observable } from 'rxjs';
import { CardDetails } from './interfaces/card-details';

@Injectable({
  providedIn: 'root'
})
export class CardsService {

  constructor(private http: HttpClient) { }

  getCards(): Observable<Card[]> {
    return this.http.get<Card[]>(`/api/cards`);
  }

  getCardDetails(accountId: number): Observable<CardDetails> {
    return this.http.get<CardDetails>(`/api/cards/${accountId}`);
  }

}
