import { NgModule } from '@angular/core';
import { Routes, RouterModule, Route } from '@angular/router';
import { LoginComponent } from './auth/login/login.component';
import { ScheduleComponent } from './schedule/schedule.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { RequestsComponent } from './requests/requests.component';
import { BalanceComponent } from './balance/balance.component';
import { ScheduleResolver } from './resolvers/schedule.resolver';
import { ScheduleDayDetailComponent } from './schedule-day-detail/schedule-day-detail.component';
import { GenericErrorComponent } from './generic-error/generic-error.component';
import { CardsResolver } from './resolvers/cards.resolver';
import { DebugComponent } from './debug/debug.component';
import { UserResolver } from './resolvers/user.resolver';
import { PickingResolver } from './resolvers/picking.resolver';
import { ShiftPickingComponent } from './shift-picking/shift-picking.component';
import { ContractResolver } from './resolvers/contract.resolver';
import { ContractComponent } from './contract/contract.component';
import { PageTitleGuard } from './page-title.guard';
import { AppMenuComponent } from './app-menu/app-menu.component';

const scheduleRouting: Route = {
  path: 'schedule',
  data: {
    pageTitle: 'pageTitles.schedule'
  },
  children: [
    {
      path: 'picking',
      component: ShiftPickingComponent,
      runGuardsAndResolvers: 'pathParamsChange',
      data: {
        animation: 'isRight'
      },
      resolve: {
        picking: PickingResolver,
      },
    },
    {
      path: ':overviewDate/day/:date',
      component: ScheduleDayDetailComponent,
      runGuardsAndResolvers: 'pathParamsChange',
      data: {
        animation: 'isRight'
      },
      resolve: {
        schedule: ScheduleResolver,
      },
    },
    {
      path: ':date',
      component: ScheduleComponent,
      runGuardsAndResolvers: 'pathParamsChange',
      data: {
        animation: 'isLeft'
      },
      resolve: {
        schedule: ScheduleResolver,
      },
    },
    {
      path: '',
      component: ScheduleComponent,
      runGuardsAndResolvers: 'pathParamsChange',
      data: {
        animation: 'isLeft'
      },
      resolve: {
        schedule: ScheduleResolver,
      },
    }
  ]
};

const requestsRouting: Route = {
  path: 'requests',
  component: RequestsComponent,
  data: {
    pageTitle: 'pageTitles.requests',
    animation: ''
  }
};

const balanceRouting: Route = {
  path: 'balance',
  component: BalanceComponent,
  data: {
    pageTitle: 'pageTitles.balance',
    animation: 'isRight'
  },
  resolve: {
    cards: CardsResolver,
  },
};

const contractRouting: Route = {
  path: 'contracts',
  component: ContractComponent,
  data: {
    pageTitle: 'pageTitles.contracts',
  },
  resolve: {
    data: ContractResolver
  }
};

const routes: Routes = [
  {
    path: '',
    canActivateChild: [/*Auth0LoginGuard, */PageTitleGuard],
    children: [
      { path: 'login', component: LoginComponent },
      {
        path: '',
        canActivateChild: [AuthGuard],
        resolve: {
          user: UserResolver,
        },
        children: [
          scheduleRouting,
          requestsRouting,
          balanceRouting,
          { path: 'availability', loadChildren: () => import('./availability/availability.module').then(m => m.AvailabilityModule) },
          { path: 'self-scheduling', loadChildren: () => import('./self-scheduling/self-scheduling.module').then(m => m.SelfSchedulingModule) },
          { path: 'time-registration', loadChildren: () => import('./time-registration/time-registration.module').then(m => m.TimeRegistrationModule) },
          {
            path: 'menu',
            children: [
              balanceRouting,
              requestsRouting,
              contractRouting,
              { path: 'availability', loadChildren: () => import('./availability/availability.module').then(m => m.AvailabilityModule) },
              { path: 'self-scheduling', loadChildren: () => import('./self-scheduling/self-scheduling.module').then(m => m.SelfSchedulingModule) },
              { path: 'time-registration', loadChildren: () => import('./time-registration/time-registration.module').then(m => m.TimeRegistrationModule) },
              {
                path: '',
                component: AppMenuComponent,
              }
            ]
          },
          { path: '', pathMatch: 'full', redirectTo: 'schedule' },
        ]
      },
    ]
  },
  {
    path: "debug",
    component: DebugComponent
  },
  {
    path: 'error',
    component: GenericErrorComponent
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
