import { Directive, OnInit, OnDestroy, ElementRef, Inject, Input } from '@angular/core';
import { ThemeService } from './theme.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { DOCUMENT } from '@angular/common';
import { Theme } from './theme.interface';
import { DefaultTheme } from '../themes/default.theme';

@Directive({
  selector: '[applyTheme]'
})
export class ThemeDirective implements OnInit, OnDestroy {

  // Defines whether styles are scoped to an element
  @Input() scoped = false;

  // Subject that will notify and complete if directive is destroyed
  private destroy$ = new Subject();

  constructor(
    private elementRef: ElementRef,
    private themeService: ThemeService,
    @Inject(DOCUMENT) private document: any
  ) {}

  /**
   * Do our magic on init
   */
  ngOnInit() {
    // Get the active theme
    const active = this.themeService.getActiveTheme();

    // Set theme if we have an active theme
    if (active) {
      if (active.name !== "default") {
        this.updateTheme(DefaultTheme)
      }
      this.updateTheme(active);
    }

    // Listen to theme changes and update theme
    this.themeService.themeChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((theme: Theme) => {
        if (active.name !== "default") {
          this.updateTheme(DefaultTheme)
        }
        this.updateTheme(theme)
      });
  }

  /**
   * Listen to component destruction and signal listeners
   */
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  /**
   * Update the theme by setting the style property on the
   * element holding our directive
   * @param theme 
   */
  updateTheme(theme: Theme) {
    const element = this.getElement();

    // project properties onto the element
    for (const key in theme.properties) {
      element.style.setProperty(`--${key}`, theme.properties[key]);
    }

    // remove old theme
    for (const name of this.themeService.theme) {
      element.classList.remove(`theme-${name}`);
    }

    // alias element with theme name
    element.classList.add(`theme-${theme.name}`);
  }

  /**
   * Retrieves the element we will attach our style properties to
   */
  getElement() {
    return this.scoped ? this.elementRef.nativeElement : this.document.body;
  }

}