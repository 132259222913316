import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../base/base.component';
import { AuthService } from '../auth/auth.service';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-debug',
  templateUrl: './debug.component.html',
  styleUrls: ['./debug.component.scss']
})
export class DebugComponent extends BaseComponent implements OnInit {

  form = new UntypedFormGroup({
    accessToken: new UntypedFormControl(),
    refreshToken: new UntypedFormControl(),
    user: new UntypedFormControl(),
  })

  accessToken: string;
  refreshToken: string;
  user: any;

  constructor(
    private authService: AuthService,
    private store: Store<State>,
  ) {
    super();
  }

  ngOnInit(): void {
    this.addSubscription(forkJoin({
      accessToken: this.store.select((state: State) => state.auth.tokens.accessToken).pipe(first()),
      refreshToken: this.store.select((state: State) => state.auth.tokens.refreshToken).pipe(first()),
      user: this.store.select((state: State) => state.auth.user).pipe(first()),
    }).subscribe(values => {
      this.accessToken = values.accessToken;
      this.refreshToken = values.refreshToken;
      this.user = values.user;
    }));
  }

  submit() {
    const accessToken = this.form.get('accessToken').value;
    const refreshToken = this.form.get('refreshToken').value;

    if (accessToken && refreshToken) {
      this.authService.login(accessToken, refreshToken);
    }
  }

}
