import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Shift } from '../interfaces/shift';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { SwitchInputOption } from '../shared/switch-input/switch-input.component';
import { TranslateService } from '@ngx-translate/core';
import { SwapService } from '../swap.service';
import { SlideUpPanelService } from '../slide-up-panel/slide-up-panel.service';
import { BaseComponent } from '../base/base.component';
import { ActivatedRoute } from '@angular/router';
import { User } from '../interfaces/user';
import { Store } from '@ngrx/store';
import { State } from '../reducers';
import * as Actions from '../reducers/swaps.actions';
import { SwapCandidate } from '../interfaces/swap-candidate';

@Component({
  selector: 'app-request-swap-form',
  templateUrl: './request-swap-form.component.html',
  styleUrls: ['./request-swap-form.component.scss']
})
export class RequestSwapFormComponent extends BaseComponent implements OnInit {

  @Input() slideUpPanelId: string;
  @Input() date: string;
  @Input() shifts: Shift[] = [];

  @Output() onSubmit: EventEmitter<any> = new EventEmitter<any>();

  swapForm = new UntypedFormGroup({
    type: new UntypedFormControl('work'),
    shiftIndex: new UntypedFormControl('0'),
  });
  wishTypeSwitchOptions: SwitchInputOption[] = [];
  type = 'work';
  shiftIndex = 0;
  candidateShifts = {};
  hasCandidateShifts = false;
  candidatesOff = [];
  hasCandidatesOff = false;
  user: User;

  constructor(
    private translateService: TranslateService,
    private slideUpPanelService: SlideUpPanelService,
    private swapService: SwapService,
    private route: ActivatedRoute,
    private store: Store<State>
  ) {
    super();
    this.addSubscription(this.translateService.get([
      'swaps.form.switchWantWork',
      'swaps.form.switchWantTimeOff',
    ]).subscribe(translations => {
      this.wishTypeSwitchOptions = [
        {
          label: translations['swaps.form.switchWantWork'],
          value: 'work',
        },
        {
          label: translations['swaps.form.switchWantTimeOff'],
          value: 'off',
        },
      ]
    }))
  }

  processCandidates(candidates: any[]) {
    let shifts = {};

    candidates.filter((candidate: any[]) => candidate.shift).forEach(candidate => {
      if (!shifts[candidate.shift.name]) shifts[candidate.shift.name] = [];
      shifts[candidate.shift.name].push(candidate);
    });
    
    this.candidateShifts = shifts;
    this.candidatesOff = candidates.filter((candidate: any[]) => !candidate.shift);
    this.hasCandidateShifts = Object.keys(this.candidateShifts).length > 0;
    this.hasCandidatesOff = this.candidatesOff.length > 0;
  }

  getCandidates() {
    this.store.dispatch(Actions.getCandidates());
  }

  createSwap(wantedResourceId: number, wantedShiftId?: number, wantedShiftName?: string) {
    const shiftIndex = parseInt(this.swapForm.get('shiftIndex').value);
    const dto = {
      shiftId: this.shifts[shiftIndex].id,
      wantedResourceId,
      wantedShiftId,
    };
    const meta = {
      shiftWanted: typeof wantedShiftId !== 'undefined',
      sender: this.user.name,
      requestShiftName: this.shifts[shiftIndex].name,
      wantedShiftName: wantedShiftName,
      wantedResourceId: wantedResourceId,
    };
    this.store.dispatch(Actions.create({dto, meta}));

    //older
    this.onSubmit.emit(dto);
    // this.getCandidates();
    this.slideUpPanelService.hide(this.slideUpPanelId);
  }

  ngOnInit(): void {
    // this.store.dispatch(Actions.setCurrentShift({shift: this.shifts[0]}));
    // this.getCandidates();
    this.addSubscription(this.route.data.subscribe(data => {
      this.user = data.user;
      // this.getCandidates();
    }))
    this.addSubscription(this.swapService.swapCandidates$.subscribe((candidates: SwapCandidate[]) => this.processCandidates(candidates)));
    this.addSubscription(this.swapForm.controls.type.valueChanges.subscribe((type: string) => this.type = type))
    this.addSubscription(this.swapForm.controls.shiftIndex.valueChanges.subscribe((shiftIndex: string) => {
      this.shiftIndex = parseInt(shiftIndex);
      this.store.dispatch(Actions.setCurrentShift({shift: this.shifts[shiftIndex]}));
      // this.getCandidates();
    }))
  }

}
