import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { BaseComponent } from '../base/base.component';
import { ConfigService } from '../config.service';
import { State } from '../reducers';
import { SlideUpPanelService } from '../slide-up-panel/slide-up-panel.service';
import { CustomError, CustomErrorDismissType, ErrorService } from './error.service';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
  styleUrls: ['./error.component.scss']
})
export class ErrorComponent extends BaseComponent implements OnInit {

  subscription: Subscription;
  error: CustomError = undefined;
  slideUpPanelId = 'errorSlideUpPanel';
  translations: string[] = [];
  supportEmail: string;

  constructor(
    private errorService: ErrorService,
    private slideUpPanelService: SlideUpPanelService,
    private translateService: TranslateService,
    private configService: ConfigService,
    private store: Store<State>,
  ) {
    super();
    this.addSubscription(this.translateService.get([
      'errors.main.title',
      'errors.main.text',
      'errors.main.buttons.ok',
      'errors.main.buttons.callToAction',
    ]).subscribe(translations => this.translations = translations))
  }

  ngOnInit(): void {
    this.addSubscription(this.errorService.currentError$.subscribe((error: CustomError) => {
      if (error) console.error('An error occurred!', error);
      this.error = error;
      if (error !== undefined) setTimeout(() => this.slideUpPanelService.show(this.slideUpPanelId));
    }));
  }

  openCallToAction() {
    this.store.select('config', 'supportEmail').subscribe(supportEmail => {
      const subject = encodeURIComponent(`ESS Error: ${this.error.code}`);
      const body = encodeURIComponent(`I received the following error in ESS: ${this.error.code} - ${this.error.text}`)
      const href = `mailto:${supportEmail}?subject=${subject}&body=${body}`;
      Object.assign(document.createElement('a'), {
        target: '_blank',
        href,
      }).click();
    })
  }

  dismiss(triggerActions: boolean = true): void {
    if (triggerActions && this.error.type === CustomErrorDismissType.CTA) this.openCallToAction();
    if (triggerActions) this.slideUpPanelService.hide(this.slideUpPanelId);
    this.errorService.dismiss();
  }

  onSlideUpPanelVisibilityChanged(visible: boolean) {
    if (!visible) this.dismiss(false);
  }

}
