<header class="main">{{date | djsDateFormat:('dateFormatting.day' | translate)}}</header>

<div class="no-data" *ngIf="!departments || departments.length === 0">
  <span>
    <div class="icon main" [inlineSVG]="'/assets/icons/icon-no-roster.svg'"></div>
    {{'teamSchedule.noData' | translate}}
  </span>
</div>

<app-selector-input
  *ngIf="departments.length > 1"
  [options]="departmentOptions"
  (selectionChanged)="departmentChanged($event)"
></app-selector-input>

<section *ngFor="let department of departments">
  <ng-container *ngIf="selectedDepartment === department.key">
    <article *ngFor="let shift of department.shifts | keyvalue">
      <header>{{shift.key}}</header>
      <div *ngFor="let person of shift.value">
        <span>{{person.employeeName}}</span>
        <span>{{person.startTime | djsDateFormat:'ddd HH:mm'}} - {{person.endTime | djsDateFormat:'HH:mm'}}</span>
      </div>
    </article>
  </ng-container>
</section>